import React from "react";
import { useSelector } from "react-redux";

import {
  selectActiveBoardComponentsIds,
} from "../redux/modules/boardsDict";
import BoardComonent from "./BoardComponent";


const isIdsListsEqual = (left, right) => {
    if (left.length !== right.length){
        return false
    }

    for(var i = 0; i < left.length; ++i){
        if(left[i] !== right[i]){
            return false
        }
    }

    return true
} 

const BoardComponents = () => {
  const boardComponentsIds = useSelector(selectActiveBoardComponentsIds, isIdsListsEqual);

  return (
    <div>
      {boardComponentsIds.map(compnentId => {
        return <BoardComonent key={compnentId} id={compnentId} />;
      })}
    </div>
  );
};

export default BoardComponents