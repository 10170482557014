import React from "react";
import { COLOR_PETER_RIVER } from "../../../../consts/colors";
import SmallIconButton from "../../../../components/Buttons/SmallIconButton";
// import MinusIcon from "@material-ui/icons/Remove";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const DeleteButton = ({ onClick, color }) => {
  return (
    <SmallIconButton
      onClick={onClick}
      hoveredColor={COLOR_PETER_RIVER}
      color={color}
    >
      <DeleteOutlineIcon style={{ fontSize: 20 }} />
    </SmallIconButton>
  );
};

export default DeleteButton;
