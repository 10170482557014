import { COLOR_DARK_BLUE } from "../consts/colors";

export const cardBorderTop = color => `2px solid ${color}`;
export const RESIZABLE_PADDING = 10;
export const INNER_PADDING = 22
export const CARD_TOTAL_PADDING = INNER_PADDING + RESIZABLE_PADDING

export const CARD_STYLE = {
  // minHeight: 300,
  border: "1px solid #dedede",
  borderRadius: 8,
  backgroundColor: "white"
};

export const TITLE_STYLE = {
  whiteSpace: "nowrap",
  fontWeight: 600,
  cursor: "text",
  display: "inline-flex",
  textOverflow: "ellipsis",
  alignItems: "center",
  overflow: "hidden",
  border: "none",
  outline: "none",
  backgroundColor: "#ffff",
  width: "90%",
  color: '#333'
};
