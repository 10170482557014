import {
  addRequiredBoardUpdate,
  addRequiredUserUpdate
} from "../backend/updater/Updater";

export const updateVersion = version =>
  version === undefined ? 0 : version + 1;

export const updateUserVersion = user => {
  user.version = updateVersion(user.version);
  addRequiredUserUpdate(user);
  return user;
};

export const updateBoardVersion = board => {
  board.version = updateVersion(board.version);
  addRequiredBoardUpdate(board);
  return board;
};
