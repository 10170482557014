import React from "react";
import ParticipantRow from "./ParticipantRow";
import { COLOR_VERY_DARK_BLUE } from "../../consts/colors";
import { PARTICIPENTS_WIDTH, PARTICIPENTS_PADDING } from "./layout";
import { AddEmailInput } from "./AddEmailInput";
import { useSelector } from "react-redux";
import {
  selectIsActiveUserOwnerInBoard,
  selectJoinRequestsList,
  selectParticipantsList,
  selectInvitedParticipantsList
} from "../../redux/modules/boardsDict";

const Subtitle = ({ text }) => (
  <h2
    style={{ color: COLOR_VERY_DARK_BLUE, paddingLeft: PARTICIPENTS_PADDING }}
  >
    {text}
  </h2>
);

const Participants = ({ boardId }) => {
  const isOwner = useSelector(state =>
    selectIsActiveUserOwnerInBoard(state, boardId)
  );
  const joinRequestsList = useSelector(state =>
    selectJoinRequestsList(state, boardId)
  );

  const participentsList = useSelector(state =>
    selectParticipantsList(state, boardId)
  );
  const invitedParticipantsList = useSelector(state =>
    selectInvitedParticipantsList(state, boardId)
  );

  return (
    <div style={{ width: PARTICIPENTS_WIDTH }}>
      {isOwner && joinRequestsList.length > 0 && (
        <>
          <Subtitle text="Join requests" />
          {joinRequestsList.map(p => (
            <ParticipantRow
              key={p.user.id}
              isOwner={isOwner}
              isJoined={false}
              participant={p.user}
              connection={p.connection}
            />
          ))}
          <div style={{ height: 25 }} />
        </>
      )}

      {participentsList.length > 0 && (
        <>
          <Subtitle text="Participants" />
          {participentsList.map(p => (
            <ParticipantRow
              key={p.user.id}
              isOwner={isOwner}
              isJoined={true}
              participant={p.user}
              connection={p.connection}
            />
          ))}
        </>
      )}

      {isOwner && (
        <>
          <div style={{ height: 25 }} />

          <Subtitle text="Have not joined yet" />
          <div style={{ height: 15 }} />
          <AddEmailInput />
          {invitedParticipantsList.map(p => (
            <ParticipantRow
              key={p.user && p.user.id ? p.user.id : p.user.email}
              isJoined={true}
              isOwner={isOwner}
              participant={p.user}
              connection={p.connection}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default Participants;
