/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBoard = /* GraphQL */ `
  subscription OnCreateBoard($ownerIds: String!, $editorIds: String!) {
    onCreateBoard(ownerIds: $ownerIds, editorIds: $editorIds) {
      id
      boardMeetingStage {
        stage
        id
        version
      }
      name {
        name
        version
      }
      meetingLifecycleTime {
        id
        scheduledStartTime
        scheduledEndTime
        startTime
        closedDate
        version
      }
      boardComponents
      boardParticipants {
        items {
          id
          userId
          boardId
          status
          permission
        }
        nextToken
      }
      pendingParticipants {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      readOnlyIds
      editorIds
      ownerIds
      version
    }
  }
`;
export const onUpdateBoard = /* GraphQL */ `
  subscription OnUpdateBoard($id: ID!) {
    onUpdateBoard(id: $id) {
      id
      boardMeetingStage {
        stage
        id
        version
      }
      name {
        name
        version
      }
      meetingLifecycleTime {
        id
        scheduledStartTime
        scheduledEndTime
        startTime
        closedDate
        version
      }
      boardComponents
      boardParticipants {
        items {
          id
          userId
          boardId
          status
          permission
          user{
            id
            userName
            email
            pictureURL
          }
        }
        nextToken
      }
      pendingParticipants {
        items {
          id
          userId
          boardId
          status
          user{
            id
            userName
            email
            pictureURL
          }
        }
        nextToken
      }
      readOnlyIds
      editorIds
      ownerIds
    }
  }
`;
export const onDeleteBoard = /* GraphQL */ `
  subscription OnDeleteBoard($ownerIds: String!, $editorIds: String!) {
    onDeleteBoard(ownerIds: $ownerIds, editorIds: $editorIds) {
      id
      boardMeetingStage {
        stage
        id
        version
      }
      name {
        name
        version
      }
      meetingLifecycleTime {
        id
        scheduledStartTime
        scheduledEndTime
        startTime
        closedDate
        version
      }
      boardComponents
      boardParticipants {
        items {
          id
          userId
          boardId
          status
          permission
        }
        nextToken
      }
      pendingParticipants {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      readOnlyIds
      editorIds
      ownerIds
      version
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($email: String!) {
    onCreateUser(email: $email) {
      id
      firstName
      lastName
      userName
      pictureURL
      email
      emailVerified
      participatingBoards {
        items {
          id
          userId
          boardId
          status
          permission
        }
        nextToken
      }
      pendingBoards {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      boardsDirectory
      version
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: ID!) {
    onUpdateUser(id: $id) {
      id
      firstName
      lastName
      userName
      pictureURL
      email
      emailVerified
      participatingBoards {
        items {
          id
          userId
          boardId
          status
          permission
        }
        nextToken
      }
      pendingBoards {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      boardsDirectory
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($email: String!) {
    onDeleteUser(email: $email) {
      id
      firstName
      lastName
      userName
      pictureURL
      email
      emailVerified
      participatingBoards {
        items {
          id
          userId
          boardId
          status
          permission
        }
        nextToken
      }
      pendingBoards {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      boardsDirectory
      version
    }
  }
`;
export const onCreatePendingParticipant = /* GraphQL */ `
  subscription OnCreatePendingParticipant {
    onCreatePendingParticipant {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
        emailVerified
        participatingBoards {
          nextToken
        }
        pendingBoards {
          nextToken
        }
        boardsDirectory
        version
      }
      userId
      board {
        id
        boardMeetingStage {
          stage
          id
          version
        }
        name {
          name
          version
        }
        meetingLifecycleTime {
          id
          scheduledStartTime
          scheduledEndTime
          startTime
          closedDate
          version
        }
        boardComponents
        boardParticipants {
          nextToken
        }
        
        pendingParticipants {
          nextToken
        }
        readOnlyIds
        editorIds
        ownerIds
        version
      }
      boardId
      status
    }
  }
`;
export const onUpdatePendingParticipant = /* GraphQL */ `
  subscription OnUpdatePendingParticipant {
    onUpdatePendingParticipant {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
        emailVerified
        participatingBoards {
          nextToken
        }
        pendingBoards {
          nextToken
        }
        boardsDirectory
        version
      }
      userId
      board {
        id
        boardMeetingStage {
          stage
          id
          version
        }
        name {
          name
          version
        }
        meetingLifecycleTime {
          id
          scheduledStartTime
          scheduledEndTime
          startTime
          closedDate
          version
        }
        boardComponents
        boardParticipants {
          nextToken
        }
        pendingParticipants {
          nextToken
        }
        readOnlyIds
        editorIds
        ownerIds
        version
      }
      boardId
      status
    }
  }
`;
export const onDeletePendingParticipant = /* GraphQL */ `
  subscription OnDeletePendingParticipant {
    onDeletePendingParticipant {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
        emailVerified
        participatingBoards {
          nextToken
        }
        
        pendingBoards {
          nextToken
        }
        boardsDirectory
        version
      }
      userId
      board {
        id
        boardMeetingStage {
          stage
          id
          version
        }
        name {
          name
          version
        }
        meetingLifecycleTime {
          id
          scheduledStartTime
          scheduledEndTime
          startTime
          closedDate
          version
        }
        boardComponents
        boardParticipants {
          nextToken
        }
        pendingParticipants {
          nextToken
        }
        readOnlyIds
        editorIds
        ownerIds
        version
      }
      boardId
      status
    }
  }
`;
export const onCreateBoardParticipantConnection = /* GraphQL */ `
  subscription OnCreateBoardParticipantConnection {
    onCreateBoardParticipantConnection {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
        participatingBoards {
          nextToken
        }
        pendingBoards {
          nextToken
        }
        boardsDirectory
        version
      }
      userId
      board {
        id
        boardMeetingStage {
          stage
          id
          version
        }
        name {
          name
          version
        }
        meetingLifecycleTime {
          id
          scheduledStartTime
          scheduledEndTime
          startTime
          closedDate
          version
        }
        boardComponents
        boardParticipants {
          nextToken
        }
        pendingParticipants {
          nextToken
        }
        readOnlyIds
        editorIds
        ownerIds
        version
      }
      boardId
      status
      permission
    }
  }
`;
export const onUpdateBoardParticipantConnection = /* GraphQL */ `
  subscription OnUpdateBoardParticipantConnection {
    onUpdateBoardParticipantConnection {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
        participatingBoards {
          nextToken
        }
        pendingBoards {
          nextToken
        }
        boardsDirectory
        version
      }
      userId
      board {
        id
        boardMeetingStage {
          stage
          id
          version
        }
        name {
          name
          version
        }
        meetingLifecycleTime {
          id
          scheduledStartTime
          scheduledEndTime
          startTime
          closedDate
          version
        }
        boardComponents
        boardParticipants {
          nextToken
        }
        pendingParticipants {
          nextToken
        }
        readOnlyIds
        editorIds
        ownerIds
        version
      }
      boardId
      status
      permission
    }
  }
`;
export const onDeleteBoardParticipantConnection = /* GraphQL */ `
  subscription OnDeleteBoardParticipantConnection {
    onDeleteBoardParticipantConnection {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
        participatingBoards {
          nextToken
        }
        pendingBoards {
          nextToken
        }
        boardsDirectory
        version
      }
      userId
      board {
        id
        boardMeetingStage {
          stage
          id
          version
        }
        name {
          name
          version
        }
        meetingLifecycleTime {
          id
          scheduledStartTime
          scheduledEndTime
          startTime
          closedDate
          version
        }
        boardComponents
        boardParticipants {
          nextToken
        }
        pendingParticipants {
          nextToken
        }
        readOnlyIds
        editorIds
        ownerIds
        version
      }
      boardId
      status
      permission
    }
  }
`;
