import React from "react";
import { updateOpenedModal, MODALS } from "../redux/modules/modals";
import Button from "../components/Buttons/Button";

const SigninButton = () => {
  const onClick = () => {
    updateOpenedModal(MODALS.SIGN_IN_MODAL);
  };

  return <Button text={"Sign in"} onClick={onClick} />;
};

export default SigninButton;
