import { v4 as uuidv4 } from "uuid";
import {
  selectActiveUserEmail,
  selectIsNameExistsInDirectory
} from "../../redux/modules/user";
import { dateString } from "../../javascript/dateString";
import { store } from "../../redux/store";

export const generateInitialBoardName = state => {
  const date = dateString();
  const baseName = "New Board - " + date;
  var name = baseName
  var counter = 1
  while (true) {
    const isNameExists = selectIsNameExistsInDirectory(state, name);
    if(isNameExists){
      counter = counter + 1
      name = `${baseName} - ${counter}`
    } else {
      break;
    }
  }
  return name;
};

export const createEmptyBoard = state => ({
  id: undefined,
  name: {
    name: generateInitialBoardName(state),
    version: 1
  },
  boardComponents: {},
  meetingLifecycleTime: {
    id: uuidv4(),
    version: 0
  },
  boardMeetingStage: {
    id: uuidv4(),
    version: 0
  },
  ownerIds: [selectActiveUserEmail(state)],
  readOnlyIds: [],
  editorIds: []
});
