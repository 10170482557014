import React, { useEffect } from "react";
import "./App.css";
import Board from "./board/Board";
import WelcomeScreen from "./onboarding/WelcomeScreen";
import { Switch, Route } from "react-router-dom";
import ActivityMonitor from "./components/ActivityMonitor";
import { updateOpenedModal, MODALS } from "./redux/modules/modals";
import AllFloatingButtons from "./board/floatingButtons/AllFloatingButtons";

// SIGN_IN_MODAL: "SIGN_IN_MODAL",
// COULD_NOT_FIND_BOARD_MODAL: "COULD_NOT_FIND_BOARD_MODAL",
// SIGN_IN_IN_PROGRESS_MODAL: "SIGN_IN_IN_PROGRESS_MODAL",
// SIGN_IN_FAILED_MODAL: "SIGN_IN_FAILED_MODAL",
// EXPORT_TO_MAIL_MODAL: "EXPORT_TO_MAIL_MODAL",
// JOINING_BOARD_MODAL: "JOINING_BOARD_MODAL",
// CREATE_BOARD_MODAL: "CREATE_BOARD_MODAL",
// DELETE_DIRECTORY_ITEM_MODAL: "DELETE_DIRECTORY_ITEM_MODAL",
// PARTICIPANTS_MODAL: "PARTICIPANTS_MODAL",
// REQUESTED_TO_JOIN_BOARD: "REQUESTED_TO_JOIN_BOARD",
// WAITING_OWNER_APPROVAL: "WAITING_OWNER_APPROVAL",
// BOARD_OWNER_DECLINED_REQUEST: "BOARD_OWNER_DECLINED_REQUEST"

const App = () => {
  // useEffect(() => {
  //   setTimeout(() => updateOpenedModal(MODALS.APPROVE_LEGAL), 5000);
  // }, []);
  return (
    <>
      <Switch>
        <Route path="/" exact>
          <WelcomeScreen />
        </Route>
        <Route path="/boards/:id">
          <Board />
          <AllFloatingButtons />
        </Route>
      </Switch>
      <ActivityMonitor />
    </>
  );
};

export default App;
