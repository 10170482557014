import { createBoardComponent } from "../../redux/modules/board";

import { FREE_TEXT_CARD_TYPE } from "../../consts/boardComponentsTypes";
import { chooseRandomColor } from "../../consts/colors";

export const createNewCard = (props) => {
  const INITIAL_CARD_WIDTH = 500;
  const INITIAL_CARD_HEIGHT = 300;

  var boardComponent = createBoardComponent(
    props.location,
    FREE_TEXT_CARD_TYPE,
  );
  boardComponent.size = {
    width: INITIAL_CARD_WIDTH,
    height: INITIAL_CARD_HEIGHT,
  };
  boardComponent.color = chooseRandomColor();
  boardComponent.editorState = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  boardComponent = { ...boardComponent, ...props };
  return boardComponent;
};
