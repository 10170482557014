import React from "react";
import { useHover } from "react-hooks-lib";
import { COLOR_BELIZE_HOLE, COLOR_SILVER } from "../../consts/colors";

const Button = ({ onClick, text, style }) => {
  const { hovered, bind: hoveredBind } = useHover();

  return (
    <div
      {...hoveredBind}
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 25,
        fontSize: 18,
        padding: 10,
        width: 70,
        cursor: "pointer",
        color: COLOR_BELIZE_HOLE,
        userSelect: "none",
        WebkitUserSelect: "none",
        backgroundColor: hovered ? COLOR_SILVER : undefined,
        opacity: hovered ? 0.8 : undefined,
        ...style
      }}
    >
      <div>{text}</div>
    </div>
  );
};

export default Button;
