import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import {
  currentSubscribedWhiteBoardId,
  isSubscriptionActive as boardSubscription
} from "../backend/api/boardDbApi";

import {
  isSubscriptionActive as isUserSubsciptionActive,
  userSubscription
} from "../backend/api/userDbApi";

const CHECKING_ACTIVITY_DEBOUNCE = 7.5 * 50 * 1000;
const IDLE_TIMEOUT = 15 * 60 * 1000;

export var isUserActive = true;

export default class ActivityMonitor extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          throttle={CHECKING_ACTIVITY_DEBOUNCE}
          timeout={IDLE_TIMEOUT}
        />
        {/* your app here */}
      </div>
    );
  }
  _disconnectedAndShouldBeConnected() {
    return (
      (userSubscription === undefined && isUserSubsciptionActive === true) ||
      (boardSubscription === false &&
        currentSubscribedWhiteBoardId !== undefined)
    );
  }

  _handleActiveOrAction() {
    this._disconnectedAndShouldBeConnected() && window.location.reload();
    isUserActive = true;
  }

  _onAction(e) {
    this._handleActiveOrAction();
  }

  _onActive(e) {
    this._handleActiveOrAction();
  }

  _onIdle(e) {
    // console.log("user is idle", e);
    // console.log("last active", this.idleTimer.getLastActiveTime());
    isUserActive = false;
  }
}
