import React from "react";
import MenuButton from "../MenuButton";

export const ChangeComponentColorButton = ({
  onOpenChangeColorMenu,
  color
}) => {
  const onClick = e => {
    e.stopPropagation();
    onOpenChangeColorMenu(e);
  };

  return <MenuButton onClick={onClick} color={color} text="Change color" />;
};

export default ChangeComponentColorButton;
