import React, { useState, useEffect } from "react";
import BoardsDirectoryTab from './drawersTabs/boardsDirectoryTab/boardsDirectoryTab'
import CollaborateTab from "./drawersTabs/collaborateTab/CollaborateTab";
import TabsWrapper from "./drawersTabs/TabsWrapper";
import DrawerTitle from "./DrawerTitle";
import { DrawerWrapper } from "./DrawerWrapper";
import { TabsBar } from "./TabsBar";
import { useWindowHeight } from "@react-hook/window-size";
import createABoard from "../javascript/collaboration/createABoard";
import { DrawerCloseArrowButton } from "./DrawerCloseArrowButton";
import SignoutButton from "../components/SignoutButton";
import { TAB_INNER_PADDING } from "../consts/layout";
import {
  unsubscribeToUserDetails,
  subscribeToUserDetails,
  getUserDetails
} from "../backend/api/userDbApi";
import { updateUser } from "../redux/modules/user";
import { getCurrentAuthStorageUser } from "../backend/api/auth";

const DrawerHeader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: TAB_INNER_PADDING
      }}
    >
      <DrawerTitle />

      <SignoutButton />
    </div>
  );
};

const onUserSubscriptionData = response => {
  updateUser(response.data);
};

const getUserAndUpdateRedux = () => {
  getCurrentAuthStorageUser().then(cognitoUser => {
    getUserDetails(cognitoUser).then(response => {
      updateUser(response.data);
    });
  });
};

const Drawer = ({ isOpen, onClose, showCollaborateTab, hideBackground }) => {
  const windowHeight = useWindowHeight();
  const [choosenTabIndex, setChoosenTabIndex] = useState(0);
  const topPartHeight = 140;
  const tabsHieght = windowHeight - 140;
  // isOpen = true;
  useEffect(() => {
    if (isOpen) {
      getUserAndUpdateRedux();
      subscribeToUserDetails(onUserSubscriptionData);
    } else {
      unsubscribeToUserDetails();
    }
    return () => unsubscribeToUserDetails();
  }, [isOpen]);

  return (
    <DrawerWrapper
      onClose={onClose}
      isOpen={isOpen}
      hideBackground={hideBackground}
    >
      <div
        style={{
          position: "fixed",
          width: "100%",
          background: "white"
        }}
      >
        <DrawerCloseArrowButton onClick={onClose} />
        <DrawerHeader />
        <TabsBar
          onChoseTabIndex={i => setChoosenTabIndex(i)}
          choosenTabIndex={choosenTabIndex}
          tabsNames={
            showCollaborateTab ? ["Boards", "Collaborate"] : ["Boards"]
          }
        />
      </div>
      <div
        style={{
          position: "absolute",
          height: tabsHieght,
          top: topPartHeight + 15,
          overflowY: "scroll",
          width: "100%"
        }}
      >
        <TabsWrapper height={tabsHieght} choosenTabIndex={choosenTabIndex}>
          <BoardsDirectoryTab tabsHieght={tabsHieght} onClose={onClose} />
          {showCollaborateTab && (
            <CollaborateTab onClose={onClose} onCreateNewBoard={createABoard} />
          )}
        </TabsWrapper>
      </div>
    </DrawerWrapper>
  );
};

export default Drawer;
