import React from "react";
import { color } from "./color";
import { ONE_LINE_NO_WRAP } from "../../consts/styles";

export const Email = ({ isBigFormat, email }) => {
  return (
    <>
      {!isBigFormat && (
        <div
          style={{
            fontSize: 15,
            fontWeight: "300",
            ...ONE_LINE_NO_WRAP
          }}
        >
          {email}
        </div>
      )}
      {isBigFormat && (
        <div
          style={{
            fontSize: 18,
            fontWeight: "600",
            color,
            alignItems: "center",
            overflow: "hidden",
            ...ONE_LINE_NO_WRAP
          }}
        >
          {email}
        </div>
      )}
    </>
  );
};
