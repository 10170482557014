import React from "react";
import MenuButton from "../floatingMenu/MenuButton";
import { useDispatch } from "react-redux";
import { createNewCard } from "./createNewCard";
import { updateBoardComponent } from "../../redux/modules/boardsDict";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import ReactGA from "react-ga";
var mixpanel = require('mixpanel-browser');

export const AddCardButton = ({ newBoardComponentLocation, onCloseMenu }) => {
  const dispatch = useDispatch();

  const onClick = (e) => {
    const location = {
      x: newBoardComponentLocation.x - 100,
      y: newBoardComponentLocation.y - 15,
    };
    const card = createNewCard({ location });
    mixpanel.track("Created card component", {
      location: newBoardComponentLocation,
    });

    ReactGA.event({
      category: "Generic Catagory",
      action: "Created card component",
      location: newBoardComponentLocation,

    });

    updateBoardComponent(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, card);
    onCloseMenu(e);
  };

  return <MenuButton onClick={onClick} text="Add a Card" />;
};

export default AddCardButton;
