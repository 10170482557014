import React from "react";
import { useSelector } from "react-redux";
import Seperator from "../Seperator";
import GimobyModal from "./GimobyModal";
import DesktopAccessDisabledIcon from "@material-ui/icons/DesktopAccessDisabled";
import { Button } from "@material-ui/core";
import { COLOR_ALIZRIN } from "../../consts/colors";
import { updateOpenedModal } from "../../redux/modules/modals";
import { useHistory } from "react-router-dom";
import { store } from "../../redux/store";
import { selectActiveBoardId } from "../../redux/modules/activeBoard";
import { BoldModalText } from "./BoldModalText";

const NoBoardBaseModal = ({ isOpen, text, ...props }) => {
  const history = useHistory();
  const activeBoardId = useSelector(selectActiveBoardId);

  const onClose = () => {
    updateOpenedModal(undefined);
    activeBoardId === undefined && history.push("/");
  };

  return (
    <GimobyModal
      isOpen={isOpen}
      backgroundColor={COLOR_ALIZRIN}
      onClose={onClose}
      {...props}
    >
      <Seperator height={20} />
      <DesktopAccessDisabledIcon
        style={{ colorPrimary: "white", fontSize: 80, color: "white" }}
      />
      <Seperator height={5} />
      <BoldModalText>{text}</BoldModalText>
      <Seperator height={15} />

      <Button
        onClick={onClose}
        variant="contained"
        style={{ background: "white" }}
      >
        <div style={{ fontWeight: "600", color: COLOR_ALIZRIN }}>Close</div>
      </Button>
      <Seperator height={15} />
    </GimobyModal>
  );
};

export default NoBoardBaseModal;
