import React from "react";
import BoardsDirectory from "./myBoardsDirectory/BoardsDirectory";
import BoardsInventations from "./boardsInventations/BoardsInventations";

export const BoardDirectoryTab = ({}) => {
  return (
    <>
      <BoardsInventations />
      <BoardsDirectory />
    </>
  );
};

export default BoardDirectoryTab