import { store } from "../redux/store";
import { selectZoomFactor, selectWindowWidthFactor } from "../redux/modules/activeBoard";

const z = number => {
  const state = store.getState();
  const factor = selectZoomFactor(state);
  const windowFacotor = selectWindowWidthFactor(state);
  return number * factor * windowFacotor;
};

export default z;
