import { Auth, Hub } from "aws-amplify";

var hubAuthListener = undefined;

export async function getCurrentAuthStorageUser() {
  var user = undefined;
  try {
    user = await Auth.currentAuthenticatedUser();
  } catch (err) {}
  return user;
}

export async function isUserLoggedInApi() {
  var user = undefined;
  user = await getCurrentAuthStorageUser();
  return user !== undefined;
}

export async function signOutApi() {
  return Auth.signOut();
}

export async function logInManually(userName, password) {
  var user;
  try {
    user = await Auth.signIn(userName, password);
  } catch (error) {
    console.log("error signing in", error);
    user = error;
  }
  return user;
}

export function launchLoginWithGoogleApi() {
  Auth.federatedSignIn({ provider: "Google" });
}

export async function initSignInListenerApi(onUserLoggedIn) {
  if (hubAuthListener) {
    return;
  }
  hubAuthListener = async ({ payload: { event, data } }) => {
    switch (event) {
      case "signIn":
        const hubLogInStatus = await isUserLoggedInApi();
        if (hubLogInStatus) {
          onUserLoggedIn();
        }
        break;
      default:
        break;
    }
  };
  Hub.listen("auth", hubAuthListener);

  var logInStatus = await isUserLoggedInApi();
  if (logInStatus) {
    onUserLoggedIn();
  }
}

export function unregisterSignInListenerApi() {
  if (!hubAuthListener) {
    return;
  }
  Hub.remove("auth", hubAuthListener);
  hubAuthListener = undefined;
}

// export async function getIdToken(){
//   return await Auth.currentSession().getIdToken().getJwtToken()
// }
