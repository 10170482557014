import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  subscribeToWhiteboardUpdates,
  unsubscribeToWhiteboardUpdates
} from "../backend/api/boardDbApi";
import { updateBoard } from "../redux/modules/boardsDict";
import {
  updateWindowWidthFactor,
  selectActiveBoardId,
  selectMagnifaierFactor,
  updateViewLocationByDelta,
  selectViewLocation,
  selectCurrentDraggedComponentId
} from "../redux/modules/activeBoard";
import { UPDATE_ORIGIN } from "../redux/reducers";
import SelectContentMenu from "./selectContentMenu/SelectContentMenu";
import { useParams, useHistory } from "react-router-dom";
import { useDrag, useWheel } from "react-use-gesture";
import a from "../javascript/a";
import z from "../javascript/z";
import { COLOR_CLOUDS } from "../consts/colors";
import useWindowSize from "@react-hook/window-size";
import BoardComponents from "./BoardComponents";
import patternImage from "../assets/pattern.png";
import joinABoard from "../javascript/collaboration/joinABoard";

const _ = require("lodash");

const Board = () => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [newItemMode, setNewItemMode] = useState(false);
  const [showSelectContentMenu, setShowSelectContentMenu] = useState(false);
  const history = useHistory();
  const [windowWidth, windowHeight] = useWindowSize();
  const viewLocation = useSelector(selectViewLocation);
  const currentDraggedComponentId = useSelector(
    selectCurrentDraggedComponentId
  );

  const [selectContentMenuLocation, setSelectContentMenuLocation] = useState({
    x: undefined,
    y: undefined
  });

  const boardId = useSelector(selectActiveBoardId);
  const magnifaierFacoter = useSelector(selectMagnifaierFactor); // Used to update board when screen size changes

  const { id } = useParams();

  const dispatch = useDispatch();

  const bind = useDrag(({ down, delta: [x, y] }) => {
    isMouseDown !== down && setIsMouseDown(down);
    if (down) {
      window.scrollBy(-x, -y);
      // updateViewLocationByDelta(a(-x), a(-y));
    }
  });

  // const bindWheel = useWheel(({ delta: [x, y] }) => {
  //   updateViewLocationByDelta(0, a(y));
  // });

  useEffect(() => {
    updateWindowWidthFactor(dispatch, windowWidth);
  }, [windowWidth]);

  const onBoardUpdate = board => {
    updateBoard(UPDATE_ORIGIN.REMOTE_CHANGE, board.data);
  };

  const isAlreadyJoined = () => boardId !== undefined && boardId === id;

  useEffect(() => {
    if (isAlreadyJoined()) {
      return;
    }

    
    joinABoard(id, history);
  }, [boardId, id]);

  useEffect(() => {
    if (boardId === undefined) {
      return;
    }
    subscribeToWhiteboardUpdates(boardId, onBoardUpdate);

    return () => {
      boardId !== undefined && unsubscribeToWhiteboardUpdates(boardId);
    };
  }, [boardId]);

  const openSelectContentMenu = e => {
    setShowSelectContentMenu(true);
    const { pageX: x, pageY: y } = e;
    setSelectContentMenuLocation({ x: a(x), y: a(y) });
  };

  const onClick = e => {
    newItemMode && openSelectContentMenu(e);
    setNewItemMode(false);
  };

  const onContextMenu = e => {
    e.preventDefault();
    openSelectContentMenu(e);
  };

  const BoardStyle = {
    cursor: newItemMode ? "pointer" : isMouseDown ? "grabbing" : "grab",
    background: COLOR_CLOUDS,
    boxShadow: "0px 0px 10px 0px rgba(57,57,57,0.4)",
    borderWidth: 0.3,
    minHeight: 5000,
    minWidth: 5000,

    backgroundImage: "url(" + patternImage + ")",
    // backgroundPosition: `${-z(viewLocation.x)}px ${z(-viewLocation.y)}px`,
    backgroundSize: `${z(10)}px`,
    position: "relative",
    WebkitUserSelect:
      isMouseDown || currentDraggedComponentId !== undefined
        ? "none"
        : undefined
  };

  return (
    <div
      {...bind()}
      // {...bindWheel()}
      onClick={onClick}
      onContextMenu={onContextMenu}
      style={BoardStyle}
    >
      {/* {boardComponentsIds.length === 0 && <EmptyBoardPlaceHolder />} */}
      <BoardComponents />
      {showSelectContentMenu && (
        <SelectContentMenu
          key={JSON.stringify(selectContentMenuLocation)}
          onClose={() => setShowSelectContentMenu(false)}
          location={selectContentMenuLocation}
        />
      )}
    </div>
  );
};

export default Board;
