import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import {
  updateZoomFactor,
  selectZoomFactor
} from "../../redux/modules/activeBoard";
import FloatingButton from "./FloatingButton";

const LESS_ZOOM_FACTOR = 9 / 10;
const MORE_ZOOM_FACTOR = 1 / LESS_ZOOM_FACTOR;

const ZoomButton = ({ plusOrMinus, children }) => {
  const dispatch = useDispatch();
  const zoomFactor = useSelector(selectZoomFactor);

  const localOnClick = () => {
    if (plusOrMinus === "-") {
      updateZoomFactor(dispatch, zoomFactor * LESS_ZOOM_FACTOR);
    } else if (plusOrMinus === "+") {
      updateZoomFactor(dispatch, zoomFactor * MORE_ZOOM_FACTOR);
    }
  };

  return <FloatingButton onClick={localOnClick}>{children}</FloatingButton>;
};

export const PlusZoomButton = () => (
  <ZoomButton plusOrMinus={"+"}>
    <AddCircleOutlineIcon style={{ fontSize: 40 }} />
  </ZoomButton>
);

export const MinusZoomButton = () => (
  <ZoomButton plusOrMinus={"-"}>
    <RemoveCircleOutlineIcon style={{ fontSize: 40 }} />
  </ZoomButton>
);
