import React, { useState } from "react";
import { useSelector } from "react-redux";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { useHover } from "react-hooks-lib";
import { Image } from "./Image";
import { UserName } from "./UserName";
import { Email } from "./Email";
import { PermissionButton } from "./PermissionButton";
import { PermissionsButtons } from "./PermissionsButtons";
import {
  PARTICIPENT_ROW_HEIGHT,
  PARTICIPENTS_WIDTH,
  PARTICIPENTS_PADDING
} from "./layout";
import { AproveOrNotButtons } from "../Buttons/ApproveOrNoButton/AproveOrNotButtons";
import { ONE_LINE_NO_WRAP } from "../../consts/styles";
import {
  changeParticipantBoardPermissions,
  assignUserPermissionToBoard,
  updatePendingConnectionStatus,
  getBoardById,
  updateParticipantConnectionStatus,
  removeUserPermissionFromBoard
} from "../../backend/api/boardDbApi";
import { store } from "../../redux/store";
import {
  selectBoardById,
  updateBoard,
} from "../../redux/modules/boardsDict";
import { selectActiveBoardId } from "../../redux/modules/activeBoard";
import {
  BOTH_SIDES_APPROVED,
  OWNER_DECLINED,
  USER_REMOVED_BY_OWNER
} from "../../consts/connectionsStatuses";
import { EDITOR_PERMISSION } from "../../consts/permissions";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import { CircularProgress } from "@material-ui/core";
import { selectActiveUserId } from "../../redux/modules/user";

const RAW_PADDING = 15;

const ParticipantRow = ({ isOwner, participant, isJoined, connection }) => {
  const { hovered, bind: hoveredBind } = useHover();
  const [inProgress, setInProgress] = useState(false);
  const activeUserId = useSelector(selectActiveUserId);
  const permission = connection.permission;
  const RAW_WRAPPER_STYLE = {
    display: "flex",
    height: PARTICIPENT_ROW_HEIGHT,
    width: PARTICIPENTS_WIDTH - PARTICIPENTS_PADDING,
    paddingTop: RAW_PADDING,
    paddingBottom: RAW_PADDING,
    paddingRight: PARTICIPENTS_PADDING,
    alignItems: "center",
    justifyContent: "space-between",

    cursor: isOwner && hovered ? "pointer" : undefined
  };

  const REMOVE_BUTTON_STYLE = {
    display: "flex",
    width: 61,
    justifyContnet: "center",
    alignItems: "center",
    marginLeft: 9
  };

  const onChangePermissions = newPermission => {
    const state = store.getState();
    const activeBoardId = selectActiveBoardId(state);
    const board = selectBoardById(state, activeBoardId);
    // console.log(
    //   board,
    //   participant.email,
    //   permission,
    //   connection.id,
    //   newPermission,
    //   connection.status
    // );
    changeParticipantBoardPermissions(
      board,
      participant.email,
      permission,
      connection.id,
      newPermission,
      connection.status
    );
  };

  const reloadActiveBoard = () => {
    const state = store.getState();
    const activeBoardId = selectActiveBoardId(state);
    getBoardById(activeBoardId).then(updatedBoard =>
      updateBoard(
        UPDATE_ORIGIN.REMOTE_CHANGE,
        updatedBoard.data,
        updatedBoard.data.id
      )
    );
  };

  const onApprove = async () => {
    setInProgress(true);
    const state = store.getState();
    const activeBoardId = selectActiveBoardId(state);
    const board = selectBoardById(state, activeBoardId);
    await updatePendingConnectionStatus(connection.id, BOTH_SIDES_APPROVED);
    await assignUserPermissionToBoard(
      board,
      EDITOR_PERMISSION,
      participant.email,
      BOTH_SIDES_APPROVED
    );
    reloadActiveBoard();
  };
  const onDecline = async () => {
    setInProgress(true);
    await updatePendingConnectionStatus(connection.id, OWNER_DECLINED);
    reloadActiveBoard();
  };

  const remove = async () => {
    setInProgress(true);

    const state = store.getState();
    const activeBoardId = selectActiveBoardId(state);
    const activeBoard = selectBoardById(state, activeBoardId);
    removeUserPermissionFromBoard(
      activeBoard,
      participant.email,
      connection.id
    );
  };

  return (
    <div {...hoveredBind} style={RAW_WRAPPER_STYLE}>
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <div style={REMOVE_BUTTON_STYLE}>
          {isOwner && hovered && activeUserId !== participant.id && (
            <PermissionButton
              isOwner={isOwner}
              onClick={remove}
              icon={<RemoveCircleOutlineIcon />}
              text="Remove"
              participantId={participant.id}
            />
          )}
        </div>
        <Image pictureURL={participant.pictureURL} />
        <div
          style={{
            width: 280,
            display: "flex-inline",
            paddingRight: 15,
            ...ONE_LINE_NO_WRAP
          }}
        >
          <div
            style={{
              // display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingLeft: participant.pictureURL ? RAW_PADDING : undefined
            }}
          >
            {participant.userName !== undefined && (
              <>
                <UserName userName={participant.userName} />
                <div style={{ height: 3 }} />
              </>
            )}
            <Email
              isBigFormat={participant.userName === undefined}
              email={participant.email}
            />
          </div>
        </div>
      </div>
      {inProgress && <CircularProgress size={20} thickness={4} />}

      {!inProgress && isJoined && (
        <PermissionsButtons
          isOwner={isOwner}
          permission={permission}
          onChangePermissions={onChangePermissions}
          participantId={participant.id}
        />
      )}
      {!inProgress && !isJoined && (
        <AproveOrNotButtons onApprove={onApprove} onDecline={onDecline} />
      )}
    </div>
  );
};

export default ParticipantRow;
