import API, { graphqlOperation } from "@aws-amplify/api";
import { getCurrentAuthStorageUser } from "./auth";
import { getUser, getUserByEmail } from "../../local-graphql/queries";
import { updateUser } from "../../local-graphql/mutations";
import { onUpdateUser } from "../../local-graphql/subscriptions";
import { replaceNullWithEmptyString, SUCCESS, formatApiReponse } from "./utils";
import { EMAIL_HAS_TOO_MANY_OCCURRENCES_ERROR } from "./errors";
const _ = require("lodash");

export async function getUserBasicDetailesByEmail(userEmail) {
  var resUser = undefined;
  var user = {};
  user.data = {};
  try {
    resUser = await API.graphql(
      graphqlOperation(getUserByEmail, { email: userEmail })
    );
    throwErrorIfFoundUser(resUser, "getUserByEmail");
    if (resUser.data.getUserByEmail.items.length == 1) {
      user.data.getUserByEmail = resUser.data.getUserByEmail.items[0];
    }
  } catch (err) {
    user = err;
  }
  user = formatApiReponse(user, "getUserByEmail");
  return user;
}

function throwErrorIfFoundUser(res, queryField) {
  if (res.data[queryField] === null) {
    throw Error(`The user is logged in but not registered in the DB`);
  }
  if (res.data[queryField].items && res.data[queryField].items.length > 1) {
    throw Error(EMAIL_HAS_TOO_MANY_OCCURRENCES_ERROR);
  }
}

export async function getUserDetails(user) {
  if (!user) {
    return undefined;
  }
  var res;
  try {
    res = await API.graphql(graphqlOperation(getUser, { id: user.username }));
    throwErrorIfFoundUser(res, "getUser");
  } catch (err) {
    res = err;
  }
  res = formatApiReponse(res, "getUser");
  return res;
}

export async function updateUserDetails(updatedContent) {
  var clonedUserDetails = _.cloneDeep(updatedContent);
  delete clonedUserDetails.participatingBoards;
  delete clonedUserDetails.pendingBoards;
  clonedUserDetails.boardsDirectory = JSON.stringify(
    clonedUserDetails.boardsDirectory
  );
  var res;
  try {
    res = await API.graphql(
      graphqlOperation(updateUser, { input: clonedUserDetails })
    );
    throwErrorIfFoundUser(res, "updateUser");
  } catch (err) {
    res = err;
  }
  res = formatApiReponse(res, "updateUser");
  return res;
}

export var isSubscriptionActive = false;
export var userSubscription = undefined;

export async function subscribeToUserDetails(onUserSubscriptionData) {
  var user = await getCurrentAuthStorageUser();

  userSubscription && unsubscribeToUserDetails();
  userSubscription = API.graphql(
    graphqlOperation(onUpdateUser, { id: user.username })
  ).subscribe({
    error: error => {
      console.log(error);
      userSubscription = undefined;
    },
    next: data => {
      const res = formatApiReponse(data.value, "onUpdateUser");
      onUserSubscriptionData(res);
    }
  });
  isSubscriptionActive = true;
}

export async function unsubscribeToUserDetails() {
  if (userSubscription === undefined) {
    return;
  }
  userSubscription.unsubscribe();
  userSubscription = undefined;
  isSubscriptionActive = false;
}
