import {
  PARTICIPANT_CONNECTION_TYPE,
  PENDING_CONNECTION_TYPE
} from "../../consts/connectionTypes";
import {
  READ_ONLY_PERMISSION,
  EDITOR_PERMISSION,
  OWNER_PERMISSION
} from "../../consts/permissions";

export const USER_ALREADY_IN_LIST_ERROR = `The User email is already a participant it the list specified`;

export const EMAIL_HAS_TOO_MANY_OCCURRENCES_ERROR = `Validation exception: there are multiple users with this emails`;

export const CONNECTION_TYPE_PROVIDED_INVALID_ERROR = `connectionType should by one of the following: ${PARTICIPANT_CONNECTION_TYPE} OR ${PENDING_CONNECTION_TYPE}`;

export const PERMISSION_TYPE_PROVIDED_INVALID_ERROR = `The Permission Type specified is not allowed, should be - ${READ_ONLY_PERMISSION} OR ${EDITOR_PERMISSION} OR ${OWNER_PERMISSION} `;

export const DOUBLE_PERMISSIONS =
  "The Permission alternation requested is not possible, The user has multiple permissions for this board";

export const NO_PRIOR_PERMISSIONS =
  "The Permission alternation requested is not possible, The user didn't have prior permissions";
