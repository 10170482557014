import { updateUserDetails } from "../api/userDbApi";

const _ = require("lodash");

const updateRemoteUser = user => {
  if (user === undefined || user.id === undefined) {
    return;
  }

  updateUserDetails(user);
};

export default updateRemoteUser;
