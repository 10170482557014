import React, { useEffect } from "react";
import ProgressModal from "./ProgressModal";
import { useParams, useHistory } from "react-router-dom";
import { updateOpenedModal, MODALS } from "../../redux/modules/modals";
import { store } from "../../redux/store";
import {
  selectBoardById,
  selectConnectionByBoardId
} from "../../redux/modules/boardsDict";
import {
  BOTH_SIDES_APPROVED,
  WAITING_OWNER_APPROVAL,
  OWNER_DECLINED,
  WAITING_USER_APPROVAL
} from "../../consts/connectionsStatuses";
import joinABoard from "../../javascript/collaboration/joinABoard";
import { selectActiveUser, updateUser } from "../../redux/modules/user";
import { getUserDetails } from "../../backend/api/userDbApi";
import { getCurrentAuthStorageUser } from "../../backend/api/auth";

const POLLING_INTERVALS = 10 * 1000;
const MAX_WAITING_TIME = 60 * 60 * 1000;

const checkPendingConnectionWhenWaitingToUserApproval = async boardId => {
  const cognitoUser = await getCurrentAuthStorageUser();
  const response = await getUserDetails(cognitoUser);
  const user = response.data
  updateUser(user);

  const connection = selectConnectionByBoardId(user.pendingBoards, boardId);

  if (!connection) {
    throw Error();
  }

  switch (connection.status) {
    case WAITING_OWNER_APPROVAL:
      return;
    case OWNER_DECLINED:
      updateOpenedModal(MODALS.OWNER_DECLINED_JOIN_REQUEST);
      return;
    case BOTH_SIDES_APPROVED:
      await joinABoard(boardId);
      return;
    default:
      throw Error();
  }
};

const WaitForOwnerApproval = props => {
  const history = useHistory();

  const onClose = () => {
    updateOpenedModal(undefined);
    history.push("/");
  };
  useEffect(() => {
    var effectFlag = true;

    const intervalId = setInterval(() => {
      if (!effectFlag) {
        return;
      }
      checkPendingConnectionWhenWaitingToUserApproval(props.extraData.boardId);
    }, POLLING_INTERVALS);

    const waitingTimeoutId = setTimeout(() => {
      if (!effectFlag) {
        return;
      }
      clearInterval(intervalId);
      updateOpenedModal(MODALS.REQUESTED_TO_JOIN_BOARD, {
        boardId: props.extraData.boardId
      });
    }, MAX_WAITING_TIME);

    return () => {
      clearInterval(intervalId);
      clearTimeout(waitingTimeoutId);
      effectFlag = false;
    };
  }, []);

  return (
    <ProgressModal
      {...props}
      text="Waiting for owner approval..."
      autoClose={false}
      onClose={onClose}
    />
  );
};

export default WaitForOwnerApproval;
