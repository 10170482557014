import React from "react";
import { useSelector } from "react-redux";
import { MODALS } from "../../redux/modules/modals";
import SigninModal from "./SigninModal";
import SigninInProgressModal from "./SigninInProgressModal";
import SigninFailedModal from "./SigninFailedModal";
import { v4 as uuidv4 } from "uuid";
import ExportToMailModal from "./ExportToMailModal";
import JoiningBoardModal from "./JoiningBoardModal";
import CreateBoardModal from "./CreateBoardModal";
import CouldNotFindBoardModal from "./CouldNotFindBoardModal";
import DeleteDirectoryItemModal from "./DeleteDirectoryItemModal";
import ParticipantsModal from "./ParticipantsModal";
import RequestedToJoinBoard from "./RequestedToJoinBoard";
import WaitForOwnerApproval from "./WaitForOwnerApproval";
import BoardOwnerDeclinedRequest from "./BoardOwnerDeclinedRequest";
import ApproveLegalModal from "./ApproveLegalModal";
import LoadingModal from "./LoadingModal";

const ModalsMannager = () => {
  const currentOpenedModal = useSelector(
    state => state.modals.currentOpenedModal
  );

  const extraData = useSelector(state => state.modals.extraData);
  return (
    <>
      {/* Modals */}
      <SigninModal
        key={uuidv4()}
        isOpen={currentOpenedModal === MODALS.SIGN_IN_MODAL}
      />
      <SigninInProgressModal
        key={uuidv4()}
        isOpen={currentOpenedModal === MODALS.SIGN_IN_IN_PROGRESS_MODAL}
      />
      <SigninFailedModal
        key={uuidv4()}
        isOpen={currentOpenedModal === MODALS.SIGN_IN_FAILED_MODAL}
      />
      <ExportToMailModal
        key={uuidv4()}
        isOpen={currentOpenedModal === MODALS.EXPORT_TO_MAIL_MODAL}
      />
      {currentOpenedModal === MODALS.JOINING_BOARD_MODAL && (
        <JoiningBoardModal
          key={uuidv4()}
          isOpen={currentOpenedModal === MODALS.JOINING_BOARD_MODAL}
          animate={false}
        />
      )}

      {currentOpenedModal === MODALS.CREATE_BOARD_MODAL && (
        <CreateBoardModal
          key={uuidv4()}
          isOpen={currentOpenedModal === MODALS.CREATE_BOARD_MODAL}
        />
      )}

      {currentOpenedModal === MODALS.COULD_NOT_FIND_BOARD_MODAL && (
        <CouldNotFindBoardModal
          key={uuidv4()}
          isOpen={currentOpenedModal === MODALS.COULD_NOT_FIND_BOARD_MODAL}
        />
      )}

      {currentOpenedModal === MODALS.DELETE_DIRECTORY_ITEM_MODAL && (
        <DeleteDirectoryItemModal
          key={uuidv4()}
          isOpen={currentOpenedModal === MODALS.DELETE_DIRECTORY_ITEM_MODAL}
          extraData={extraData}
        />
      )}

      {currentOpenedModal === MODALS.PARTICIPANTS_MODAL && (
        <ParticipantsModal
          key={uuidv4()}
          isOpen={currentOpenedModal === MODALS.PARTICIPANTS_MODAL}
          extraData={extraData}
        />
      )}

      {currentOpenedModal === MODALS.REQUESTED_TO_JOIN_BOARD && (
        <RequestedToJoinBoard
          key={uuidv4()}
          isOpen={currentOpenedModal === MODALS.REQUESTED_TO_JOIN_BOARD}
          extraData={extraData}
        />
      )}

      {currentOpenedModal === MODALS.WAITING_OWNER_APPROVAL && (
        <WaitForOwnerApproval
          key={uuidv4()}
          isOpen={currentOpenedModal === MODALS.WAITING_OWNER_APPROVAL}
          extraData={extraData}
        />
      )}

      {currentOpenedModal === MODALS.OWNER_DECLINED_JOIN_REQUEST && (
        <BoardOwnerDeclinedRequest
          key={uuidv4()}
          isOpen={currentOpenedModal === MODALS.OWNER_DECLINED_JOIN_REQUEST}
          extraData={extraData}
        />
      )}

      {currentOpenedModal === MODALS.LOADING_MODAL && (
        <LoadingModal
          key={uuidv4()}
          isOpen={currentOpenedModal === MODALS.LOADING_MODAL}
        />
      )}

      {/* {currentOpenedModal === MODALS.APPROVE_MODAL && (
        <ApproveLegalModal
          key={uuidv4()}
          isOpen={currentOpenedModal === MODALS.APPROVE_MODAL}
        />
      )} */}
    </>
  );
};

export default ModalsMannager;
