import { Transforms, Editor } from "slate";

const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format
  });

  return !!match;
};

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};



const getLeafAux = (node, path, index) => {
  return index < path.length
    ? getLeafAux(node.children[path[index]], path, index + 1)
    : node;
};

const getLeaf = (editorState, path) =>
  getLeafAux({ children: editorState }, path, 0);

export const checkIfLocationInChildren = (location, editorState) => {
  try {
    // Check if block exists, throws error if out of bounds
    const node = getLeaf(editorState, location.path);
    if (node.text.length < location.offset) {
      throw Error();
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const blur = editor => {
  Transforms.setSelection(editor, {
    focus: {
      path: [0, 0],
      offset: 0
    },
    anchor: {
      path: [0, 0],
      offset: 0
    }
  });
  document.activeElement.blur();
};
