import React from "react";

const DrawerTitle = () => {
  return (
    <div
      style={{
        fontSize: 25,
        fontWeight: "700"
      }}
    >
      Gimoby Boards
    </div>
  );
};

export default DrawerTitle;
