import boardsDict, { STATE_KEY as BOARDS_DICT_KEY } from "./modules/boardsDict";
import user, { STATE_KEY as USER_KEY } from "./modules/user.js";
import modals, { STATE_KEY as MODALS_KEY } from "./modules/modals.js";
import activeBoard, {
  STATE_KEY as ACTIVE_BOARD_KEY
} from "./modules/activeBoard.js";
import { store } from "./store";

const INIT_STATE = "INIT_STATE";

export const UPDATE_ORIGIN = {
  LOCAL_CHANGE: "LOCAL_CHANGE",
  REMOTE_CHANGE: "REMOTE_CHANGE"
};

export var reduxUpdateOrigin = undefined;

export const initRedux = () => {
  store.dispatch({
    type: INIT_STATE,
    origin: UPDATE_ORIGIN.LOCAL_CHANGE,
    payload: {}
  });
};

const reducer = (state = {}, action) => {
  if (action.payload && action.payload.origin === undefined) {
    throw Error("action.payload.origin must be defined");
  }
  reduxUpdateOrigin = action.payload && action.payload.origin;
  if (action.type === INIT_STATE) {
    state = {};
  }
  return {
    [USER_KEY]: user(state[USER_KEY], action),
    [MODALS_KEY]: modals(state[MODALS_KEY], action),
    [ACTIVE_BOARD_KEY]: activeBoard(state[ACTIVE_BOARD_KEY], action),
    [BOARDS_DICT_KEY]: boardsDict(state, action)
  };
};

export default reducer;
