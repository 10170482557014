import React from "react";
import Seperator from "../Seperator";
import GimobyModal from "./GimobyModal";
import { COLOR_BELIZE_HOLE } from "../../consts/colors";
import { useHistory } from "react-router-dom";
import { updateOpenedModal } from "../../redux/modules/modals";
import { useDispatch } from "react-redux";
import SendIcon from "@material-ui/icons/Send";
import { useHover } from "react-hooks-lib";

const SendButton = ({ onClick }) => {
  const { hovered, bind: hoveredBind } = useHover();

  return (
    <div
      onClick={onClick}
      // variant="contained"
      {...hoveredBind}
      style={{
        display: "flex",
        background: hovered ? "white" : COLOR_BELIZE_HOLE,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 50,
        padding: 10,
        cursor: "pointer"
      }}
    >
      <SendIcon
        style={{
          color: hovered ? COLOR_BELIZE_HOLE : "white",
          fontSize: 17,
          marginTop: -1
        }}
      />
      <div
        style={{
          fontWeight: "500",
          color: hovered ? COLOR_BELIZE_HOLE : "white",
          paddingLeft: 8
        }}
      >
        SEND
      </div>
    </div>
  );
};

//1B998B
const ExportToMailModal = ({ isOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const text = "Sign in failed, maybe try again later";

  const onClose = () => {
    updateOpenedModal(undefined);
  };

  return (
    <GimobyModal
      isOpen={isOpen}
      backgroundColor={COLOR_BELIZE_HOLE}
      onClose={onClose}
      animate={true}
      innerStyle={{
        padding: 0,
        boxShadow: "0px 0px 6px 2px rgba(70,70,70,0.2)"
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: 17,
            paddingLeft: 20
            // paddingTop: 20
          }}
        >
          <h3 style={{ color: "white" }}>Meeting Summary</h3>
          <SendButton />
        </div>

        <div
          style={{
            width: "100%",
            height: 300,
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
            backgroundColor: "white"
          }}
        ></div>
      </div>
      {/* <Seperator height={25} />

      <div
        style={{
          width: "100%",
          height: 40,
          borderRadius: 15,
          backgroundColor: "white"
        }}
      ></div>
      <Seperator height={25} />

      <Button
        onClick={onClose}
        variant="contained"
        style={{ background: "white" }}
      >
        <div style={{ fontWeight: "600", color: COLOR_BELIZE_HOLE }}>Send</div>
      </Button> */}
    </GimobyModal>
  );
};

export default ExportToMailModal;
