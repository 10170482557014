import React, { useState } from "react";
import { useSelector } from "react-redux";
import { TAB_INNER_PADDING } from "../../../../consts/layout";
import {
  selectBoardInventaionsOfActiveUser,
  addNewBoardAfterId,
  updateUser
} from "../../../../redux/modules/user";
import { AproveOrNotButtons } from "../../../../components/Buttons/ApproveOrNoButton/AproveOrNotButtons";
import {
  COLOR_VERY_DARK_BLUE,
  COLOR_TURQUOISE
} from "../../../../consts/colors";
import { updateParticipantConnectionStatus } from "../../../../backend/api/boardDbApi";
import {
  BOTH_SIDES_APPROVED,
  USER_DECLINED
} from "../../../../consts/connectionsStatuses";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getCurrentAuthStorageUser } from "../../../../backend/api/auth";
import { getUserDetails } from "../../../../backend/api/userDbApi";

const SubTitle = ({ text }) => {
  return (
    <div
      style={{ color: COLOR_VERY_DARK_BLUE, fontWeight: "700", fontSize: 17 }}
    >
      {text}
    </div>
  );
};

const OneInventation = ({ connection }) => {
  const [isLoading, setIsLoading] = useState(false);
  const onApprove = () => {
    setIsLoading(true);
    updateParticipantConnectionStatus(connection.id, BOTH_SIDES_APPROVED).then(
      () => {
        addNewBoardAfterId(undefined, connection.boardId);
      }
    );
  };

  const onDecline = () => {
    setIsLoading(true);
    updateParticipantConnectionStatus(connection.id, USER_DECLINED).then(() =>
      getCurrentAuthStorageUser().then(cognitoUser => {
        getUserDetails(cognitoUser).then(response => {
          updateUser(response.data);
        });
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        paddingTop: 20,
        color: "#333",
        alignItems: "center",
        justifyContent: "space-between",
        fontWeight: "500"
      }}
    >
      <div style={{ marginTop: -5 }}>{connection.board.name.name}</div>

      {!isLoading && (
        <AproveOrNotButtons
          onApprove={onApprove}
          onDecline={onDecline}
          fontSize={12}
        />
      )}
      {isLoading === true && (
        <CircularProgress
          classes={{
            colorPrimary: "white"
          }}
          // color="primary"
          size={20}
          thickness={3}
          style={{ colorPrimary: COLOR_TURQUOISE }}
        />
      )}
    </div>
  );
};

const BoardsInventations = ({ onClick, color }) => {
  const connections = useSelector(selectBoardInventaionsOfActiveUser);
  if (connections.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        paddingTop: TAB_INNER_PADDING - 5,
        paddingLeft: TAB_INNER_PADDING,
        paddingRight: TAB_INNER_PADDING
      }}
    >
      <SubTitle text="Boards Inventations" />
      {connections.map(c => (
        <OneInventation key={c.boardId} connection={c} />
      ))}

      <div style={{ height: 35 }} />
      <div style={{ marginBottom: -10 }}>
        <SubTitle text="My Boards" />
      </div>
    </div>
  );
};

export default BoardsInventations;
