import React from "react";
import ReactDOM from "react-dom";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatStrikethroughIcon from "@material-ui/icons/FormatStrikethrough";
import FormatSizeIcon from "@material-ui/icons/FormatSize";
import LinkIcon from "@material-ui/icons/Link";

import { COLOR_ASBESTOS } from "../../consts/colors";
import z from "../../javascript/z";
import { useHover } from "react-hooks-lib";
import { useSlate } from "slate-react";
import { isBlockActive, toggleBlock, isMarkActive, toggleMark } from "./Utils";
import { isLinkActive, insertLink, unwrapLink } from "./withLinks";

const Icon = React.forwardRef(
  ({ color, className, iconName, active, ...props }, ref) => {
    const { hovered, bind: hoveredBind } = useHover();

    const iconStyle = {
      fontSize: z(30),
      padding: z(8),
      borderRadius: 50,
      cursor: "pointer",
      opacity: active ? 0.8 : undefined,
      color: active ? "white" : hovered ? color : COLOR_ASBESTOS,
      backgroundColor: active ? color : undefined,
    };
    var CurrentIcon = {
      FormatBold: <FormatBoldIcon style={iconStyle} />,
      FormatItalic: <FormatItalicIcon style={iconStyle} />,
      FormatUnderline: <FormatUnderlinedIcon style={iconStyle} />,
      LooksOne: <FormatSizeIcon style={iconStyle} />,
      // LooksTwo: <LooksTwoIcon style={iconStyle} />,
      LinkIcon: <LinkIcon style={iconStyle} />,
      FormatListNumbered: <FormatListNumberedIcon style={iconStyle} />,
      FormatListBulleted: <FormatListBulletedIcon style={iconStyle} />,
      FormatStrikethrough: <FormatStrikethroughIcon style={iconStyle} />,
    }[iconName];
    return (
      <span {...hoveredBind} {...props} ref={ref}>
        {CurrentIcon}
      </span>
    );
  }
);

export const BlockButton = ({ color, format, icon }) => {
  const editor = useSlate();
  return (
    <Icon
      iconName={icon}
      color={color}
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    />
  );
};

export const MarkButton = ({ color, format, icon }) => {
  const editor = useSlate();
  return (
    <Icon
      iconName={icon}
      color={color}
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    />
  );
};

export const LinkButton = ({ color }) => {
  const editor = useSlate();
  return (
    <Icon
      iconName={"LinkIcon"}
      color={color}
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
          return;
        }
        event.preventDefault();
        const url = window.prompt("Enter the URL of the link:");
        if (!url) return;
        insertLink(editor, url);
      }}
    />
  );
};
