
/**
 * The user was added by the board owner but didn't approve the inventaion yet
 */
export const WAITING_USER_APPROVAL = "WAITING_USER_APPROVAL"
/**
 * The user requested to join the board but the board owner haven't approved it yet
 */
export const WAITING_OWNER_APPROVAL = "WAITING_OWNER_APPROVAL"
/**
 * Both sides approved to join the board
 */
export const BOTH_SIDES_APPROVED = "BOTH_SIDES_APPROVED"
/**
 * The user declined the board owner's invention
 */
export const USER_DECLINED = "USER_DECLINED"
/**
 * The board owner declined the join request made by a user
 */
export const OWNER_DECLINED = "OWNER_DECLINED"

export const USER_LEFT = "USER_LEFT"

export const USER_REMOVED_BY_OWNER = "USER_REMOVED_BY_OWNER"