import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { BORDER_PADDING } from "../../consts/layout";
import PeopleIcon from "@material-ui/icons/People";
import { MODALS, updateOpenedModal } from "../../redux/modules/modals";
import { selectActiveBoardId } from "../../redux/modules/activeBoard";
import { getBoardById } from "../../backend/api/boardDbApi";
import {
  updateBoard,
  selectJoinRequestsList,
  selectIsActiveUserOwnerInBoard
} from "../../redux/modules/boardsDict";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import { Badge } from "../../components/Badge";

const style = {
  padding: 10,
  position: "fixed",
  bottom: 10 + BORDER_PADDING,
  left: BORDER_PADDING,
  cursor: "pointer"
};

const ParticipentsButton = () => {
  const activeBoardId = useSelector(selectActiveBoardId);
  const isOwner = useSelector(state =>
    selectIsActiveUserOwnerInBoard(state, activeBoardId)
  );
  const joinRequestsList = useSelector(state =>
    selectJoinRequestsList(state, activeBoardId)
  );
  const localOnClick = useCallback(e => {
    e.stopPropagation();
    getBoardById(activeBoardId).then(board =>
      updateBoard(UPDATE_ORIGIN.REMOTE_CHANGE, board.data, board.data.id)
    );
    updateOpenedModal(MODALS.PARTICIPANTS_MODAL, { boardId: activeBoardId });
  });

  const icon = useCallback(() => {
    return <PeopleIcon style={{ fontSize: 40 }} />;
  });

  return (
    <Button onClick={localOnClick} style={style}>
      {isOwner && joinRequestsList.length > 0 && (
        <Badge number={joinRequestsList.length} />
      )}
      {icon()}
    </Button>
  );
};

export default ParticipentsButton;
