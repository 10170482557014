import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Seperator from "../../../components/Seperator";
import { COLOR_VERY_DARK_BLUE } from "../../../consts/colors";
import Text from "../../../components/Text";
import { createEmptyBoard } from "../../../board/templates/createEmptyBoard";
import createDailyBoard, {
  DAILY_TEMPLATE,
} from "../../../board/templates/dailyBoardTemplate";
import createABoard from "../../../javascript/collaboration/createABoard";
import { useHistory } from "react-router-dom";
import {
  isUserSignin,
  selectActiveUserEmail,
} from "../../../redux/modules/user";
import { MODALS, updateOpenedModal } from "../../../redux/modules/modals";
import { store } from "../../../redux/store";
import createOneOnOneBoard from "../../../board/templates/firstOneOnOne";
import ReactGA from "react-ga";
var mixpanel = require("mixpanel-browser");

const EMPTY_TEMPLATE = "EMPTY_TEMPLATE";
const FIRST_ONE_ON_ONE_TEMPLATE = "FIRST_ONE_ON_ONE_TEMPLATE";

const getBoardFromTemplate = (templateType) => {
  switch (templateType) {
    case EMPTY_TEMPLATE:
      return createEmptyBoard(store.getState());
    case FIRST_ONE_ON_ONE_TEMPLATE:
      return createOneOnOneBoard(store.getState());
    case DAILY_TEMPLATE:
      return createDailyBoard();
    default:
      return createEmptyBoard(store.getState());
  }
};

const HostBoardSection = () => {
  const [selectTemplate, setSelectTemplate] = useState(EMPTY_TEMPLATE);
  const history = useHistory();
  const isSignedIn = useSelector(isUserSignin);

  const onClick = () => {
    if (!isSignedIn) {
      updateOpenedModal(MODALS.SIGN_IN_MODAL);
      return;
    }
    const boardTemplate = getBoardFromTemplate(selectTemplate);
    mixpanel.track("Create A board", { selectTemplate });

    ReactGA.event({
      category: "Generic Catagory",
      action: "Create A board",
      selectTemplate,
    });
    createABoard(boardTemplate, history);
  };

  return (
    <>
      <h3>Host a Board</h3>

      <FormControl fullWidth variant="outlined">
        <InputLabel id="board-template-label">Board template</InputLabel>
        <Select
          labelId="board-template-label"
          id="board-template-label-outlined"
          value={selectTemplate}
          onChange={(e) => setSelectTemplate(e.target.value)}
          label="Board template"
        >
          <MenuItem value={EMPTY_TEMPLATE}>Empty board</MenuItem>
          <MenuItem value={FIRST_ONE_ON_ONE_TEMPLATE}>
            First One on One
          </MenuItem>
        </Select>
      </FormControl>

      <Seperator height={20} />

      <Button
        fullWidth
        color="primary"
        style={{ backgroundColor: COLOR_VERY_DARK_BLUE }}
        onClick={onClick}
      >
        <Text text="Create" style={{ color: "white" }} />
      </Button>
    </>
  );
};

export default HostBoardSection;
