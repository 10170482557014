export const replaceEmptyStringWithNull = o => {
  if (typeof o !== "object") {
    return;
  }
  for (let k in o) {
    if (o[k] === "") {
      o[k] = null;
      continue;
    }
    replaceEmptyStringWithNull(o[k]);
  }
};

export const replaceNullWithEmptyString = o => {
  if (typeof o !== "object") {
    return;
  }

  for (let k in o) {
    if (o[k] === null) {
      if (k === "version") {
        o[k] = 0;
      } else {
        o[k] = "";
      }
      continue;
    }
    replaceNullWithEmptyString(o[k]);
  }
};

export const USER_NOT_LOGGED_IN = "No current user";
export const USER_NOT_AUTHORIZED = "Unauthorized";
export const NO_SUCH_BOARD = "No such Board";
export const UNHANDLED_ERROR = "Unhandled Error";
export const SUCCESS = "Success";
export const DYNAMO_CONDITION_FAIL = "DynamoDB:ConditionalCheckFailedException";

const formatResponseData = dataResponse => {
  if (dataResponse === undefined || dataResponse === null) {
    return undefined;
  }
  replaceNullWithEmptyString(dataResponse);

  if (dataResponse.boardComponents) {
    dataResponse.boardComponents = JSON.parse(dataResponse.boardComponents);
  }
  if (dataResponse.boardsDirectory) {
    dataResponse.boardsDirectory = JSON.parse(dataResponse.boardsDirectory);
  }
  return dataResponse;
};

export function formatApiReponse(apiReponse, queryField) {
  if (
    apiReponse.data &&
    apiReponse.data[queryField] !== undefined &&
    apiReponse.data[queryField] !== null
  ) {
    return {
      data: formatResponseData(apiReponse.data[queryField]),
      error: apiReponse.errors,
      status: SUCCESS
    };
  }
  var errorMessage = undefined;
  var data = undefined;
  if (apiReponse.errors && apiReponse.errors[0]) {
    errorMessage = apiReponse.errors[0].errorType;
    data = formatResponseData(apiReponse.errors[0].data);
  } else {
    errorMessage = apiReponse;
  }

  switch (errorMessage) {
    case USER_NOT_LOGGED_IN:
    case USER_NOT_AUTHORIZED:
    case NO_SUCH_BOARD:
      return {
        status: errorMessage,
        data
      };
      break;
    case DYNAMO_CONDITION_FAIL:
      return {
        status: USER_NOT_AUTHORIZED,
        error: DYNAMO_CONDITION_FAIL,
        data
      };
      break;
    default:
      return {
        status: UNHANDLED_ERROR,
        error: errorMessage,
        data
      };
  }
}
