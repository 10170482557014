import API, { graphqlOperation } from "@aws-amplify/api";
import { getBoard, getUserByEmail } from "../../local-graphql/queries";
import { onUpdateBoard } from "../../local-graphql/subscriptions";
import {
  createBoard,
  updateBoard as updateBoardQuery,
  deleteBoard,
  createBoardParticipantConnection,
  updateBoardParticipantConnection,
  deleteBoardParticipantConnection,
  createPendingParticipant,
  updatePendingParticipant,
  deletePendingParticipant,
} from "../../local-graphql/mutations";
import {
  BOTH_SIDES_APPROVED,
  USER_REMOVED_BY_OWNER,
} from "../../consts/connectionsStatuses";
import {
  READ_ONLY_PERMISSION,
  EDITOR_PERMISSION,
  OWNER_PERMISSION,
} from "../../consts/permissions";

import {
  replaceEmptyStringWithNull,
  replaceNullWithEmptyString,
  formatApiReponse,
  NO_SUCH_BOARD,
  SUCCESS,
  USER_NOT_AUTHORIZED,
} from "./utils";
import {
  USER_ALREADY_IN_LIST_ERROR,
  PERMISSION_TYPE_PROVIDED_INVALID_ERROR,
  EMAIL_HAS_TOO_MANY_OCCURRENCES_ERROR,
  DOUBLE_PERMISSIONS,
} from "./errors";
import {
  PENDING_CONNECTION_TYPE,
  PARTICIPANT_CONNECTION_TYPE,
} from "../../consts/connectionTypes";
import { getUserBasicDetailesByEmail } from "./userDbApi";

const _ = require("lodash");

export async function createNewBoardApi(boardContent) {
  replaceEmptyStringWithNull(boardContent);
  var res = undefined;
  try {
    res = await API.graphql(
      graphqlOperation(createBoard, { input: boardContent })
    );
    throwErrorIfFoundBoard(res, "createBoard");
  } catch (err) {
    res = err;
  }
  res = formatApiReponse(res, "createBoard");

  return res;
}

export async function createNewBoard(boardContent, userEmail, userId) {
  if (boardContent.id) delete boardContent.id;

  if (boardContent.boardComponents) {
    boardContent.boardComponents = JSON.stringify(boardContent.boardComponents);
  }

  if (!isUserInParticipantList(userEmail, boardContent.ownerIds)) {
    if (!boardContent.ownerIds) {
      boardContent.ownerIds = [];
    }
    boardContent.ownerIds.push(userEmail);
  }
  const res = await createNewBoardApi(boardContent);
  if (res.status === SUCCESS) {
    await API.graphql(
      graphqlOperation(createBoardParticipantConnection, {
        input: {
          status: BOTH_SIDES_APPROVED,
          boardParticipantConnectionUserId: userId,
          userId: userId,
          boardParticipantConnectionBoardId: res.data.id,
          boardId: res.data.id,
          permission: OWNER_PERMISSION,
        },
      })
    );
  }
  return res;
}

function throwErrorIfFoundBoard(res, queryField) {
  if (res.data[queryField] === null) {
    if (res.errors && res.errors[0].errorType) {
      throw res;
    } else {
      throw NO_SUCH_BOARD;
    }
  }
}

export async function getBoardById(boardId) {
  var res = undefined;
  try {
    res = await API.graphql(graphqlOperation(getBoard, { id: boardId }));
    throwErrorIfFoundBoard(res, "getBoard");
  } catch (err) {
    res = err;
  }
  res = formatApiReponse(res, "getBoard");
  return res;
}

export async function updateBoardApi(updatedContent) {
  if (updatedContent.boardComponents) {
    updatedContent.boardComponents = JSON.stringify(
      updatedContent.boardComponents
    );
  }
  replaceEmptyStringWithNull(updatedContent);
  var res = undefined;
  try {
    res = await API.graphql(
      graphqlOperation(updateBoardQuery, { input: updatedContent })
    );
    throwErrorIfFoundBoard(res, "updateBoard");
  } catch (err) {
    res = err;
  }
  res = formatApiReponse(res, "updateBoard");
  return res;
}

export async function updateBoard(updatedContent) {
  const clonedBoard = _.cloneDeep(updatedContent);
  delete clonedBoard.boardParticipants;
  delete clonedBoard.pendingParticipants;
  delete clonedBoard.readOnlyIds;
  delete clonedBoard.editorIds;
  delete clonedBoard.ownerIds;
  const res = await updateBoardApi(clonedBoard);
  return res;
}
//TODO:manipulate response
export function deleteBoardById(whiteBoardId) {
  API.graphql(graphqlOperation(deleteBoard, { input: { id: whiteBoardId } }));
}

var updateSubscription = undefined;
export var currentSubscribedWhiteBoardId = undefined;
export var isSubscriptionActive = false;
/**
 * in callback you must call JSON.parse on the "content" field
 * @param {*} updateDataCB
 */
export function subscribeToWhiteboardUpdates(whiteBoardId, updateDataCB) {
  updateSubscription && unsubscribeToWhiteboardUpdates();
  updateSubscription = API.graphql(
    graphqlOperation(onUpdateBoard, {
      id: whiteBoardId,
    })
  ).subscribe({
    error: (error) => {
      updateSubscription = undefined;
      isSubscriptionActive = false;
    },
    next: (data) => {
      const res = formatApiReponse(data.value, "onUpdateBoard");
      updateDataCB(res);
    },
  });
  currentSubscribedWhiteBoardId = whiteBoardId;
  isSubscriptionActive = true;
}

export function unsubscribeToWhiteboardUpdates() {
  if (updateSubscription === undefined) {
    return;
  }
  updateSubscription.unsubscribe();
  updateSubscription = undefined;
  currentSubscribedWhiteBoardId = undefined;
  isSubscriptionActive = false;
}

export function isUserInParticipantList(userEmail, inputList) {
  return inputList && userEmail && inputList.includes(userEmail);
}

export async function assignUserPermissionToBoard(
  board,
  permission,
  userEmail,
  status
) {
  var idList = getPermissionIdListName(permission);
  var updateJson = {
    id: board.id,
    [idList]: board[idList],
  };
  if (isUserInParticipantList(userEmail, updateJson[idList])) {
    throw Error(USER_ALREADY_IN_LIST_ERROR);
  }
  updateJson[idList].push(userEmail);

  var user = await getUserBasicDetailesByEmail(userEmail);
  if (user.status !== SUCCESS) {
    return user;
  }
  var connectionRes = await createBoardParticipantsConnection(
    status,
    user.data.id,
    board.id,
    permission
  );

  const res = await updateBoardApi(updateJson);
  if (res.status !== SUCCESS) {
    await API.graphql(
      graphqlOperation(deleteBoardParticipantConnection, {
        input: { id: connectionRes.data.createBoardParticipantConnection.id },
      })
    );
  }

  return res;
}
export async function updateParticipantConnectionStatus(
  connectionId,
  newStatus
) {
  var jasonUpdate = {
    id: connectionId,
    status: newStatus,
  };
  return updateConnectionStatus(jasonUpdate, PARTICIPANT_CONNECTION_TYPE);
}
export async function updatePendingConnectionStatus(connectionId, newStatus) {
  var jasonUpdate = {
    id: connectionId,
    status: newStatus,
  };
  return updateConnectionStatus(jasonUpdate, PENDING_CONNECTION_TYPE);
}

//TODO:manipulate response
export async function deletePendingConnection(connectionId) {
  return await API.graphql(
    graphqlOperation(deletePendingParticipant, {
      input: {
        id: connectionId,
      },
    })
  );
}

//TODO:manipulate response
async function updateConnectionStatus(updateJson, connectionType) {
  var mutationString = undefined;
  switch (connectionType) {
    case PARTICIPANT_CONNECTION_TYPE:
      mutationString = updateBoardParticipantConnection;
      break;
    case PENDING_CONNECTION_TYPE:
      mutationString = updatePendingParticipant;
      break;
    default:
      throw Error("the connection specified is not valid");
  }
  return await API.graphql(
    graphqlOperation(mutationString, { input: updateJson })
  );
}

//TODO:manipulate response
export async function createBoardParticipantsConnection(
  status,
  userId,
  boardId,
  permission
) {
  return await API.graphql(
    graphqlOperation(createBoardParticipantConnection, {
      input: {
        status,
        boardParticipantConnectionUserId: userId,
        userId,
        boardParticipantConnectionBoardId: boardId,
        boardId,
        permission,
      },
    })
  );
}

//TODO:manipulate response
export async function createBoardPendingConnection(status, userId, boardId) {
  return await API.graphql(
    graphqlOperation(createPendingParticipant, {
      input: {
        status,
        pendingParticipantUserId: userId,
        userId,
        pendingParticipantBoardId: boardId,
        boardId,
      },
    })
  );
}

export async function changeParticipantBoardPermissions(
  board,
  userEmail,
  oldPermissionType,
  connectionId,
  newPermissionType
) {
  const oldIdListName = getPermissionIdListName(oldPermissionType);
  const newIdListName = getPermissionIdListName(newPermissionType);
  const isUserInOldIdList = isUserInParticipantList(
    userEmail,
    board[oldIdListName]
  );
  const isUserInNewIdList = isUserInParticipantList(
    userEmail,
    board[newIdListName]
  );
  if (isUserInOldIdList && isUserInNewIdList) {
    if (oldIdListName === newIdListName) {
      return;
    }
    throw Error(DOUBLE_PERMISSIONS);
  } else if (
    (!isUserInOldIdList && isUserInNewIdList) ||
    (!isUserInOldIdList && !isUserInNewIdList)
  ) {
    return;
  } else {
    if (oldIdListName === "ownerIds" && board[oldIdListName].length === 1) {
      return;
    }
    board[oldIdListName] = removeAllOccurrencesInList(
      board[oldIdListName],
      userEmail
    );
    board[newIdListName].push(userEmail);
    const updatedBoardDataJson = {
      id: board.id,
      [oldIdListName]: board[oldIdListName],
      [newIdListName]: board[newIdListName],
    };
    if (connectionId !== undefined) {
      await API.graphql(
        graphqlOperation(updateBoardParticipantConnection, {
          input: {
            id: connectionId,
            permission: newPermissionType,
          },
        })
      );
    }
    return updateBoardApi(updatedBoardDataJson);
  }
}

function removeAllOccurrencesInList(list, item) {
  return list.filter((value) => value !== item);
}

function getPermissionIdListName(permission) {
  var idList = undefined;
  switch (permission) {
    case READ_ONLY_PERMISSION:
      idList = "readOnlyIds";
      break;
    case EDITOR_PERMISSION:
      idList = "editorIds";
      break;
    case OWNER_PERMISSION:
      idList = "ownerIds";
      break;
    default:
      throw Error(PERMISSION_TYPE_PROVIDED_INVALID_ERROR);
  }
  return idList;
}

export async function removeUserPermissionFromBoard(
  board,
  userEmail,
  connectionId
) {
  var updatedJson = {
    id: board.id,
    readOnlyIds: _.without(board.readOnlyIds, userEmail),
    editorIds: _.without(board.editorIds, userEmail),
    ownerIds: _.without(board.ownerIds, userEmail),
  };

  await updateParticipantConnectionStatus(connectionId, USER_REMOVED_BY_OWNER);

  return await updateBoardApi(updatedJson);
}
