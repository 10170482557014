import React from "react";
import { DRAWER_WIDTH } from "../consts/layout";
import { COLOR_VERY_DARK_BLUE, COLOR_ASBESTOS } from "../consts/colors";

import {
  DRAWER_ZINDEX_BOARDS,
  DRAWER_ZINDEX_COLLABORATE
} from "../consts/layout";
import { useTransition, animated } from "react-spring";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";

export const DrawerWrapper = ({
  isOpen,
  children,
  choosenTabIndex,
  hideBackground,
  onClose
}) => {
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  const transitions = useTransition(isOpen, null, {
    from: { transform: `translate3d(${DRAWER_WIDTH}px,0,0)` },
    enter: { transform: "translate3d(0px,0,0)" },
    leave: { transform: `translate3d(${DRAWER_WIDTH}px,0,0)` }
  });

  return (
    <>
      {isOpen && hideBackground && (
        <div
          onClick={onClose}
          style={{
            backgroundColor: hideBackground ? COLOR_ASBESTOS + "aa" : undefined,
            width: windowWidth,
            height: windowHeight,
            position: "fixed",
            top: 0,
            left: 0
          }}
        />
      )}
      <div
        style={{
          position: "fixed",
          height: "100%",
          top: 0,
          right: 0,
          zIndex: DRAWER_ZINDEX_COLLABORATE
        }}
      >
        {transitions.map(({ item, key, props }) => {
          if (item === false) {
            return null;
          }
          return (
            <animated.div key={key} style={props}>
              <div
                onMouseDown={e => {
                  e.stopPropagation();
                }}
                style={{
                  width: DRAWER_WIDTH,
                  height: 3000,
                  borderLeft: `2px solid ${COLOR_VERY_DARK_BLUE + "99"}`,
                  background: "white",
                  zIndex: DRAWER_ZINDEX_COLLABORATE,
                    // // Tabs["Collaborate"] === choosenTabIndex
                    //   ? DRAWER_ZINDEX_COLLABORATE
                    //   : DRAWER_ZINDEX_BOARDS,
                  padding: 0,
                  cursor: "default"
                }}
              >
                {isOpen && children}
              </div>
            </animated.div>
          );
        })}
      </div>
    </>
  );
};
