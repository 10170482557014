import React from "react";
import { AproveButton } from "./AproveButton";
import { DeclineButton } from "./DeclineButton";

export const AproveOrNotButtons = ({onApprove, onDecline, fontSize}) => {
  return (
    <div style={{ display: "flex" }}>
      <DeclineButton onClick={onDecline} fontSize={fontSize}/>
      <div style={{ width: 14 }} />
      <AproveButton onClick={onApprove} fontSize={fontSize}/>
    </div>
  );
};
