import React from "react";
import { useSpring, animated } from "react-spring";
import { FLOATING_MENU_ZINDEX } from "../../consts/layout";

export const SELECT_MENU_WIDTH = 200;

const BOX_STYLE = {
  width: SELECT_MENU_WIDTH,
  borderRadius: 8,
  //   border: '0.5px solid #333',
  background: "white",
  boxShadow: "0 4px 17px 6px rgba(0, 0, 0, 0.1)",
  padding: 8
};

const FloatingMenu = React.forwardRef(
  ({ locationParamenters, onClick, children }, ref) => {
    const [props] = useSpring(() => ({
      left: locationParamenters.left,
      top: locationParamenters.toTop,
      opacity: 1,
      from: { opacity: 0, top: locationParamenters.fromTop }
    }));

    return (
      <div
        ref={ref}
        onMouseMove={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()}
        style={{ position: "relative", cursor: "default", overflow:'visible', zIndex: 800 }}
      >
        <animated.div style={{ position: "absolute", ...props }}>
          <div style={BOX_STYLE} onClick={onClick}>
            {children}
          </div>
        </animated.div>
      </div>
    );
  }
);

export default FloatingMenu;
