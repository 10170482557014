import React from "react";
import Seperator from "../Seperator";
import GimobyModal from "./GimobyModal";
import LockIcon from "@material-ui/icons/Lock";
import { Button } from "@material-ui/core";
import { COLOR_POMEGRANATE, COLOR_ALIZRIN } from "../../consts/colors";
import { useHistory } from "react-router-dom";
import { updateOpenedModal } from "../../redux/modules/modals";
import { useDispatch } from "react-redux";
import { BoldModalText } from "./BoldModalText";

//1B998B
const SigninFailedModal = ({ isOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const text = "Sign in failed, maybe try again later";

  const onClose = () => {
    history.push("/");
    updateOpenedModal(undefined);
  };

  return (
    <GimobyModal
      isOpen={isOpen}
      backgroundColor={COLOR_ALIZRIN}
      onClose={onClose}
    >
      <Seperator height={25} />
      <LockIcon
        style={{ colorPrimary: "white", fontSize: 80, color: "white" }}
      />
      <Seperator height={10} />
      <BoldModalText>{text}</BoldModalText>

      <Seperator height={25} />
      <Button
        onClick={onClose}
        variant="contained"
        style={{ background: "white" }}
      >
        <div style={{ fontWeight: "600", color: COLOR_ALIZRIN }}>Close</div>
      </Button>
    </GimobyModal>
  );
};

export default SigninFailedModal;
