import React from "react";
import { COLOR_PETER_RIVER } from "../../../../consts/colors";
import SmallIconButton from "../../../../components/Buttons/SmallIconButton";
// import MinusIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";

const EditButton = ({ onClick, color }) => {
  return (
    <SmallIconButton
      onClick={onClick}
      hoveredColor={COLOR_PETER_RIVER}
      color={color}
    >
      <EditIcon
        style={{ fontSize: 17}}
      />
    </SmallIconButton>
  );
};

export default EditButton;
