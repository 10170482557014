import { v4 as uuidv4 } from "uuid";
import {
  FREE_TEXT_CARD_TYPE,
  TITLE_TYPE
} from "../../consts/boardComponentsTypes";
import { COLOR_LOGO_BLUE, COLOR_DARK_BLUE } from "../../consts/colors";
import { createBoardComponent, emptyBoard } from "../../redux/modules/board";
import { dateString } from "../../javascript/dateString";

const _ = require("lodash");

export const DAILY_TEMPLATE = "DAILY_TEMPLATE";

const createDailyBoard = () => {
  var dailyBoard = _.cloneDeep(emptyBoard);
  dailyBoard.id = uuidv4();

  const titleComponent = createBoardComponent({ x: 50, y: 50 }, TITLE_TYPE);
  titleComponent.text = "Daily Meeting - " + dateString();

  const yanivMessions = createBoardComponent(
    { x: 50, y: 140 },
    FREE_TEXT_CARD_TYPE
  );
  yanivMessions.title = "Yaniv's Missions";
  yanivMessions.color = COLOR_LOGO_BLUE;

  const deanMessions = createBoardComponent(
    { x: 530, y: 140 },
    FREE_TEXT_CARD_TYPE
  );
  deanMessions.title = "Dean's Missions";
  deanMessions.color = COLOR_DARK_BLUE;

  dailyBoard.boardComponents = {
    [titleComponent.id]: titleComponent,
    [yanivMessions.id]: yanivMessions,
    [deanMessions.id]: deanMessions
  };

  return dailyBoard;
};

export default createDailyBoard;
