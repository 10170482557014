import React, { useState, useRef } from "react";
import {useSelector} from 'react-redux'
import { selectBoardName } from "../../../../redux/modules/boardsDict";

const EditDirectoryItemInput = React.forwardRef(
  ({ height, width, item, onEnter, placeholder, onChange }, ref) => {
    const name = useSelector(state => selectBoardName(state, item.id))
    const [value, setValue] = useState(name? name : item.name);

    const onLocalChange = e => {
      setValue(e.target.value);
      onChange && onChange(e.target.value);
    };

    const onKeyDown = e => {
      if (e.key === "Enter") {
        onEnter(value);
      }
    };

    // useEffect(() => ref.current.focus(), []);

    return (
      <input
        ref={ref}
        type="text"
        style={{
          borderRadius: 8,
          border: "0.5px dashed #1C6EA4",
          height,
          width,
          paddingLeft: 10,
          fontSize: 16,
          outline: "none",
          fontWeight: "600",
          color: "#333"
        }}
        onMouseDown={e => e.stopPropagation()}
        placeholder={placeholder}
        value={value}
        onChange={onLocalChange}
        onKeyDown={onKeyDown}
      ></input>
    );
  }
);

export default EditDirectoryItemInput;
