import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import Seperator from "../../../components/Seperator";
import { COLOR_LOGO_BLUE } from "../../../consts/colors";
import Text from "../../../components/Text";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { selectActiveBoardId } from "../../../redux/modules/activeBoard";

const ShareBoardCodeSection = () => {
  const [copyText, setCopyText] = useState("Copy to clipboard");

  const boardId = useSelector(selectActiveBoardId);

  return (
    <>
      <h3>Copy Code To Share Board</h3>
      <TextField
        variant="outlined"
        fullWidth
        defaultValue={boardId}
        inputProps={{
          readOnly: true
        }}
      />
      <Seperator height={20} />

      <CopyToClipboard text={boardId} onCopy={() => setCopyText("Copied ✓")}>
        <Button
          fullWidth
          color="primary"
          style={{ backgroundColor: COLOR_LOGO_BLUE }}
        >
          <Text text={copyText} style={{ color: "white" }} />
        </Button>
      </CopyToClipboard>
    </>
  );
};

export default ShareBoardCodeSection;
