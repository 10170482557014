import { store } from "../store";
import { UPDATE_ORIGIN } from "../reducers";
import { v4 as uuidv4 } from "uuid";
import { updateUserVersion } from "../updateVersion";
import { updateBoardsList, selectBoardName } from "./boardsDict";
import {
  WAITING_OWNER_APPROVAL,
  WAITING_USER_APPROVAL
} from "../../consts/connectionsStatuses";

const _ = require("lodash");
export const STATE_KEY = "user";

export const DIRECTORY = "DIRECTORY";
export const BOARD = "BOARD";

const newDirectory = directoryName => ({
  type: DIRECTORY,
  isOpen: true,
  name: directoryName ? directoryName : "New directory",
  id: uuidv4(),
  version: 0,
  children: []
});

const createRootDirectory = () => {
  return {
    type: DIRECTORY_TYPE,
    name: "root", // Don't change, the flatten algorithem assumes this name
    version: 0,
    isOpen: true,
    id: uuidv4(),
    children: []
  };
};

const newBoardDirectoryEntry = boardId => ({
  type: BOARD,
  id: boardId
});

export var items = {
  type: DIRECTORY,
  isOpen: true,
  name: "root",
  id: "root",
  children: [
    // {
    //   type: BOARD,
    //   id: "aaaa",
    //   name: "Board1"
    // }
  ]
};

const ACTION_UPDATE_USER = "ACTION_UPDATE_USER";
// const ACTION_UPDATE_BOARDS_LIST = "ACTION_UPDATE_BOARDS_LIST";
const ACTION_UPDATE_IS_OPEN = "ACTION_UPDATE_IS_OPEN";
const ACTION_UPDATE_ITEM_LOCATION = "ACTION_UPDATE_ITEM_LOCATION";
const ACTION_ADD_DIRECTORY_AFTER_ID = "ACTION_ADD_DIRECTORY_AFTER_ID";
const ACTION_ADD_DIRECTORY_AT_LAST_PLACE = "ACTION_ADD_DIRECTORY_AT_LAST_PLACE";
const ACTION_DELETE_DIRECTORY_ITEM = "ACTION_DELETE_DIRECTORY_ITEM";
const ACTION_ADD_BOARD_AFTER_ID = "ACTION_ADD_BOARD_AFTER_ID";
const ACTION_UPDATE_DIRECTORY_NAME = "ACTION_UPDATE_DIRECTORY_NAME";

const DIRECTORY_TYPE = "DIRECTORY_TYPE";
const BOARD_TYPE = "BOARD_TYPE";

const initialState = {
  id: undefined,
  // boardsDirectory: _.cloneDeep(emptyDirectory)
  boardsDirectory: items
};

export const selectActiveUser = state => state[STATE_KEY];
export const selectActiveUserEmail = state => state[STATE_KEY].email;
export const selectActiveUserId = state => state[STATE_KEY].id;
export const selectBoardsIdsList = state => state[STATE_KEY].boardsIdsList;
export const selectBoardsDirectory = state => state[STATE_KEY].boardsDirectory;
export const selectUserVersion = state => state[STATE_KEY].version;
export const isUserSignin = state => state[STATE_KEY].id !== undefined;

const extractAllBoardsConnections = (user, connectionStatus) =>
  user.participatingBoards.items === ""
    ? []
    : user.participatingBoards.items
        .filter(
          connection =>
            connection.board !== undefined &&
            (!connectionStatus ||
              connection.connectionStatus === connectionStatus)
        )
        .map(connection => connection.board);

export const selectBoardInventaionsOfActiveUser = () => {
  const activeUser = store.getState()[STATE_KEY];
  if (activeUser.id === undefined) {
    return [];
  }

  const connections = activeUser.participatingBoards.items.filter(
    connection => connection.status === WAITING_USER_APPROVAL
  );
  return connections;
};

export const updateUser = user => {
  const origin = UPDATE_ORIGIN.REMOTE_CHANGE;

  const userBoards = extractAllBoardsConnections(user);
  updateBoardsList(userBoards, origin);

  store.dispatch({
    type: ACTION_UPDATE_USER,
    payload: { user, origin }
  });
};

export const updateIsOpenDirectoryById = (id, isOpen) => {
  store.dispatch({
    type: ACTION_UPDATE_IS_OPEN,
    payload: { id, isOpen, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

export const updateDirectoryName = (id, newName) => {
  store.dispatch({
    type: ACTION_UPDATE_DIRECTORY_NAME,
    payload: { id, newName, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

export const updateItemLocationAfter = (itemToMove, addAfterId) => {
  store.dispatch({
    type: ACTION_UPDATE_ITEM_LOCATION,
    payload: { itemToMove, addAfterId, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

export const addNewDirectoryAfterId = (addAfterId, directoryName) => {
  store.dispatch({
    type: ACTION_ADD_DIRECTORY_AFTER_ID,
    payload: { addAfterId, directoryName, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

export const addNewBoardAfterId = (addAfterId, boardId) => {
  store.dispatch({
    type: ACTION_ADD_BOARD_AFTER_ID,
    payload: { addAfterId, boardId, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

export const addNewDirectoryAtLastPlace = directoryName => {
  store.dispatch({
    type: ACTION_ADD_DIRECTORY_AT_LAST_PLACE,
    payload: { directoryName, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

export const deleteDirectoryItem = id => {
  store.dispatch({
    type: ACTION_DELETE_DIRECTORY_ITEM,
    payload: { id, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

const findItemById = (dir, id) => {
  for (let i = 0; i < dir.children.length; ++i) {
    if (dir.children[i].id === id) {
      return dir.children[i];
    }
    if (dir.children[i].type === DIRECTORY) {
      const searchResult = findItemById(dir.children[i], id);
      if (searchResult !== undefined) {
        return searchResult;
      }
    }
  }

  return undefined;
};

const insertAtIndex = (arr, newItem, index) => [
  ...arr.slice(0, index),

  newItem,

  ...arr.slice(index)
];

const removeItem = (arr, index) =>
  arr.filter((_, i) => {
    return i !== index;
  });

const addItemAfterId = (dir, itemToMove, addAfterId) => {
  if (addAfterId === undefined) {
    dir.children = insertAtIndex(dir.children, itemToMove, 0);
    return true;
  }
  for (let i = 0; i < dir.children.length; ++i) {
    if (dir.children[i].id === addAfterId) {
      if (dir.children[i].type === BOARD) {
        dir.children = insertAtIndex(dir.children, itemToMove, i + 1);
      }

      if (dir.children[i].type === DIRECTORY) {
        if (dir.children[i].isOpen) {
          dir.children[i].children = insertAtIndex(
            dir.children[i].children,
            itemToMove,
            0
          );
        } else {
          dir.children = insertAtIndex(dir.children, itemToMove, i + 1);
        }
      }
      return true;
    } else {
      if (dir.children[i].type === DIRECTORY && dir.children[i].isOpen) {
        const found = addItemAfterId(dir.children[i], itemToMove, addAfterId);
        if (found) {
          return true;
        }
      }
    }
  }
  return false;
};

const removeItemFromDirectory = (dir, id) => {
  for (let i = 0; i < dir.children.length; ++i) {
    if (dir.children[i].id === id) {
      dir.children = removeItem(dir.children, i);
      return true;
    }
    if (dir.children[i].type === DIRECTORY && dir.children[i].isOpen) {
      const found = removeItemFromDirectory(dir.children[i], id);
      if (found) {
        return true;
      }
    }
  }
  return false;
};

const addItemAtLastPlace = (directoryClone, newItem) => {
  directoryClone.children = [...directoryClone.children, newItem];
};

const selectIsNameExistsInDirectoryAux = (dir, name, state) => {
  for (let i = 0; i < dir.children.length; ++i) {
    if (dir.children[i].type === BOARD) {
      const boardName = selectBoardName(state, dir.children[i].id);
      if (boardName === name) {
        return true;
      }
    }
    if (dir.children[i].type === DIRECTORY) {
      const found = selectIsNameExistsInDirectoryAux(
        dir.children[i],
        name,
        state
      );
      if (found) {
        return true;
      }
    }
  }
  return false;
};
export const selectIsNameExistsInDirectory = (state, name) => {
  const dir = state[STATE_KEY].boardsDirectory;
  return selectIsNameExistsInDirectoryAux(dir, name, state);
};

const reducer = (state = initialState, action) => {
  var newUser = undefined;
  switch (action.type) {
    case ACTION_UPDATE_USER:
      if (state.version > action.payload.user.version) {
        return state;
      }

      if (
        action.payload.user.boardsDirectory === null ||
        action.payload.user.boardsDirectory === undefined ||
        action.payload.user.boardsDirectory === ""
      ) {
        // Happens on user first signin

        newUser = {
          ...action.payload.user,
          boardsDirectory: createRootDirectory()
        };
        return updateUserVersion(newUser);
      }

      return action.payload.user;
    case ACTION_UPDATE_IS_OPEN:
      var directory = _.cloneDeep(state.boardsDirectory);
      var objectToUpdate = findItemById(directory, action.payload.id);
      if (objectToUpdate === undefined) {
        return state;
      }
      objectToUpdate.isOpen = action.payload.isOpen;
      newUser = {
        ...state,
        boardsDirectory: directory
      };
      return updateUserVersion(newUser);

    case ACTION_UPDATE_DIRECTORY_NAME:
      var directory = _.cloneDeep(state.boardsDirectory);
      var objectToUpdate = findItemById(directory, action.payload.id);
      if (objectToUpdate === undefined) {
        return state;
      }
      objectToUpdate.name = action.payload.newName;
      newUser = {
        ...state,
        boardsDirectory: directory
      };
      return updateUserVersion(newUser);

    case ACTION_UPDATE_ITEM_LOCATION:
      var directoryClone = _.cloneDeep(state.boardsDirectory);
      removeItemFromDirectory(directoryClone, action.payload.itemToMove.id);

      addItemAfterId(
        directoryClone,
        action.payload.itemToMove,
        action.payload.addAfterId
      );
      newUser = {
        ...state,
        boardsDirectory: {
          ...directoryClone
        }
      };
      return updateUserVersion(newUser);
    case ACTION_ADD_DIRECTORY_AFTER_ID:
      var directoryClone = _.cloneDeep(state.boardsDirectory);
      var newItem = newDirectory(action.payload.directoryName);
      addItemAfterId(directoryClone, newItem, action.payload.addAfterId);
      newUser = {
        ...state,
        boardsDirectory: {
          ...directoryClone
        }
      };
      return updateUserVersion(newUser);

    case ACTION_ADD_BOARD_AFTER_ID:
      var directoryClone = _.cloneDeep(state.boardsDirectory);
      var newItem = newBoardDirectoryEntry(action.payload.boardId);
      addItemAfterId(directoryClone, newItem, action.payload.addAfterId);
      newUser = {
        ...state,
        boardsDirectory: {
          ...directoryClone
        }
      };
      return updateUserVersion(newUser);

    case ACTION_ADD_DIRECTORY_AT_LAST_PLACE:
      var directoryClone = _.cloneDeep(state.boardsDirectory);
      var newItem = newDirectory(action.payload.directoryName);
      addItemAtLastPlace(directoryClone, newItem);
      newUser = {
        ...state,
        boardsDirectory: {
          ...directoryClone
        }
      };
      return updateUserVersion(newUser);

    case ACTION_DELETE_DIRECTORY_ITEM:
      var directoryClone = _.cloneDeep(state.boardsDirectory);
      removeItemFromDirectory(directoryClone, action.payload.id);
      newUser = {
        ...state,
        boardsDirectory: {
          ...directoryClone
        }
      };
      return updateUserVersion(newUser);
    default:
      return state;
  }
};

export default reducer;
