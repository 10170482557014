import React from "react";
import z from "../../javascript/z";

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return (
        <ul {...attributes} style={{ paddingLeft: z(42), lineHeight: 1.5 }}>
          {children}
        </ul>
      );
    case "heading-one":
      return <h4 {...attributes}>{children}</h4>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      return (
        <li {...attributes} style={{ paddingLeft: z(0), lineHeight: 1.5 }}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol {...attributes} style={{ paddingLeft: z(40), lineHeight: 1.5 }}>
          {children}
        </ol>
      );
    case "link":
      return (
        <a
          {...attributes}
          href={element.url}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(element.url, "_blank");
          }}
        >
          {children}
        </a>
      );
    default:
      return (
        <p {...attributes} style={{ lineHeight: 1.65 }}>
          {children}
        </p>
      );
  }
};

export default Element;
