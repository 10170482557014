import { updateOpenedModal, MODALS } from "../../redux/modules/modals";
import {
  getBoardById,
  createBoardParticipantsConnection,
  createBoardPendingConnection,
  updateParticipantConnectionStatus,
  deletePendingConnection,
} from "../../backend/api/boardDbApi";
import { store } from "../../redux/store";
import {
  updateBoard,
  selectPermissionOfUserByEmail,
  selectConnectionByUserId,
  selectConnectionByBoardId,
} from "../../redux/modules/boardsDict";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import { updateActiveBoardId } from "../../redux/modules/activeBoard";
import { selectActiveUser, addNewBoardAfterId } from "../../redux/modules/user";
import {
  WAITING_USER_APPROVAL,
  BOTH_SIDES_APPROVED,
  OWNER_DECLINED,
  WAITING_OWNER_APPROVAL,
} from "../../consts/connectionsStatuses";
import { USER_NOT_AUTHORIZED, NO_SUCH_BOARD } from "../../backend/api/utils";
import ReactGA from "react-ga";
var mixpanel = require("mixpanel-browser");

const handlePendingConnection = async (user, boardCode) => {
  const pendingConnection = selectConnectionByBoardId(
    user.pendingBoards,
    boardCode
  );

  if (pendingConnection === undefined) {
    await createBoardPendingConnection(
      WAITING_OWNER_APPROVAL,
      user.id,
      boardCode
    );
    updateOpenedModal(MODALS.REQUESTED_TO_JOIN_BOARD, { boardId: boardCode });
    return;
  }

  switch (pendingConnection.status) {
    case WAITING_OWNER_APPROVAL:
      updateOpenedModal(MODALS.REQUESTED_TO_JOIN_BOARD, { boardId: boardCode });
      break;
    default:
      updateOpenedModal(MODALS.OWNER_DECLINED_JOIN_REQUEST);
      break;
  }
};

const joinABoard = async (boardCode) => {
  const state = store.getState();

  mixpanel.track("Started to join the board");
  updateOpenedModal(MODALS.JOINING_BOARD_MODAL);

  const res = await getBoardById(boardCode);

  const activeUser = selectActiveUser(state);

  if (res.status === NO_SUCH_BOARD) {
    mixpanel.track("No such board");
    ReactGA.event({
      category: "Generic Catagory",
      action: "No such board",
    });
    updateOpenedModal(MODALS.COULD_NOT_FIND_BOARD_MODAL);
    return undefined;
  }

  if (res.status === USER_NOT_AUTHORIZED) {
    mixpanel.track("Not authorized");
    ReactGA.event({
      category: "Generic Catagory",
      action: "Not authorized",
    });
    await handlePendingConnection(activeUser, boardCode);
    return undefined;
  }

  //Authorized
  const board = res.data;

  const connection = selectConnectionByUserId(
    board.boardParticipants,
    activeUser.id
  );

  if (connection === undefined) {
    //User wasn't signed in but was added to this board
    const permission = selectPermissionOfUserByEmail(board, activeUser.email);

    if (permission === undefined) {
      mixpanel.track("Permission error");
      ReactGA.event({
        category: "Generic Catagory",
        action: "Permission error",
      });
      throw Error(
        "If authorized, user must be in at least one of the permissions lists"
      );
    }

    await createBoardParticipantsConnection(
      BOTH_SIDES_APPROVED,
      activeUser.id,
      board.id,
      permission
    );
  } else {
    // User was sign in before the board owner add him and now he accepts the invitation
    if (connection.status === WAITING_USER_APPROVAL) {
      ReactGA.event({
        category: "Generic Catagory",
        action: "Waiting user approval",
      });
      mixpanel.track("Waiting user approval");
      await updateParticipantConnectionStatus(
        connection.id,
        BOTH_SIDES_APPROVED
      );
      addNewBoardAfterId(undefined, board.id);
    }

    // User was approved by board owner, but he left a pending connection so
    // the waiting room will be able to check the status of the connection
    if (connection.status === BOTH_SIDES_APPROVED) {
      ReactGA.event({
        category: "Generic Catagory",
        action: "Both sides approved",
      });
      mixpanel.track("Both sides approved");
      const approvedPendingConnection = selectConnectionByBoardId(
        activeUser.pendingBoards,
        board.id
      );
      if (approvedPendingConnection !== undefined) {
        addNewBoardAfterId(undefined, board.id);
        await deletePendingConnection(approvedPendingConnection.id);
      }
    }
  }

  updateBoard(UPDATE_ORIGIN.REMOTE_CHANGE, board, board.id);

  updateActiveBoardId(boardCode);
  updateOpenedModal(undefined);
  mixpanel.track("Joined board");
};

export default joinABoard;
