import React from "react";
import { COLOR_PETER_RIVER } from "../../../../consts/colors";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DIRECTORY, BOARD } from "../../../../redux/modules/user";
import SmallIconButton from "../../../../components/Buttons/SmallIconButton";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const DirectoryButton = ({ type, isOpen, onClick, color }) => {

  if(type === BOARD) {
    return <FiberManualRecordIcon style={{fontSize:9, paddingBottom:4}}/>
  }
  return (
    <SmallIconButton
      onClick={onClick}
      hoveredColor={COLOR_PETER_RIVER}
      color={color}
    >
      <div>
        {type === DIRECTORY && isOpen === false && <ChevronRightIcon style={{fontSize:20}} />}
        {type === DIRECTORY && isOpen === true && <ExpandMoreIcon style={{fontSize:20}}/>}
      </div>
    </SmallIconButton>
  );
};

export default DirectoryButton;
