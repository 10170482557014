import React from "react";
import Seperator from "../Seperator";
import GimobyModal from "./GimobyModal";
import { Button } from "@material-ui/core";
import { COLOR_ALIZRIN } from "../../consts/colors";
import { useHistory } from "react-router-dom";
import { updateOpenedModal } from "../../redux/modules/modals";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
  BOARD,
  deleteDirectoryItem,
  selectActiveUser,
} from "../../redux/modules/user";
import { BoldModalText } from "./BoldModalText";
import { updateParticipantConnectionStatus } from "../../backend/api/boardDbApi";
import { USER_REMOVED_BY_OWNER } from "../../consts/connectionsStatuses";
import { selectConnectionByBoardId } from "../../redux/modules/boardsDict";
import { store } from "../../redux/store";

const DeleteDirectoryItemModal = ({ isOpen, extraData }) => {
  const text = `Delete ${
    extraData.itemToDelete.type === BOARD ? "board" : "directory"
  }? it can't be restored`;

  const onClose = () => {
    updateOpenedModal(undefined);
  };

  const onDelete = () => {
    const state = store.getState();
    const user = selectActiveUser(state);
    const connection = selectConnectionByBoardId(
      user.participatingBoards,
      extraData.itemToDelete.id
    );
    updateParticipantConnectionStatus(connection.id, USER_REMOVED_BY_OWNER);
    deleteDirectoryItem(extraData.itemToDelete.id);
    onClose();
  };

  return (
    <GimobyModal
      isOpen={isOpen}
      backgroundColor={COLOR_ALIZRIN}
      onClose={onClose}
    >
      <Seperator height={10} />
      <DeleteOutlineIcon
        style={{ colorPrimary: "white", fontSize: 80, color: "white" }}
      />
      <Seperator height={15} />
      <BoldModalText>
        <div style={{ textAlign: "center" }}>
          {`Delete ${
            extraData.itemToDelete.type === BOARD ? "board" : "directory"
          }?`}
          <br />
          it can't be restored
        </div>
      </BoldModalText>
      <Seperator height={30} />
      <div style={{ display: "flex" }}>
        <Button
          onClick={onDelete}
          // variant="contained"
          style={{ backgroundColor: COLOR_ALIZRIN }}
        >
          <div style={{ fontWeight: "600", color: "white" }}>Delete</div>
        </Button>
        <div style={{ width: 80 }} />
        <Button
          onClick={onClose}
          variant="contained"
          style={{ background: "white" }}
        >
          <div
            style={{ fontWeight: "400", opacity: 0.8, color: COLOR_ALIZRIN }}
          >
            Close
          </div>
        </Button>
      </div>
    </GimobyModal>
  );
};

export default DeleteDirectoryItemModal;
