import React from "react";
import { useSelector } from "react-redux";
import MenuIcon from "@material-ui/icons/Menu";
import { Button } from "@material-ui/core";
import { BORDER_PADDING } from "../../consts/layout";
import { selectBoardInventaionsOfActiveUser } from "../../redux/modules/user";
import { Badge } from "../../components/Badge";

const style = {
  position: "fixed",
  padding: 10,
  right: BORDER_PADDING,
  top: BORDER_PADDING,
  cursor: "pointer"
};

const DrawerButton = ({ onClick }) => {
  const pendingBoards = useSelector(selectBoardInventaionsOfActiveUser);
  
  return (
    <Button onClick={onClick} style={style}>
      {pendingBoards.length > 0 && <Badge number={pendingBoards.length} />}
      <MenuIcon style={{ fontSize: 35 }} />
    </Button>
  );
};

export default DrawerButton;
