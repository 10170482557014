import React, { useState } from "react";
import { COLOR_DARK_BLUE } from "../../consts/colors";

const BUTTON_STYLE = {
  display: "flex",
  padding: 8,
  alignContent: "left",
  borderRadius: 4,
  border: "none",
  width: "100%",
  color: "#333333",
  fontWeight: 400,
  fontSize: 14,
  letterSpacing: 0,
  whiteSpace: "nowrap",
  textDecoration: "none",
  outline: "none",
  cursor: "pointer"
};


const MenuButton = ({ text, onClick, color }) => {
  const [isHovered, setIsHovered] = useState(false);
  const definedColor = color ? color : COLOR_DARK_BLUE;
  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        ...BUTTON_STYLE,
        opacity: isHovered ? 0.7 : undefined,
        backgroundColor: isHovered ? definedColor : "white"
      }}
      onClick={onClick}
    >
      <div style={{ color: isHovered ? "white" : undefined }}>{text}</div>
    </button>
  );
};

export default MenuButton;
