import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  updateBoardComponent,
  selectActiveBoardComponentById
} from "../../redux/modules/boardsDict";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import ArrowDropDownButton from "../card/ArrowDropDownButton";
import { nullIfNeeded } from "../../javascript/nullIfNeeded";
import z from "../../javascript/z";
import Resizable from "./Resizable";
import {
  cardBorderTop,
  CARD_STYLE,
  TITLE_STYLE,
  INNER_PADDING,
  RESIZABLE_PADDING
} from "../../components/Card";
import { animated, useSpring } from "react-spring";

const _ = require("lodash");

export const updateTitle = (dispatch, component, newTitle) => {
  var clonedComponent = _.cloneDeep(component);
  clonedComponent.title = nullIfNeeded(newTitle);
  updateBoardComponent(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, clonedComponent);
};

export const updateText = (dispatch, component, newText) => {
  var clonedComponent = _.cloneDeep(component);
  clonedComponent.text = nullIfNeeded(newText);
  updateBoardComponent(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, clonedComponent);
};

const selectorEquality = (left, right) => {
  return left.version === right.version;
};

const CardComponent = ({ id, children }) => {
  const boardComponent = useSelector(
    state => selectActiveBoardComponentById(state, id),
    selectorEquality
  );
  const dispatch = useDispatch();

  const animatedParams = {
    outerWidth: z(boardComponent.size.width)
  };

  const [props, set] = useSpring(() => animatedParams);

  set(animatedParams);

  const borderTop = cardBorderTop(boardComponent.color);
  const resizablePadding = z(RESIZABLE_PADDING);
  const innerPadding = z(INNER_PADDING);

  return (
    <animated.div
      style={{
        ...CARD_STYLE,
        borderTop,
        width: props.outerWidth,
        fontSize: z(24),
        boxShadow: "0px 0px 6px 2px rgba(70,70,70,0.02)"
      }}
    >
      <Resizable
        childrenWidth={z(boardComponent.size.width)}
        childrenHeight={"100%"}
        resizablePadding={resizablePadding}
        componentId={id}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: innerPadding
          }}
        >
          <div
            style={{
              display: "flex"
            }}
          >
            <input
              type="text"
              onChange={e =>
                updateTitle(dispatch, boardComponent, e.target.value)
              }
              style={{
                ...TITLE_STYLE,
                // width: props.outerWidth - 20,
                color: boardComponent.color,
                fontSize: "1.1em",
              }}
              placeholder="Add a title"
              value={boardComponent.title || ""}
              onMouseDown={e => e.stopPropagation()}
            />
            <div
              style={{
                position: "absolute",
                right: innerPadding / 2 + resizablePadding,
                top: innerPadding / 2 + resizablePadding * 1.7
              }}
            >
              <ArrowDropDownButton
                hoverTintColor={boardComponent.color}
                id={id}
                fontSize="1.1em"
              />
            </div>
          </div>

          {children}
        </div>
      </Resizable>
    </animated.div>
  );
};

export default CardComponent;
