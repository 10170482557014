import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHover } from "react-hooks-lib";
import { COLOR_DARK_BLUE } from "../../../../consts/colors";
import { TAB_INNER_PADDING, DRAWER_WIDTH } from "../../../../consts/layout";
import {
  updateIsOpenDirectoryById,
  updateDirectoryName,
  BOARD,
  DIRECTORY
} from "../../../../redux/modules/user";
import {
  selectBoardName,
  updateBoard,
  updateBoardName
} from "../../../../redux/modules/boardsDict";
import { useSpring, animated } from "react-spring";
import DirectoryButton from "./DirectoryButton";
import EditDirectoryItemInput from "./NewDirectoryButton";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import DeleteButton from "./DeleteButton";
import { updateOpenedModal, MODALS } from "../../../../redux/modules/modals";
import EditButton from "./EditButton";
import MoreButton from "./MoreButton";

const BOARD_CARD_WIDTH = DRAWER_WIDTH - TAB_INNER_PADDING;
const BOARD_CARD_HEIGHT = 35;

const ITEM_WRAPPER_STYLE = {
  display: "flex",
  alignItems: "center",
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  width: BOARD_CARD_WIDTH,
  height: BOARD_CARD_HEIGHT,
  paddingTop: 5,
  paddingBottom: 5,
  paddingRight: 10,
  cursor: "pointer",
  userSelect: "none",
  WebkitUserSelect: "none"
};

const INNER_TEXT_STYLE = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  height: "100%",
  width: DRAWER_WIDTH - TAB_INNER_PADDING,
  overflow: "hidden"
};

const changeItemName = (item, newName) => {
  if (item.type === DIRECTORY) {
    updateDirectoryName(item.id, newName);
  } else {
    updateBoardName(newName, item.id);
  }
};

const BoardName = ({ id }) => {
  const boardName = useSelector(state => selectBoardName(state, id));
  return boardName || "Board without permissions";
};

const ItemLine = ({
  dirItem,
  index,
  draggedIndex,
  draggedDepth,
  onPlusClick
}) => {
  const editModeRef = useRef(null);
  const editBoxRef = useRef(null);
  const { hovered, bind: hoveredBind } = useHover();
  const [editNameMode, setEditNameMode] = useState(false);
  const currentlyDragged = index === draggedIndex;
  const hoveredAndNoOtherIsDragged = hovered && draggedIndex === -1;
  const backgroundColor =
    hoveredAndNoOtherIsDragged || currentlyDragged
      ? "rgb(212, 235, 247)"
      : "white";
  const color =
    hoveredAndNoOtherIsDragged || currentlyDragged ? COLOR_DARK_BLUE : "#333";

  const [{ paddingLeft }, set] = useSpring(() => ({
    paddingLeft: currentlyDragged ? draggedDepth : dirItem.currentDepth * 30
  }));

  useOnClickOutside(
    editModeRef,
    () =>
      editModeRef.current !== null &&
      editNameMode === true &&
      setEditNameMode(false)
  );

  useEffect(() => {
    if (editNameMode === true && editBoxRef.current) {
      editBoxRef.current.focus();
    }
  }, [editNameMode, editBoxRef.current]);

  const onDirecotyButton = () => {
    if (dirItem.type === DIRECTORY) {
      updateIsOpenDirectoryById(dirItem.id, !dirItem.isOpen);
    }
  };

  const onMinusClicked = () => {
    updateOpenedModal(MODALS.DELETE_DIRECTORY_ITEM_MODAL, {
      itemToDelete: dirItem
    });
  };
  // console.log(dirItem.id, )
  set(() => ({
    paddingLeft: currentlyDragged
      ? draggedDepth * 30
      : dirItem.currentDepth * 30
  }));

  if (editNameMode) {
    return (
      <div
        key={dirItem.id}
        ref={editModeRef}
        style={{ marginLeft: TAB_INNER_PADDING - 10, paddingTop: 3 }}
      >
        <EditDirectoryItemInput
          ref={editBoxRef}
          item={dirItem}
          placeholder="Edit name"
          width={DRAWER_WIDTH - 2 * TAB_INNER_PADDING + 10}
          height={BOARD_CARD_HEIGHT}
          onEnter={newName => {
            setEditNameMode(false);
            changeItemName(dirItem, newName);
          }}
          onChange={newName => {}}
        />
      </div>
    );
  }

  return (
    <>
      <div
        {...hoveredBind}
        style={{
          ...ITEM_WRAPPER_STYLE,
          backgroundColor
        }}
      >
        <div
          style={{
            ...INNER_TEXT_STYLE
          }}
        >
          <animated.div
            style={{
              // ...TITLE_STYLE,
              // width: "100%",
              display: "flex",
              cursor: "pointer",
              backgroundColor,
              color,
              paddingLeft,
              fontWeight: dirItem.type === DIRECTORY ? "600" : "500",
              overflow: "hidden",
              alignItems: "center"
            }}
          >
            <div
              style={{
                display: "flex",
                width: TAB_INNER_PADDING,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <DirectoryButton
                isOpen={dirItem.isOpen}
                type={dirItem.type}
                onClick={onDirecotyButton}
                color={color}
              />
            </div>
            <div
              style={{
                display: "flex",
                paddingBottom: 3,
                alignItems: "center"
              }}
            >
              {dirItem.type === BOARD && (
                <BoardName key={`bn-${dirItem.id}`} id={dirItem.id} />
              )}
              {dirItem.type === DIRECTORY && dirItem.name}

              {hovered && (
                <div style={{ marginLeft: 5, marginBottom: 2 }}>
                  <EditButton
                    onClick={e => {
                      e.stopPropagation();
                      setEditNameMode(true);
                    }}
                  />
                </div>
              )}
            </div>
          </animated.div>
        </div>
        {/* {hovered && <MoreButton onClick={onMinusClicked} color={color} />} */}
        {hovered && <DeleteButton onClick={onMinusClicked} color={color} />}
      </div>
    </>
  );
};

export default ItemLine;
