import boardReducer from "./board";
import { selectActiveBoardId } from "./activeBoard";
import { UPDATE_ORIGIN } from "../reducers";
import {
  ACTION_UPDATE_BOARD,
  ACTION_UPDATE_BOARD_NAME,
  ACTION_UPDATE_BOARD_COMPONENT,
  ACTION_DELETE_BOARD_COMPONENT,
  ACTION_UPDATE_COMPONENT_SIZE,
  ACTION_UPDATE_COLOR,
  ACTION_UPDATE_EDITOR_STATE,
  LIFE_CYCLE
} from "./board";
import { store } from "../store";
import { BOARD_NAME_TYPE } from "../../consts/boardComponentsTypes";
import { selectActiveUserEmail } from "./user";
import {
  OWNER_PERMISSION,
  EDITOR_PERMISSION,
  READ_ONLY_PERMISSION
} from "../../consts/permissions";
import {
  WAITING_OWNER_APPROVAL,
  BOTH_SIDES_APPROVED,
  WAITING_USER_APPROVAL
} from "../../consts/connectionsStatuses";
import { isUserInParticipantList } from "../../backend/api/boardDbApi";

export const STATE_KEY = "boardsDict";

const participentsList = [
  {
    id: "1",
    email: "sbyaniv@gmail.com",
    pictureURL: "https://research.ucr.edu/media/42042/participants.png",
    userName: "Yaniv Beaudoin"
  },
  {
    id: "1yi72",
    email: "dleshem4@gmail.com",
    pictureURL:
      "https://0.academia-photos.com/5288630/3414041/29119523/s200_zvi.leshem.jpg",
    userName: "Dean Leshem"
  },
  {
    id: "1huykhk3",
    email: "sbyaniv@gmail.com",
    pictureURL: "https://research.ucr.edu/media/42042/participants.png",
    userName: "Yaniv Beaudoin"
  },
  {
    id: "1hhh5",
    email: "dleshem4@gmail.com",
    pictureURL:
      "http://www.speedlabs.org/wp-content/uploads/2018/11/1-28-1lYrYTQoLhi87mllgBw.png",
    userName: "Dean Leshem"
  },
  {
    id: "15kiuuh",
    email: "sbyaniv@gmail.com",
    pictureURL:
      "https://0.academia-photos.com/5288630/3414041/29119523/s200_zvi.leshem.jpg",
    userName: "Yaniv Beaudoin"
  },
  {
    id: "1jjjjj5",
    email: "dleshem4@gmail.com",
    pictureURL:
      "http://www.speedlabs.org/wp-content/uploads/2018/11/1-28-1lYrYTQoLhi87mllgBw.png",
    userName: "Dean Leshem"
  },
  {
    id: "15jkjkl",
    email: "sbyaniv@gmail.com",
    pictureURL: "https://research.ucr.edu/media/42042/participants.png",
    userName: "Yaniv Beaudoin"
  },
  {
    id: "15lk",
    email: "dleshem4@gmail.com",
    pictureURL:
      "http://www.speedlabs.org/wp-content/uploads/2018/11/1-28-1lYrYTQoLhi87mllgBw.png",
    userName: "Dean Leshem"
  }
];

const joinRequestsList = [
  {
    id: "1qwfsdsa5",
    email: "sbyaniv@gmail.com",
    pictureURL: "https://research.ucr.edu/media/42042/participants.png",
    userName: "Yaniv Beaudoin"
  },
  {
    id: "15zxcvas",
    email: "dleshem4@gmail.com",
    pictureURL:
      "http://www.speedlabs.org/wp-content/uploads/2018/11/1-28-1lYrYTQoLhi87mllgBw.png",
    userName: "Dean Leshem"
  }
];

const InvitedParticipantsList = [
  { id: "15g", email: "sbyaniv@gmail.com" },
  { id: "15f", email: "dleshem4@gmail.com", userName: "Yaniv Beaudoin" },
  { id: "1sd", email: "yaniv@gimoby.com" },
  {
    id: "1a",
    email: "dean@gimoby.com",
    pictureURL:
      "http://www.speedlabs.org/wp-content/uploads/2018/11/1-28-1lYrYTQoLhi87mllgBw.png",
    userName: "Dean Leshem"
  }
];

const boardsInventations = [
  {
    boardId: "bbb",
    boardName: "Board bbb"
  },
  {
    boardId: "ccc",
    boardName: "Board ccc"
  },
  {
    boardId: "ddd",
    boardName: "Board ddd"
  },
  {
    boardId: "eee",
    boardName: "Board eee"
  }
];

const ACTION_UPDATE_BOARDS_LIST = "ACTION_UPDATE_BOARDS_LIST";

const initialState = {};

const selectActiveBoardIfUndefined = (state, boardId) => {
  return boardId ? boardId : selectActiveBoardId(state);
};

export const selectBoardById = (state, id) => state[STATE_KEY][id];
export const selectBoardVersion = (state, id) => state[STATE_KEY][id].version;
export const selectBoardsList = state => Object.keys(state[STATE_KEY]);

export const selectBoardsListByIds = (state, boardsIds) =>
  boardsIds.map(id => state[STATE_KEY][id]);

export const selectActiveBoardComponentById = (state, id) => {
  const activeBoardId = selectActiveBoardId(state);
  if (activeBoardId === undefined) {
    return undefined;
  }
  return state[STATE_KEY][activeBoardId].boardComponents[id];
};

export const selectBoardName = (state, id) => {
  if (state[STATE_KEY][id] === undefined) {
    return undefined;
  }
  return state[STATE_KEY][id].name.name;
};

export const selectActiveBoardComponentsIds = state => {
  const activeBoardId = selectActiveBoardId(state);
  if (activeBoardId === undefined) {
    return [];
  }
  const boardComponents = state[STATE_KEY][activeBoardId].boardComponents;
  return Object.keys(boardComponents)
    .filter(key => boardComponents[key].stage !== LIFE_CYCLE.DELETED)
    .sort();
};

export const selectPermissionOfUserByEmail = (board, userEmail) => {
  if (isUserInParticipantList(userEmail, board.readOnlyIds)) {
    return READ_ONLY_PERMISSION;
  } else if (isUserInParticipantList(userEmail, board.editorsIds)) {
    return EDITOR_PERMISSION;
  } else if (isUserInParticipantList(userEmail, board.ownerIds)) {
    return OWNER_PERMISSION;
  } else {
    return undefined;
  }
};

export const selectActiveBoardName = state => {
  const activeBoardId = selectActiveBoardId(state);
  return selectBoardName(state, activeBoardId);
};

export const selectIsActiveUserOwnerInBoard = (state, boardId) => {
  const board = state[STATE_KEY][boardId];
  const activeUserEmail = selectActiveUserEmail(state);

  return (
    board &&
    board.ownerIds !== "" &&
    board.ownerIds.indexOf(activeUserEmail) > -1
  );
};

const selectParticipantsByConnectionStatus = (
  pariticipantsConnectionsList,
  status
) =>
  pariticipantsConnectionsList === ""
    ? []
    : pariticipantsConnectionsList.items
        .filter(c => c.status === status)
        .map(c => ({ user: c.user, connection: c }));

export const selectJoinRequestsList = (state, boardId) => {
  const board = state[STATE_KEY][boardId];

  if (board === undefined) {
    return [];
  }
  
  return selectParticipantsByConnectionStatus(
    board.pendingParticipants,
    WAITING_OWNER_APPROVAL
  );
};

// const selectUsersByPermission = (boardParticipants, permission) => {
//   boardParticipants === "" ? [] : boardParticipants.items.filter(connection => connection.permission === )
// }

export const selectParticipantsList = (state, boardId) => {
  const board = state[STATE_KEY][boardId];
  return selectParticipantsByConnectionStatus(
    board.boardParticipants,
    BOTH_SIDES_APPROVED
  );
};

const selectInvitedParticipantsConnections = pariticipantsConnectionsList =>
  selectParticipantsByConnectionStatus(
    pariticipantsConnectionsList,
    WAITING_USER_APPROVAL
  );

const selectAllUsersOfBoardParticipants = pariticipantsConnectionsList =>
  pariticipantsConnectionsList === ""
    ? []
    : pariticipantsConnectionsList.items.map(connection => ({
        user: connection.user,
        connection: connection
      }));

const buildUserFromId = (email, permission) => ({
  user: { email },
  connection: { permission }
});

export const selectAllUserEmailsWithPermissions = board => [
  ...board.readOnlyIds.map(id => buildUserFromId(id, READ_ONLY_PERMISSION)),
  ...board.editorIds.map(id => buildUserFromId(id, EDITOR_PERMISSION)),
  ...board.ownerIds.map(id => buildUserFromId(id, OWNER_PERMISSION))
];

export const selectIsOnlyApprovedOwner = (state, userId) => {
  const activeBoardId = selectActiveBoardId(state);
  const board = state[STATE_KEY][activeBoardId];
  return board.ownerIds.length === 1;
  // const approvedOwners = board.boardParticipants.items.filter(connection => {
  //   return (
  //     connection.status === BOTH_SIDES_APPROVED &&
  //     board.ownerIds.indexOf(userId) > -1
  //   );
  // });
  // return approvedOwners.length === 1;
};

/*
 * Returns a list of invited paticipatns:
 *  1. Invited participants that has no connection in any
 *     participants list - Happens when we add email of someone
 *     that have not yet opened a user one our system but was added
 *     to one of the permissions lists
 *  2. Users that are in one of the paricipants list with
 *     status WAITING_USER_APPROVED which means that the board owner add
 *     the user to a participants list but the user have not approved the
 *     connection yet
 */
export const selectInvitedParticipantsList = (state, boardId) => {
  const board = state[STATE_KEY][boardId];

  const allUserEmailsWithPermissions = selectAllUserEmailsWithPermissions(
    board
  );

  const allConnectionsUsers = selectAllUsersOfBoardParticipants(
    board.boardParticipants
  );
  // const emailsOfNonExistingUsers = allOnwerAllowedEmails.fileter(email => )
  const allPermissionedEmailWithoutConnection = allUserEmailsWithPermissions.filter(
    ({ user: { email } }) =>
      allConnectionsUsers.every(connection => connection.user.email !== email)
  );

  return [
    ...selectInvitedParticipantsConnections(board.boardParticipants),
    ...allPermissionedEmailWithoutConnection
  ];
};

export const selectConnectionByUserId = (connectionsList, userId) => {
  return connectionsList.items.filter(
    connection => connection.userId === userId
  )[0];
};

export const selectConnectionByBoardId = (connectionsList, boardId) => {
  return connectionsList.items.filter(
    connection => connection.boardId === boardId
  )[0];
};

const isBoardComponentTypeExits = (boardComponents, componentType) => {
  return Object.values(boardComponents).some(
    b => b.stage !== LIFE_CYCLE.DELETED && b.componentType === componentType
  );
};

export const selectIsBoardNameComponentExitsts = state => {
  const activeBoardId = selectActiveBoardId(state);
  if (!activeBoardId) {
    return undefined;
  }
  return isBoardComponentTypeExits(
    state[STATE_KEY][activeBoardId].boardComponents,
    BOARD_NAME_TYPE
  );
};

const updateBoardAction = (origin, board, boardId) => ({
  type: ACTION_UPDATE_BOARD,
  payload: {
    board,
    origin,
    boardId
  }
});

export const updateBoard = (origin, board, boardId) => {
  store.dispatch(updateBoardAction(origin, board, boardId));
};

export const updateBoardsList = (boardsList, origin) => {
  store.dispatch({
    type: ACTION_UPDATE_BOARDS_LIST,
    payload: {
      boardsList,
      origin
    }
  });
};

export const updateBoardName = (name, boardId) => {
  store.dispatch({
    type: ACTION_UPDATE_BOARD_NAME,
    payload: {
      name,
      origin: UPDATE_ORIGIN.LOCAL_CHANGE,
      boardId
    }
  });
};

export const updateBoardComponent = (
  dispatch,
  origin,
  boardComponent,
  boardId
) => {
  dispatch({
    type: ACTION_UPDATE_BOARD_COMPONENT,
    payload: {
      boardComponent,
      boardId,
      origin,
      boardId
    }
  });
};

export const deleteBoardComponent = (
  dispatch,
  origin,
  componentId,
  boardId
) => {
  dispatch({
    type: ACTION_DELETE_BOARD_COMPONENT,
    payload: {
      componentId,
      boardId,
      origin,
      boardId
    }
  });
};

export const updateEditorState = (
  dispatch,
  editorState,
  componentId,
  boardId
) => {
  dispatch({
    type: ACTION_UPDATE_EDITOR_STATE,
    payload: {
      componentId,
      editorState,
      origin: UPDATE_ORIGIN.LOCAL_CHANGE,
      boardId
    }
  });
};

export const updateComponentSize = (
  dispatch,
  componentId,
  sizeDelta,
  locationDelta,
  boardId
) => {
  dispatch({
    type: ACTION_UPDATE_COMPONENT_SIZE,
    payload: {
      componentId,
      sizeDelta,
      locationDelta,
      origin: UPDATE_ORIGIN.LOCAL_CHANGE,
      boardId
    }
  });
};

export const updateComponentColor = (dispatch, id, color, boardId) => {
  dispatch({
    type: ACTION_UPDATE_COLOR,
    payload: {
      id,
      color,
      origin: UPDATE_ORIGIN.LOCAL_CHANGE,
      boardId
    }
  });
};

const reducer = (state = initialState, action) => {
  if (action.payload === undefined) {
    return state;
  }
  const boardId = selectActiveBoardIfUndefined(state, action.payload.boardId);
  const boardsDictState = state[STATE_KEY];
  switch (action.type) {
    case ACTION_UPDATE_BOARD:
    case ACTION_UPDATE_BOARD_NAME:
    case ACTION_UPDATE_BOARD_COMPONENT:
    case ACTION_DELETE_BOARD_COMPONENT:
    case ACTION_UPDATE_COMPONENT_SIZE:
    case ACTION_UPDATE_COLOR:
    case ACTION_UPDATE_EDITOR_STATE:
      const updatedBoard = boardReducer(boardsDictState[boardId], action);
      return {
        ...boardsDictState,
        [boardId]: updatedBoard
      };
    case ACTION_UPDATE_BOARDS_LIST:
      var updatedBoards = {};
      for (var i = 0; i < action.payload.boardsList.length; ++i) {
        const currentBoard = action.payload.boardsList[i];
        const newAction = updateBoardAction(
          action.payload.origin,
          currentBoard,
          currentBoard.id
        );
        const reducedBoard = boardReducer(
          state[STATE_KEY][currentBoard.id],
          newAction
        );
        updatedBoards[reducedBoard.id] = reducedBoard;
      }

      return {
        ...boardsDictState,
        ...updatedBoards
      };
    default:
      return boardsDictState;
  }
};

export default reducer;
