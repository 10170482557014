import React, { useState } from "react";
import { useSelector } from "react-redux";

import JoinBoardSection from "../drawer/drawersTabs/collaborateTab/JoinBoardSection";
import HostBoardSection from "../drawer/drawersTabs/collaborateTab/HostBoardSection";
import SigninButton from "../components/SigninButton";
import { useHistory } from "react-router-dom";
import { isUserSignin } from "../redux/modules/user";
import DrawerButton from "../board/floatingButtons/DrawerButton";
import Drawer from "../drawer/Drawer";
import TestComponent2 from "../backend/tests/TestComponent2";

const WelcomeScreen = () => {
  const history = useHistory();
  const isSignedin = useSelector(isUserSignin);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <div>
      {!isSignedin && (
        <div style={{ position: "fixed", right: 45, top: 15 }}>
          <SigninButton />
        </div>
      )}
      {isSignedin && (
        <DrawerButton onClick={() => setIsDrawerOpen(!isDrawerOpen)} />
      )}
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <h1>Gimoby Boards</h1>
          <h2 style={{ width: 450, textAlign: "center", lineHeight: 1.7 }}>
            {"A new way to prepare, manage and document online meetings"}
          </h2>
        </div>

        <div
          style={{
            display: "flex",
            width: "100vw",
            justifyContent: "space-evenly"
          }}
        >
          <div style={{ width: 400 }}>
            <JoinBoardSection
              onJoinedBoard={boardCode => {
                history.push(`/boards/${boardCode}`);
              }}
            />
          </div>
          <div style={{ width: 400 }}>
            <HostBoardSection />
          </div>
        </div>
        {/* <TestComponent2/> */}
        <div />
        <div />
      </div>

      <Drawer
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isSignedin && isDrawerOpen}
        showCollaborateTab={false}
        hideBackground={true}
      />
    </div>
  );
};

export default WelcomeScreen;
