import React from "react";
import { useSelector } from "react-redux";
import {
  TITLE_TYPE,
  FREE_TEXT_CARD_TYPE,
  BOARD_NAME_TYPE
} from "../consts/boardComponentsTypes";
import FadeInOut from "../components/Animations/FadeInOut";
import DraggableBoardComponent from "./wrappers/DraggableBoardComponent";
import FreeTextCard from "./card/FreeTextCard";
import BoardNameComponent from "./TextComponents/BoardNameComponent";
import TitleBoardComponent from "./TextComponents/TitleBoardComponent";
import { selectActiveBoardComponentById } from "../redux/modules/boardsDict";

const isCommponentsEqual = (left, right) => {
  return left.version === right.version;
};

export const BoardComonent = ({ id }) => {
  const component = useSelector(
    state => selectActiveBoardComponentById(state, id),
    isCommponentsEqual
  );

  if (!component) return <div />;
  var innerComponent = null;
  switch (component.componentType) {
    case BOARD_NAME_TYPE:
      innerComponent = <BoardNameComponent key={id} id={id} />;
      break;
    case TITLE_TYPE:
      innerComponent = <TitleBoardComponent key={id} id={id} />;
      break;
    case "DISSCUSSION_TYPE": // Old type that we change to card type
    case FREE_TEXT_CARD_TYPE:
      innerComponent = <FreeTextCard key={id} id={id} />;
      break;
    default:
      return <div />;
  }

  return (
    <FadeInOut>
      <DraggableBoardComponent id={id}>
        {innerComponent}
      </DraggableBoardComponent>
    </FadeInOut>
  );
};

export default BoardComonent;
