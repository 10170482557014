import React, { useCallback, useMemo, useState, useRef } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, Slate } from "slate-react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";

import {
  updateEditorState,
  selectActiveBoardComponentById,
} from "../../redux/modules/boardsDict";
import { useSelector, useDispatch } from "react-redux";
import z from "../../javascript/z";
import Element from "./RenderElement";
import Leaf from "./RenderLeaf";
import { toggleMark, checkIfLocationInChildren, blur } from "./Utils";
import ToolBar from "./ToolBar";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { withLinks } from "./withLinks";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const lastStateJsonDict = {};

export const selectorEquality = (left, right) => {
  return left.version === right.version;
};

const SlateEditor = ({ id }) => {
  //   const [value, setValue] = useState(initialValue);
  const ref = useRef();
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  var editor = useMemo(
    () => withLinks(withHistory(withReact(createEditor()))),
    []
  );
  const [isFocused, setIsFocused] = useState(false);

  useOnClickOutside(ref, () => setIsFocused(false));
  const boardComponent = useSelector(
    (state) => selectActiveBoardComponentById(state, id),
    selectorEquality
  );
  const dispatch = useDispatch();

  const onChange = (value) => {
    const currentStateJson = JSON.stringify(value);
    if (currentStateJson !== lastStateJsonDict[id]) {
      lastStateJsonDict[id] = currentStateJson;
      updateEditorState(dispatch, value, boardComponent.id);
    }
  };

  if (editor.selection !== null && boardComponent.editorState !== undefined) {
    if (
      !checkIfLocationInChildren(
        editor.selection.focus,
        boardComponent.editorState
      ) ||
      !checkIfLocationInChildren(
        editor.selection.anchor,
        boardComponent.editorState
      )
    ) {
      blur(editor);
    }
  }

  return (
    <div
      ref={ref}
      onMouseDown={(e) => e.stopPropagation()}
      style={{ cursor: "text" }}
    >
      <Slate
        editor={editor}
        value={boardComponent.editorState}
        onChange={onChange}
      >
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          autoComplete="on"
          autoCorrect="on"
          autoCapitalize="on"
          spellCheck="on"
          //   autoFocus
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {isFocused ? (
          <ToolBar color={boardComponent.color} />
        ) : (
          <div style={{ height: z(4) }} />
        )}
      </Slate>
    </div>
  );
};

export default SlateEditor;
