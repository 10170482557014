import React, { useState, useCallback, useRef, useEffect } from "react";
import { useTransition, animated } from "react-spring";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const TabsWrapper = ({ children, choosenTabIndex, height }) => {
  const previousChoosenTabIndex = usePrevious(choosenTabIndex);

  const enteringDirection =
    choosenTabIndex < previousChoosenTabIndex ? "" : "-";

  const leavingDirection = choosenTabIndex < previousChoosenTabIndex ? "-" : "";

  const transitions = useTransition(choosenTabIndex, p => p, {
    from: {
      // transform: `translate3d(${enteringDirection}100%,0,0)`,
      opacity: 0
    },
    enter: {
      opacity: 1
      // transform: "translate3d(0%,0,0)"
    },
    leave: {
      opacity: 0
      // transform: `translate3d(${leavingDirection}100%,0,0)`
    }
  });

  return (
    <div style={{ width: "100%", height }}>
      {transitions.map(({ item, key, props }) => {
        return (
          <animated.div
            key={key}
            style={{
              ...props,
              position: "absolute",
              width: "100%",
              height,
              willChange: "opacity"
            }}
          >
            {children[item]}
          </animated.div>
        );
      })}
    </div>
  );
};

export default TabsWrapper;
