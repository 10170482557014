import React from "react";
import { useSpring, animated } from "react-spring";
import { updateOpenedModal } from "../../redux/modules/modals";
import { useDispatch } from "react-redux";
import {
  MODAL_ZINDEX,
  MODAL_INNER_ZINDEX,
  MODAL_OUTER_ZINDEX,
} from "../../consts/layout";

const GimobydModal = ({
  isOpen,
  children,
  backgroundColor,
  autoClose,
  animate,
  innerStyle,
  onClose,
}) => {
  const dispatch = useDispatch();

  const animationConfig = animate
    ? {
        from: { xys: [-50, 50, 0.8], opacity: 0.7 },
        to: { xys: [0, 0, 1], opacity: 1 },
      }
    : {};
  const [props] = useSpring(() => animationConfig);

  if (!isOpen) {
    return <div />;
  }

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "#f3f4f5",
          opacity: 0.95,
          zIndex: MODAL_OUTER_ZINDEX,
        }}
      />
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: MODAL_INNER_ZINDEX,
        }}
        onClick={() =>
          onClose
            ? onClose()
            : autoClose && updateOpenedModal(dispatch, undefined)
        }
      >
        <animated.div
          style={{
            minHeight: 300,
            minWidth: 500,
            background: backgroundColor,
            opacity: animate && props.opacity,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            borderRadius: 20,
            paddingBottom: "1em",
            transform:
              animate &&
              props.xys.interpolate(
                (x, y, s) => `translate3d(${x}px,${y}px,0) scale(${s})`
              ),
            ...innerStyle,
            // props.xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`)
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </animated.div>
      </div>
    </div>
  );
};

export default GimobydModal;
