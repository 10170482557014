import React from "react";
import MenuButton from "../MenuButton";
import { useDispatch } from "react-redux";
import { deleteBoardComponent } from "../../../redux/modules/boardsDict";
import { UPDATE_ORIGIN } from "../../../redux/reducers";

export const DeleteComponentButton = ({ id, onCloseMenu, color }) => {
  const dispatch = useDispatch();

  const onClick = e => {
    deleteBoardComponent(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, id);
    onCloseMenu(e);
  };

  return <MenuButton onClick={onClick} color={color} text="Delete" />;
};

export default DeleteComponentButton;
