import React from "react";
import { useHistory } from "react-router-dom";
import Seperator from "../Seperator";
import GimobyModal from "./GimobyModal";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import { Button } from "@material-ui/core";
import { COLOR_VERY_DARK_BLUE } from "../../consts/colors";
import { updateOpenedModal, MODALS } from "../../redux/modules/modals";
import { useDispatch } from "react-redux";
import { BoldModalText } from "./BoldModalText";

//1B998B
const RequestedToJoinBoard = ({ isOpen, extraData }) => {
  const history = useHistory();

  const onClose = () => {
    updateOpenedModal(undefined);
    history.push("/");
  };
  const onClick = () => {
    updateOpenedModal(MODALS.WAITING_OWNER_APPROVAL, extraData);
  };

  return (
    <GimobyModal
      isOpen={isOpen}
      backgroundColor={COLOR_VERY_DARK_BLUE}
      autoClose={false}
      onClose={onClose}
    >
      <Seperator height={25} />
      <AddToQueueIcon
        style={{ colorPrimary: "white", fontSize: 80, color: "white" }}
      />
      <Seperator height={10} />
      <BoldModalText> Sent a request to join the board</BoldModalText>
      <Seperator height={25} />
      <Button
        onClick={onClick}
        variant="contained"
        style={{ background: "white" }}
      >
        <div style={{ fontWeight: "600", color: COLOR_VERY_DARK_BLUE }}>
          Wait for owner approval
        </div>
      </Button>
    </GimobyModal>
  );
};

export default RequestedToJoinBoard;
