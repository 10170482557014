import React, { useState, useRef, useCallback } from "react";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import ComponentMenu from "../floatingMenu/componentMenu/ComponentMenu";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import ChangeColorMenu from "../floatingMenu/componentMenu/ChangeColorMenu";

const NOT_HOVERD_COLOR = "#bbb";

const ArrowDropDownButton = ({ hoverTintColor, id, fontSize }) => {
  const [isOnHover, setIsHover] = useState(false);
  const [isChangeColorMenuOpen, setIsChangeColorMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setIsMenuOpen(false);
    setIsChangeColorMenuOpen(false);
  });

  const onClick = e => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
      setIsChangeColorMenuOpen(false);
    } else if (isChangeColorMenuOpen) {
      setIsMenuOpen(true);
      setIsChangeColorMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const getLocation = () => {
    return {
      x: ref.current.getBoundingClientRect().x,
      y: ref.current.getBoundingClientRect().y
    };
  };

  const onOpenChangeColorMenu = () => {
    setIsMenuOpen(false);
    setIsChangeColorMenuOpen(true);
  };

  const icon = useCallback(() => {
    return (
      <ArrowDropDownCircleIcon
        style={{
          color: isOnHover ? hoverTintColor : NOT_HOVERD_COLOR,
          fontSize,
          cursor: "pointer"
        }}
      />
    );
  }, [fontSize, isOnHover]);

  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  return (
    <div ref={ref}>
      <div
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {icon()}
      </div>
      {isMenuOpen && (
        <ComponentMenu
          id={id}
          color={hoverTintColor}
          location={getLocation()}
          onClose={() => setIsMenuOpen(false)}
          onOpenChangeColorMenu={onOpenChangeColorMenu}
        />
      )}
      {isChangeColorMenuOpen && (
        <ChangeColorMenu
          color={hoverTintColor}
          id={id}
          onClose={() => setIsChangeColorMenuOpen(false)}
        />
      )}
    </div>
  );
};

export default ArrowDropDownButton;
