import React from "react";
import NoBoardBaseModal from "./NoBoardBaseModal";

const BoardOwnerDeclinedRequest = ({ isOpen }) => {
  const text = "Your request was declined by the board owner";
  return (
    <NoBoardBaseModal
      isOpen={isOpen}
      text={text}
      autoClose={false}
      innerStyle={{ width: 500, textAlign: "center" }}
    />
  );
};

export default BoardOwnerDeclinedRequest;
