/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBoard = /* GraphQL */ `
  mutation CreateBoard(
    $input: CreateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    createBoard(input: $input, condition: $condition) {
      id
      boardMeetingStage {
        stage
        id
        version
      }
      name {
        name
        version
      }
      meetingLifecycleTime {
        id
        scheduledStartTime
        scheduledEndTime
        startTime
        closedDate
        version
      }
      boardComponents
      boardParticipants {
        items {
          id
          userId
          boardId
          status
          permission
        }
        nextToken
      }
      pendingParticipants {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      readOnlyIds
      editorIds
      ownerIds
      version
    }
  }
`;
export const updateBoard = /* GraphQL */ `
  mutation UpdateBoard(
    $input: UpdateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    updateBoard(input: $input, condition: $condition) {
      id
      boardMeetingStage {
        stage
        id
        version
      }
      name {
        name
        version
      }
      meetingLifecycleTime {
        id
        scheduledStartTime
        scheduledEndTime
        startTime
        closedDate
        version
      }
      boardComponents
      boardParticipants {
        items {
          id
          userId
          boardId
          status
          permission
          user {
            id
            userName
            email
            pictureURL
          }
        }
        nextToken
      }
      pendingParticipants {
        items {
          id
          userId
          boardId
          status
          user {
            id
            userName
            email
            pictureURL
          }
        }
        nextToken
      }
      readOnlyIds
      editorIds
      ownerIds
      version
    }
  }
`;
export const deleteBoard = /* GraphQL */ `
  mutation DeleteBoard(
    $input: DeleteBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    deleteBoard(input: $input, condition: $condition) {
      id
      boardMeetingStage {
        stage
        id
        version
      }
      name {
        name
        version
      }
      meetingLifecycleTime {
        id
        scheduledStartTime
        scheduledEndTime
        startTime
        closedDate
        version
      }
      boardComponents
      boardParticipants {
        items {
          id
          userId
          boardId
          status
          permission
        }
        nextToken
      }
      editorParticipants {
        items {
          id
          status
        }
        nextToken
      }
      pendingParticipants {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      readOnlyIds
      editorIds
      ownerIds
      version
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      userName
      pictureURL
      email
      emailVerified
      participatingBoards {
        items {
          id
          userId
          boardId
          status
          permission
        }
        nextToken
      }
      pendingBoards {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      boardsDirectory
      version
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      userName
      pictureURL
      email
      emailVerified
      participatingBoards {
        items {
          id
          userId
          boardId
          status
          permission
        }
        nextToken
      }
      pendingBoards {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      boardsDirectory
      version
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      userName
      pictureURL
      email
      emailVerified
      participatingBoards {
        items {
          id
          userId
          boardId
          status
          permission
        }
        nextToken
      }
      pendingBoards {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      boardsDirectory
      version
    }
  }
`;
export const createPendingParticipant = /* GraphQL */ `
  mutation CreatePendingParticipant(
    $input: CreatePendingParticipantInput!
    $condition: ModelPendingParticipantConditionInput
  ) {
    createPendingParticipant(input: $input, condition: $condition) {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
      }
      userId
      boardId
      status
    }
  }
`;
export const updatePendingParticipant = /* GraphQL */ `
  mutation UpdatePendingParticipant(
    $input: UpdatePendingParticipantInput!
    $condition: ModelPendingParticipantConditionInput
  ) {
    updatePendingParticipant(input: $input, condition: $condition) {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
      }
      userId
      board {
        id
        boardMeetingStage {
          stage
          id
          version
        }
        name {
          name
          version
        }
        meetingLifecycleTime {
          id
          scheduledStartTime
          scheduledEndTime
          startTime
          closedDate
          version
        }
        boardComponents
        boardParticipants {
          nextToken
        }
        pendingParticipants {
          nextToken
        }
        readOnlyIds
        editorIds
        ownerIds
        version
      }
      boardId
      status
    }
  }
`;
export const deletePendingParticipant = /* GraphQL */ `
  mutation DeletePendingParticipant(
    $input: DeletePendingParticipantInput!
    $condition: ModelPendingParticipantConditionInput
  ) {
    deletePendingParticipant(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createBoardParticipantConnection = /* GraphQL */ `
  mutation CreateBoardParticipantConnection(
    $input: CreateBoardParticipantConnectionInput!
    $condition: ModelBoardParticipantConnectionConditionInput
  ) {
    createBoardParticipantConnection(input: $input, condition: $condition) {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
      }
      userId
      boardId
      status
      permission
    }
  }
`;
export const updateBoardParticipantConnection = /* GraphQL */ `
  mutation UpdateBoardParticipantConnection(
    $input: UpdateBoardParticipantConnectionInput!
    $condition: ModelBoardParticipantConnectionConditionInput
  ) {
    updateBoardParticipantConnection(input: $input, condition: $condition) {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
      }
      userId
      boardId
      status
      permission
    }
  }
`;
export const deleteBoardParticipantConnection = /* GraphQL */ `
  mutation DeleteBoardParticipantConnection(
    $input: DeleteBoardParticipantConnectionInput!
    $condition: ModelBoardParticipantConnectionConditionInput
  ) {
    deleteBoardParticipantConnection(input: $input, condition: $condition) {
      id
      user {
        id
        firstName
        lastName
        userName
        pictureURL
        email
      }
      userId
      board {
        id
        boardMeetingStage {
          stage
          id
          version
        }
        name {
          name
          version
        }
        meetingLifecycleTime {
          id
          scheduledStartTime
          scheduledEndTime
          startTime
          closedDate
          version
        }
        boardComponents
        boardParticipants {
          nextToken
        }
        readOnlyIds
        editorIds
        ownerIds
        version
      }
      boardId
      status
      permission
    }
  }
`;
// export const createOwnerParticipant = /* GraphQL */ `
//   mutation CreateOwnerParticipant(
//     $input: CreateOwnerParticipantInput!
//     $condition: ModelOwnerParticipantConditionInput
//   ) {
//     createOwnerParticipant(input: $input, condition: $condition) {
//       id
//       user {
//         id
//         firstName
//         lastName
//         userName
//         pictureURL
//         email
//       }
//       board {
//         id
//         boardMeetingStage {
//           stage
//           id
//           version
//         }
//         name {
//           name
//           version
//         }
//         meetingLifecycleTime {
//           id
//           scheduledStartTime
//           scheduledEndTime
//           startTime
//           closedDate
//           version
//         }
//         boardComponents
//         readOnlyParticipants {
//           nextToken
//         }
//         editorParticipants {
//           nextToken
//         }
//         ownerParticipants {
//           nextToken
//         }
//         pendingParticipants {
//           nextToken
//         }
//         readOnlyIds
//         editorIds
//         ownerIds
//       }
//       status
//     }
//   }
// `;
// export const updateOwnerParticipant = /* GraphQL */ `
//   mutation UpdateOwnerParticipant(
//     $input: UpdateOwnerParticipantInput!
//     $condition: ModelOwnerParticipantConditionInput
//   ) {
//     updateOwnerParticipant(input: $input, condition: $condition) {
//       id
//       user {
//         id
//         firstName
//         lastName
//         userName
//         pictureURL
//         email
//       }
//       board {
//         id
//         boardMeetingStage {
//           stage
//           id
//           version
//         }
//         name {
//           name
//           version
//         }
//         meetingLifecycleTime {
//           id
//           scheduledStartTime
//           scheduledEndTime
//           startTime
//           closedDate
//           version
//         }
//         boardComponents
//         readOnlyParticipants {
//           nextToken
//         }
//         editorParticipants {
//           nextToken
//         }
//         ownerParticipants {
//           nextToken
//         }
//         pendingParticipants {
//           nextToken
//         }
//         readOnlyIds
//         editorIds
//         ownerIds
//       }
//       status
//     }
//   }
// `;
// export const deleteOwnerParticipant = /* GraphQL */ `
//   mutation DeleteOwnerParticipant(
//     $input: DeleteOwnerParticipantInput!
//     $condition: ModelOwnerParticipantConditionInput
//   ) {
//     deleteOwnerParticipant(input: $input, condition: $condition) {
//       id
//       user {
//         id
//         firstName
//         lastName
//         userName
//         pictureURL
//         email
//       }
//       board {
//         id
//         boardMeetingStage {
//           stage
//           id
//           version
//         }
//         name {
//           name
//           version
//         }
//         meetingLifecycleTime {
//           id
//           scheduledStartTime
//           scheduledEndTime
//           startTime
//           closedDate
//           version
//         }
//         boardComponents
//         readOnlyParticipants {
//           nextToken
//         }
//         editorParticipants {
//           nextToken
//         }
//         ownerParticipants {
//           nextToken
//         }
//         pendingParticipants {
//           nextToken
//         }
//         readOnlyIds
//         editorIds
//         ownerIds
//       }
//       status
//     }
//   }
// `;
// export const createPendingParticipant = /* GraphQL */ `
//   mutation CreatePendingParticipant(
//     $input: CreatePendingParticipantInput!
//     $condition: ModelPendingParticipantConditionInput
//   ) {
//     createPendingParticipant(input: $input, condition: $condition) {
//       id
//       user {
//         id
//         firstName
//         lastName
//         userName
//         pictureURL
//         email
//       }
//       board {
//         id
//         boardMeetingStage {
//           stage
//           id
//           version
//         }
//         name {
//           name
//           version
//         }
//         meetingLifecycleTime {
//           id
//           scheduledStartTime
//           scheduledEndTime
//           startTime
//           closedDate
//           version
//         }
//         boardComponents
//         readOnlyParticipants {
//           nextToken
//         }
//         editorParticipants {
//           nextToken
//         }
//         ownerParticipants {
//           nextToken
//         }
//         pendingParticipants {
//           nextToken
//         }
//         readOnlyIds
//         editorIds
//         ownerIds
//       }
//       status
//     }
//   }
// `;
// export const updatePendingParticipant = /* GraphQL */ `
//   mutation UpdatePendingParticipant(
//     $input: UpdatePendingParticipantInput!
//     $condition: ModelPendingParticipantConditionInput
//   ) {
//     updatePendingParticipant(input: $input, condition: $condition) {
//       id
//       user {
//         id
//         firstName
//         lastName
//         userName
//         pictureURL
//         email
//       }
//       board {
//         id
//         boardMeetingStage {
//           stage
//           id
//           version
//         }
//         name {
//           name
//           version
//         }
//         meetingLifecycleTime {
//           id
//           scheduledStartTime
//           scheduledEndTime
//           startTime
//           closedDate
//           version
//         }
//         boardComponents
//         readOnlyParticipants {
//           nextToken
//         }
//         editorParticipants {
//           nextToken
//         }
//         ownerParticipants {
//           nextToken
//         }
//         pendingParticipants {
//           nextToken
//         }
//         readOnlyIds
//         editorIds
//         ownerIds
//       }
//       status
//     }
//   }
// `;
// export const deletePendingParticipant = /* GraphQL */ `
//   mutation DeletePendingParticipant(
//     $input: DeletePendingParticipantInput!
//     $condition: ModelPendingParticipantConditionInput
//   ) {
//     deletePendingParticipant(input: $input, condition: $condition) {
//       id
//       user {
//         id
//         firstName
//         lastName
//         userName
//         pictureURL
//         email
//       }
//       board {
//         id
//         boardMeetingStage {
//           stage
//           id
//           version
//         }
//         name {
//           name
//           version
//         }
//         meetingLifecycleTime {
//           id
//           scheduledStartTime
//           scheduledEndTime
//           startTime
//           closedDate
//           version
//         }
//         boardComponents
//         readOnlyParticipants {
//           nextToken
//         }
//         editorParticipants {
//           nextToken
//         }
//         ownerParticipants {
//           nextToken
//         }
//         pendingParticipants {
//           nextToken
//         }
//         readOnlyIds
//         editorIds
//         ownerIds
//       }
//       status
//     }
//   }
// `;
