import React from "react";
import { useHover } from "react-hooks-lib";
export const SquareButton = ({ backgroundColor, text, onClick, fontSize }) => {
  const { hovered, bind: hoveredBind } = useHover();
  return (
    <div
      {...hoveredBind}
      onClick={onClick}
      style={{
        borderRadius: 50,
        padding: 10,
        cursor: "pointer",
        backgroundColor,
        opacity: hovered ? 0.5 : 0.8,
        color: "white",
        userSelect:'none',
        WebkitUserSelect: 'none',
        fontSize: fontSize ? fontSize : undefined
      }}
    >
      {text}
    </div>
  );
};
