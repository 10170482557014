import React from "react";
import { useHover } from "react-hooks-lib";
import { color } from "./color";
import { selectIsActiveUserOwnerInBoard } from "../../redux/modules/boardsDict";

export const PermissionButton = ({ onClick, icon, text, isChoosen, isOwner }) => {
  const { hovered, bind: hoveredBind } = useHover();

  return (
    <div
      {...hoveredBind}
      onClick={isOwner ? onClick : undefined}
      style={{
        padding: 7,
        borderRadius: 100,
        width: 38,
        display: "flex",
        flexDirection: "column",
        cursor: isOwner ? "pointer" : "default",
        alignItems: "center",
        color: isChoosen ? "white" : isOwner && hovered ? color : "#333",
        background: isChoosen ? color : undefined,
        opacity: isChoosen ? 0.5 : undefined,
        WebkitUserSelect: 'none'
      }}
    >
      {icon}
      <div style={{ fontSize: 10, marginTop: 2 }}>{text}</div>
    </div>
  );
};
