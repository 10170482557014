export const LEFT_PADDING_SIZE = 29;
export const BORDER_PADDING = 10;

export const DRAGGABLE_ZINDEX = 50;

export const DRAWER_ZINDEX_COLLABORATE = 180;
export const MODAL_OUTER_ZINDEX = 200;
export const MODAL_INNER_ZINDEX = 201;
export const DRAWER_ZINDEX_BOARDS = 220;

export const FLOATING_MENU_ZINDEX = 300;

export const TAB_INNER_PADDING = 40
export const DRAWER_WIDTH = 500

