import React from "react";

import { updateBoardComponent } from "../../redux/modules/boardsDict";
import { UPDATE_ORIGIN } from "../../redux/reducers";

import { nullIfNeeded } from "../../javascript/nullIfNeeded";
import CardComponent from "../wrappers/CardComponent";
import SlateEditor from "../../components/SlateEditor/SlateEditor";

const _ = require("lodash");

export const updateText = (dispatch, component, newText) => {
  var clonedComponent = _.cloneDeep(component);
  clonedComponent.text = nullIfNeeded(newText);
  updateBoardComponent(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, clonedComponent);
};

const FreeTextCard = ({ id }) => {
  return (
    <CardComponent id={id}>
      <SlateEditor id={id} />
    </CardComponent>
  );
};

export default FreeTextCard;
