// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { Auth } from "aws-amplify"

// import { setOAuthRedirect } from "./backend/api/auth";

export const DEV_GRAPHQL_ENDPOINT = "https://qbrubngnfnewvbrr62bsxyp2oe.appsync-api.us-east-2.amazonaws.com/graphql"
const PRODUCTION_GRAPHQL_ENDPOINT = "https://grmktlkqsrf2xjpuh5qqortmfm.appsync-api.us-east-2.amazonaws.com/graphql"
const TEST_GRAPHQL_ENDPOINT = "https://t6fjjbt7fjh2pgvr4iikbgvqpm.appsync-api.us-east-2.amazonaws.com/graphql"


const DEV_GRAPHQL_API_KEY ="da2-2nnt5dg4znbozewvq3webi5rva"
const PRODUCTION_GRAPHQL_API_KEY = "da2-u634u6i7nnbznaxkjri5lvoqaa"
const TEST_GRAPHQL_API_KEY = "da2-u634u6i7nnbznaxkjri5lvoqaa"

const DEV_IDENTITY_POOL_ID = "us-east-2:a705554b-0747-4e3f-b119-270a704d115a"
const PRODUCTION_IDENTITY_POOL_ID = "us-east-2:480ad746-909a-46cd-904f-36f307a27b92"
const TEST_IDENTITY_POOL_ID = "us-east-2:74afd93c-bc4c-4632-b003-e1bc1c5614ad"



const DEV_USER_POOL_ID = "us-east-2_sb81VjEcr"
const PRODUCTION_USER_POOL_ID = "us-east-2_YTSpGqOvf"
const TEST_USER_POOL_ID = "us-east-2_0b3n0zXUN"

const DEV_USER_POOL_WEB_CLIENT_ID = "3cjkg6216if9t8je2kd647ijlv"
const PRODUCTION_USER_POOL_WEB_CLIENT_ID = "384s0u9jm8deo0c8p0jpk8dlmu"
const TEST_USER_POOL_WEB_CLIENT_ID = "3aao57e2u301a7c1c6duogdq6c"


const DEV_OAUTH_DOMAIN = "gimobywhiteboard7f55516d-7f55516d-dev.auth.us-east-2.amazoncognito.com"
const PRODUCTION_OAUTH_DOMAIN = "auth-boards.gimoby.com"
const TEST_OAUTH_DOMAIN = "gimobywhiteboard1b9a160f-1b9a160f-test.auth.us-east-2.amazoncognito.com"


const DEV_SIGN_IN_REDIRECT = "http://localhost:3000/logged-in/"
const PRODUCTION_SIGN_IN_REDIRECT = "https://boards.gimoby.com/logged-in/"
const TEST_SIGN_IN_REDIRECT = "https://testing.d2wr19tn58ofl4.amplifyapp.com/logged-in/"

const DEV_SIGN_OUT_REDIRECT = "http://localhost:3000/"
const PRODUCTION_SIGN_OUT_REDIRECT = "https://boards.gimoby.com/"
const TEST_SIGN_OUT_REDIRECT = "https://testing.d2wr19tn58ofl4.amplifyapp.com/"


export function setAwsConfiguration(manuallySetProduction, isTest){
    //Dev environment
    if((!manuallySetProduction)&&(window)&&(window.location)&&(window.location.hostname === 'localhost')){
        awsmobile.aws_appsync_graphqlEndpoint = DEV_GRAPHQL_ENDPOINT
        awsmobile.aws_appsync_apiKey = DEV_GRAPHQL_API_KEY
        awsmobile.aws_cognito_identity_pool_id = DEV_IDENTITY_POOL_ID
        awsmobile.aws_user_pools_id = DEV_USER_POOL_ID
        awsmobile.aws_user_pools_web_client_id = DEV_USER_POOL_WEB_CLIENT_ID
        awsmobile.oauth.domain = DEV_OAUTH_DOMAIN
        awsmobile.oauth.redirectSignIn = DEV_SIGN_IN_REDIRECT
        awsmobile.oauth.redirectSignOut = DEV_SIGN_OUT_REDIRECT

    }
    //Production environment Running on localhost
    else if((manuallySetProduction)&&(window)&&(window.location)&&(window.location.hostname === 'localhost')){
        awsmobile.aws_appsync_graphqlEndpoint = PRODUCTION_GRAPHQL_ENDPOINT
        awsmobile.aws_appsync_apiKey = PRODUCTION_GRAPHQL_API_KEY
        awsmobile.aws_cognito_identity_pool_id = PRODUCTION_IDENTITY_POOL_ID
        awsmobile.aws_user_pools_id = PRODUCTION_USER_POOL_ID
        awsmobile.aws_user_pools_web_client_id = PRODUCTION_USER_POOL_WEB_CLIENT_ID
        awsmobile.oauth.domain = PRODUCTION_OAUTH_DOMAIN
        awsmobile.oauth.redirectSignIn = DEV_SIGN_IN_REDIRECT
        awsmobile.oauth.redirectSignOut = DEV_SIGN_OUT_REDIRECT
    }
    //Production environment
    else{
        awsmobile.aws_appsync_graphqlEndpoint = PRODUCTION_GRAPHQL_ENDPOINT
        awsmobile.aws_appsync_apiKey = PRODUCTION_GRAPHQL_API_KEY
        awsmobile.aws_cognito_identity_pool_id = PRODUCTION_IDENTITY_POOL_ID
        awsmobile.aws_user_pools_id = PRODUCTION_USER_POOL_ID
        awsmobile.aws_user_pools_web_client_id = PRODUCTION_USER_POOL_WEB_CLIENT_ID
        awsmobile.oauth.domain = PRODUCTION_OAUTH_DOMAIN
        awsmobile.oauth.redirectSignIn = PRODUCTION_SIGN_IN_REDIRECT
        awsmobile.oauth.redirectSignOut = PRODUCTION_SIGN_OUT_REDIRECT
    }
    if(isTest){
        awsmobile.aws_appsync_graphqlEndpoint = TEST_GRAPHQL_ENDPOINT
        awsmobile.aws_appsync_apiKey = TEST_GRAPHQL_API_KEY
        awsmobile.aws_cognito_identity_pool_id = TEST_IDENTITY_POOL_ID
        awsmobile.aws_user_pools_id = TEST_USER_POOL_ID
        awsmobile.aws_user_pools_web_client_id = TEST_USER_POOL_WEB_CLIENT_ID
        awsmobile.oauth.domain = TEST_OAUTH_DOMAIN
        awsmobile.oauth.redirectSignIn = TEST_SIGN_IN_REDIRECT
        awsmobile.oauth.redirectSignOut = TEST_SIGN_OUT_REDIRECT
    }
}


export var awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "graphql_headers":async()=>({
        'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()} ),
    "aws_cognito_region": "us-east-2",
    "oauth": {
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};

// export default awsmobile;

