import React from "react";

import updateRemoteUser from "./updateRemoteUser";
import updateRemoteBoard from "./updateRemoteBoard";

var requiredUpdates = {
  // pairs of Id and Entity
};

const resetRequiredUpdates = () => {
  requiredUpdates = {};
};

const addRequiredUpdate = (entityType, entity) => {
  requiredUpdates[entity.id] = { entityType, entity };
};

const BOARD_ENTITY = "BOARD_ENTITY";
const USER_ENTITY = "USER_ENTITY";

export const addRequiredBoardUpdate = entity =>
  addRequiredUpdate(BOARD_ENTITY, entity);
export const addRequiredUserUpdate = entity =>
  addRequiredUpdate(USER_ENTITY, entity);

const update = () => {
  const updates = Object.entries(requiredUpdates);
  for (let i = 0; i < updates.length; i++) {
    const entity = updates[i][1].entity;
    const entityType = updates[i][1].entityType;

    if (entityType === USER_ENTITY) {
      updateRemoteUser(entity);
    }
    if (entityType === BOARD_ENTITY) {
      updateRemoteBoard(entity);
    }
  }
};

const runUpdater = () => {
  update();
  resetRequiredUpdates();
  setTimeout(runUpdater, 300);
};

export default runUpdater;
