import React, { useState } from "react";
import Seperator from "../Seperator";
import GimobyModal from "./GimobyModal";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { COLOR_LOGO_BLUE } from "../../consts/colors";
import GoogleLogo from "../../assets/GoogleLogo.png";
import { launchLoginWithGoogleApi } from "../../backend/api/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSpring, animated } from "react-spring";
import { BoldModalText } from "./BoldModalText";
import { useHistory } from "react-router-dom";
import { updateOpenedModal } from "../../redux/modules/modals";

const SigninWithGoogleButton = ({ onClick }) => (
  <div
    onClick={onClick}
    variant="contained"
    style={{
      background: "white",
      display: "flex",
      padding: 10,
      paddingRight: 15,
      borderRadius: 5,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    }}
  >
    <img
      style={{ width: 20, height: 20, padding: 5 }}
      src={GoogleLogo}
      alt="GoogleLogo"
    />

    <div style={{ marginLeft: 5, fontWeight: "600", color: "#586BA4" }}>
      Sign in with Google
    </div>
  </div>
);

const ProggressButton = () => {
  const [props] = useSpring(() => ({
    from: { width: 120 },
    to: { width: 50, height: 23 }
  }));

  return (
    <animated.div
      style={{
        ...props,
        backgroundColor: "white",
        padding: 15,
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
       
      }}
    >
      <CircularProgress size={23}/>
    </animated.div>
  );
};

const SigninModal = ({ isOpen }) => {
  const [isRedirceting, setIsRedirecting] = useState(false);
  const text = "Unlock Gimoby Boards";

  const history = useHistory();

  const onClose = () => {
    updateOpenedModal(undefined);
    history.push("/");
  };

  const onClick = () => {
    setIsRedirecting(true);
    launchLoginWithGoogleApi();
  };

  return (
    <GimobyModal
      isOpen={isOpen}
      backgroundColor={COLOR_LOGO_BLUE}
      onClose={onClose}
    >
      <Seperator height={25} />
      <LockOpenIcon
        style={{ colorPrimary: "white", fontSize: 80, color: "white" }}
      />
      <Seperator height={20} />
      <BoldModalText>{text}</BoldModalText>
      <Seperator height={20} />

      {!isRedirceting && <SigninWithGoogleButton onClick={onClick} />}
      {isRedirceting && <ProggressButton />}
      <Seperator height={25} />
    </GimobyModal>
  );
};

export default SigninModal;
