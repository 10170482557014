import React from "react";
import { COLOR_VERY_DARK_BLUE } from "../consts/colors";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useHover } from "react-hooks-lib";


export const DrawerCloseArrowButton = ({ onClick }) => {
  const { hovered, bind: hoveredBind } = useHover();
  const color = COLOR_VERY_DARK_BLUE + "99";
  return (
    <div
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      {...hoveredBind}
      style={{
        width: 25,
        height: 25,
        position: "absolute",
        top: 20,
        left: -15,
        borderRadius: 100,
        border: `2px solid ${color}`,
        zIndex: 800,
        background: hovered ? COLOR_VERY_DARK_BLUE : "white",
        cursor: "pointer"
      }}
    >
      <ChevronRightIcon
        style={{ color: hovered ? "white" : COLOR_VERY_DARK_BLUE }}
      />
    </div>
  );
};
