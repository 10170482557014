import React, { useState } from "react";
import FloatingMenu, { SELECT_MENU_WIDTH } from "../FloatingMenu";
import { useDispatch } from "react-redux";

import { colorsArray, COLOR_SILVER } from "../../../consts/colors";
import { updateComponentColor } from "../../../redux/modules/boardsDict";

const _ = require("lodash");

const BUTTON_STYLE = {
  display: "flex",
  padding: 8,
  // alignItems: "center",
  // justifyContent: 'center',
  borderRadius: 4,
  border: "none",
  width: "100%",
  color: "#333333",
  fontWeight: 600,
  fontSize: 14,
  letterSpacing: 0,
  whiteSpace: "nowrap",
  textDecoration: "none",
  outline: "none"
};

const ColorButton = ({ color, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      style={{
        borderRadius: 200,
        width: 20,
        height: 20,
        padding: 6,
        cursor: "pointer",
        backgroundColor: isHovered ? COLOR_SILVER : undefined,
        opacity: isHovered ? 0.7 : undefined
        // flex: '50%'
      }}
    >
      <div
        style={{
          borderRadius: 100,
          width: 20,
          height: 20,
          backgroundColor: color
        }}
      />
    </div>
  );
};

const ChangeColorMenu = ({ onClose, color, id }) => {
  const dispatch = useDispatch();

  const onClick = e => {
    onClose();
    e.stopPropagation();
  };

  const locationParamenters = {
    left: -SELECT_MENU_WIDTH / 2,
    fromTop: -10,
    toTop: 5
  };

  const updateColor = color => {
    updateComponentColor(dispatch, id, color);
  };

  return (
    <FloatingMenu locationParamenters={locationParamenters} onClick={onClick}>
      <div style={BUTTON_STYLE}>Pick a color</div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {colorsArray.map(color => (
          <ColorButton
            key={"Color-Button-" + color}
            color={color}
            onClick={() => updateColor(color)}
          />
        ))}
      </div>
    </FloatingMenu>
  );
};

export default ChangeColorMenu;
