import React from "react";
import { COLOR_EMERALD } from "../../../consts/colors";
import { SquareButton } from "../SquareButton";

export const AproveButton = ({ onClick, fontSize }) => (
  <SquareButton
    backgroundColor={COLOR_EMERALD}
    onClick={onClick}
    text="Aprove"
    fontSize={fontSize}
  />
);
