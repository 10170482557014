import React from "react";
import TextBoardComponent from "./TextBoardComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveBoardComponentById } from "../../redux/modules/boardsDict";
import { nullIfNeeded } from "../../javascript/nullIfNeeded";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import { updateBoardComponent } from "../../redux/modules/boardsDict";
const _ = require("lodash");

export const updateComponentText = (dispatch, component, newText) => {
  var clonedComponent = _.cloneDeep(component);
  clonedComponent.text = nullIfNeeded(newText);
  updateBoardComponent(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, clonedComponent);
};

const TitleBoardComponent = ({ id }) => {
  const boardComponent = useSelector(state =>
    selectActiveBoardComponentById(state, id)
  );
  const dispatch = useDispatch();

  return (
    <TextBoardComponent
      id={id}
      onChange={text => updateComponentText(dispatch, boardComponent, text)}
      text={boardComponent.text}
    />
  );
};

export default TitleBoardComponent;
