import React, { useRef, useState } from "react";
import { useHover } from "react-hooks-lib";
import { useDrag } from "react-use-gesture";
import { updateComponentSize } from "../../redux/modules/boardsDict";
import { useDispatch } from "react-redux";
import a from "../../javascript/a";
import { DRAGGABLE_ZINDEX } from "../../consts/layout";

const LEFT_BORFER_TYPE = "LEFT_BORFER_TYPE";
const RIGHT_BORFER_TYPE = "RIGHT_BORFER_TYPE";
// const TOP_BORDER_TYPE = "TOP_BORDER_TYPE";
// const BOTTOM_BORDER_TYPE = "BOTTOM_BORDER_TYPE";
// const RIGHT_TOP_CORNER_TYPE = "RIGHT_TOP_CORNER_TYPE";
// const LEFT_BOTTOM_CORNER_TYPE = "LEFT_BOTTOM_CORNER_TYPE";
// const RIGHT_BOTTOM_CORNER_TYPE = "RIGHT_BOTTOM_CORNER_TYPE";
// const LEFT_TOP_CORNER_TYPE = "LEFT_TOP_CORNER_TYPE";

const borderTypeToCursor = {
  LEFT_BORFER_TYPE: "ew-resize",
  RIGHT_BORFER_TYPE: "ew-resize"
  // TOP_BORDER_TYPE: "ns-resize",
  // BOTTOM_BORDER_TYPE: "ns-resize",
  // RIGHT_TOP_CORNER_TYPE: "nesw-resize",
  // LEFT_BOTTOM_CORNER_TYPE: "nesw-resize",
  // RIGHT_BOTTOM_CORNER_TYPE: "nwse-resize",
  // LEFT_TOP_CORNER_TYPE: "nwse-resize"
};

const ResizableBorder = ({
  componentId,
  borderType,
  width,
  height,
  onMouseDownChange
}) => {
  const ref = useRef();
  const hoverProps = useHover();
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);
  const bind = useDrag(({ down, delta: [x, y] }) => {
    setIsDragging(down);
    onMouseDownChange(down);
    if (down !== true) {
      return;
    }

    const locationDelta = {
      x: borderType === LEFT_BORFER_TYPE ? a(x) : 0,
      y: 0
    };

    const sizeDelta = {
      x: borderType === LEFT_BORFER_TYPE ? -a(x) : a(x),
      y: 0
    };

    updateComponentSize(dispatch, componentId, sizeDelta, locationDelta); //Currently resizing only x
  });

  return (
    <div onMouseDown={e => e.stopPropagation()}>
      <div
        ref={ref}
        {...bind()}
        {...hoverProps.bind}
        style={{
          width,
          height,
          cursor: borderTypeToCursor[borderType],
          zIndex: isDragging ? DRAGGABLE_ZINDEX : undefined
        }}
        // onMouseMove={e => e.stopPropagation()}
      />
    </div>
  );
};

const Resizable = ({
  children,
  childrenHeight,
  childrenWidth,
  onSizeChanged,
  resizablePadding,
  componentId
}) => {
  const [isMouseDown, setIsMouseDown] = useState(false);

  const widthWithoutPadding = childrenWidth - resizablePadding * 2;
  const onMouseDownChange = newIsMouseDown => {
    newIsMouseDown !== isMouseDown && setIsMouseDown(newIsMouseDown);
  };
  return (
    <div style={{ WebkitUserSelect: isMouseDown ? "none" : undefined }}>
      {/* <div style={{ display: "flex" }}>
        <ResizableBorder
          borderType={LEFT_TOP_CORNER_TYPE}
          width={resizablePadding}
          height={resizablePadding}
          componentId={componentId}
        />
        <ResizableBorder
          borderType={TOP_BORDER_TYPE}
          width={widthWithoutPadding}
          height={resizablePadding}
          componentId={componentId}
        />
        <ResizableBorder
          borderType={RIGHT_TOP_CORNER_TYPE}
          width={resizablePadding}
          height={resizablePadding}
          componentId={componentId}
        />
      </div> */}
      <div style={{ display: "flex" }}>
        <ResizableBorder
          borderType={LEFT_BORFER_TYPE}
          width={resizablePadding}
          height={childrenHeight}
          componentId={componentId}
          onMouseDownChange={onMouseDownChange}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: widthWithoutPadding
          }}
        >
          {children}
        </div>
        <ResizableBorder
          borderType={RIGHT_BORFER_TYPE}
          width={resizablePadding}
          height={childrenHeight}
          componentId={componentId}
          onMouseDownChange={onMouseDownChange}
        />
      </div>
      {/* <div style={{ display: "flex" }}>
        <ResizableBorder
          borderType={LEFT_BOTTOM_CORNER_TYPE}
          width={resizablePadding}
          height={resizablePadding}
          componentId={componentId}
        />
        <ResizableBorder
          borderType={BOTTOM_BORDER_TYPE}
          width={widthWithoutPadding}
          height={resizablePadding}
          componentId={componentId}
        />
        <ResizableBorder
          borderType={RIGHT_BOTTOM_CORNER_TYPE}
          width={resizablePadding}
          height={resizablePadding}
          componentId={componentId}
        />
      </div> */}
    </div>
  );
};

export default Resizable;
