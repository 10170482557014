import React from "react";
import { updateOpenedModal, MODALS } from "../redux/modules/modals";
import Button from "../components/Buttons/Button";
import { useHistory } from "react-router-dom";
import { signOutApi } from "../backend/api/auth";
import { initRedux } from "../redux/reducers";

const SignoutButton = () => {
  const history = useHistory();

  const onClick = () => {
    // Signout then
    signOutApi().then(() => {
      initRedux();
      history.push("/");
    });
  };

  return (
    <Button text={"Sign out"} onClick={onClick} style={{ opacity: 0.5 }} />
  );
};

export default SignoutButton;
