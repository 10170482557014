import { v4 as uuidv4 } from "uuid";
import { BOARD_NAME_TYPE } from "../../consts/boardComponentsTypes";
import { COLOR_LOGO_BLUE, COLOR_DARK_BLUE } from "../../consts/colors";
import { createBoardComponent, emptyBoard } from "../../redux/modules/board";
import { dateString } from "../../javascript/dateString";
import { createNewCard } from "../selectContentMenu/createNewCard";

const _ = require("lodash");

export const DAILY_TEMPLATE = "DAILY_TEMPLATE";

const CARDS = [
  {
    location: {
      x: 0,
      y: 170,
    },
    size: {
      width: 700,
      height: 500,
    },
    title: "Yaniv's Background",
  },
  {
    location: {
      x: 800,
      y: 170,
    },
    title: "Meeting Goals",
  },
  {
    location: {
      x: 1445,
      y: 170,
    },
    title: "Meeting Summary",
  },
  {
    location: {
      x: 1445,
      y: 590,
    },
    title: "Yaniv's Action items (HW)",
  },
  {
    location: {
      x: 1445,
      y: 940,
    },
    title: "Dean's Action items (HW)",
  },
  {
    location: {
      x: 800,
      y: 940,
    },
    title: "Meeting Details",
  },
  {
    location: {
      x: 800,
      y: 590,
    },
    title: "Meeting Agenda",
  },
  {
    location: {
      x: 2070,
      y: 170,
    },
    size: {
      width: 700,
      height: 500,
    },
    title: "Dean's Background",
  },
];

const createOneOnOneBoard = () => {
  var newBoard = _.cloneDeep(emptyBoard);
  newBoard.id = uuidv4();

  const titleComponent = createBoardComponent(
    { x: 0, y: 50 },
    BOARD_NAME_TYPE
  );
  titleComponent.text = "One On One - " + dateString();
  newBoard.boardComponents[titleComponent.id] = titleComponent;

  for (let i = 0; i < CARDS.length; ++i) {
    const card = createNewCard(CARDS[i]);
    newBoard.boardComponents[card.id] = card;
  }

  return newBoard;
};

export default createOneOnOneBoard;
