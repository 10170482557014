import React from "react";

import {
  updateBoardComponent,
  deleteBoardComponent
} from "../../redux/modules/boardsDict";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import z from "../../javascript/z";
import { useSpring, animated } from "react-spring";
import { useDispatch } from "react-redux";


const TITLE_STYLE = {
  whiteSpace: "nowrap",
  fontWeight: 500,
  minWidth: 200,
  cursor: "text",
  // display: "inline-flex",
  alignItems: "center",
  border: "none",
  outline: "none",
  backgroundColor: "#fff0",
  // fontSize: 36,
  resize: "none",
  color: "#333",
  transform: "scale(1, 1.05)",
  textOverflow: "ellipsis"
};

const TextBoardComponent = ({
  id,
  text,
  boardComponent,
  style,
  placeholder,
  onChange
}) => {
  const dispatch = useDispatch()
  const width = text && text.length > 20 ? text.length * z(28) : z(600)
  const [props, set] = useSpring(() => ({
    fontSize: z(36)
  }));

  set({
    fontSize: z(36)
  });

  const onBlur = () => {
    if (text === undefined || text === null || text === "") {
      deleteBoardComponent(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, id);
    }
  };

  return (
    <animated.div
      style={{
        ...props
      }}
    >
      <input
        key={id}
        type="text"
        style={{
          ...TITLE_STYLE,
          fontSize: "1em",
          width,
          ...style
        }}
        onChange={e => onChange(e.target.value)}
        value={text || ""}
        onBlur={() => onBlur()}
        placeholder={placeholder ? placeholder : "Type here.."}
        onMouseDown={e => e.stopPropagation()}
      />
    </animated.div>
  );
};

export default TextBoardComponent;
