import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import SigninInProgressModal from "./components/Modals/SigninInProgressModal";
import App from "./App";
import useLocalStorage from "react-use-localstorage";
import {
  initSignInListenerApi,
  unregisterSignInListenerApi,
  getCurrentAuthStorageUser,
} from "./backend/api/auth";
import {
  updateOpenedModal,
  MODALS,
  selectCurrentOpenedModal,
} from "./redux/modules/modals";
import { updateUser } from "./redux/modules/user";
import { getUserDetails } from "./backend/api/userDbApi";
import { store } from "./redux/store";
import runUpdater from "./backend/updater/Updater";
import ReactGA from "react-ga";
var mixpanel = require("mixpanel-browser");

const LoginSwitch = () => {
  const location = useLocation();
  const history = useHistory();
  const [lastUrl, setLastUrl] = useLocalStorage("lastUrl", location.name);
  const activeUser = useSelector((state) => state.user);
  const isLoggedInUrl = () => location.pathname === "/logged-in/";

  useEffect(() => {
    runUpdater();
  }, []);

  useEffect(() => {
    var promiseFlag = true;
    var timeout = undefined;
    // updateOpenedModal(dispatch, undefined)

    const logginTimePassed = () => {
      if (!promiseFlag) {
        return;
      }
      unregisterSignInListenerApi();
      updateOpenedModal(MODALS.SIGN_IN_FAILED_MODAL);
    };

    const onLoginProcessFinished = () => {
      if (!promiseFlag) {
        return;
      }
      timeout && clearTimeout(timeout);
      unregisterSignInListenerApi();
      getCurrentAuthStorageUser().then((cognitoUser) => {
        getUserDetails(cognitoUser).then((response) => {
          const user = response.data;
          updateUser(user);

          updateOpenedModal(undefined);
          history.push(lastUrl);
        });
      });
    };

    if (isLoggedInUrl()) {
      updateOpenedModal(MODALS.SIGN_IN_IN_PROGRESS_MODAL);
      timeout = setTimeout(logginTimePassed, 20000);
      initSignInListenerApi(onLoginProcessFinished);
    } else {
      setLastUrl(location.pathname);
      timeout && clearTimeout(timeout);
    }
    return () => {
      promiseFlag = false;
      timeout && clearTimeout(timeout);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (activeUser.id !== undefined || isLoggedInUrl()) {
      return;
    }
    getCurrentAuthStorageUser().then((cognitoUser) => {
      if (cognitoUser === undefined) {
        if (location.pathname !== "/") {
          updateOpenedModal(MODALS.SIGN_IN_MODAL, { disableClose: true });
        }
        return;
      }
      updateOpenedModal(MODALS.LOADING_MODAL);

      getUserDetails(cognitoUser).then((response) => {
        const user = response.data;

        mixpanel.identify(user.id);
        mixpanel.people.set({
          $email: user.email,
          USER_ID: user.id,
        });

        mixpanel.track("Logged in");

        ReactGA.set({
          userId: user.div,
        });
        ReactGA.event({
          category: "Generic Catagory",
          action: "Log in process finished",
        });

        updateUser(user);
        const state = store.getState();
        const currentOpenedModal = selectCurrentOpenedModal(state);
        currentOpenedModal === MODALS.LOADING_MODAL &&
          updateOpenedModal(undefined);
      });
    });
  }, [activeUser.id, location.pathname]);

  // useEffect(()=>{
  //   updateOpenedModal(MODALS.APRROVE_LEGAL)
  // }, [])

  return (
    <div>
      {isLoggedInUrl() && activeUser.id === undefined && (
        <SigninInProgressModal isOpen={true} />
      )}
      {(location.pathname === "/" || activeUser.id !== undefined) && <App />}
    </div>
  );
};

export default LoginSwitch;
