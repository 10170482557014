import React from "react";
import TextBoardComponent from "./TextBoardComponent";
import { useSelector } from "react-redux";
import {
  selectActiveBoardName,
  updateBoardName
} from "../../redux/modules/boardsDict";

const BoardNameComponent = ({ id }) => {
  const boardComponentName = useSelector(selectActiveBoardName);
  return (
    <TextBoardComponent
      id={id}
      text={boardComponentName}
      style={{ fontSize: "1.3em", fontWeight: "bold" }}
      placeholder="Type board name here"
      onChange={name => updateBoardName(name)}
    />
  );
};

export default BoardNameComponent;
