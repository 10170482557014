import { UPDATE_ORIGIN } from "../reducers";
import { store } from "../store";

const _ = require("lodash");

export const STATE_KEY = "activeBoard";

const ACTION_UPDATE_CURRENT_DRAGGED_COMPONENT_ID =
  "ACTION_UPDATE_CURRENT_DRAGGED_COMPONENT_ID";
const ACTION_LAST_DRAG_TIME = "ACTION_LAST_DRAG_TIME";
const ACTION_UPDATE_ZOOM_FACTOR = "ACTION_UPDATE_ZOOM_FACTOR";
const ACTION_UPDATE_WINDOW_WIDTH_FACTOR = "ACTION_UPDATE_WINDOW_WIDTH_FACTOR";
const ACTION_UPDATE_IS_DRAWER_OPEN = "ACTION_UPDATE_IS_DRAWER_OPEN";
const ACTION_UPDATE_ACITVE_BOARD_ID = "ACTION_UPDATE_ACITVE_BOARD_ID";
const ACTION_UPDATE_IS_DRAGGING_BOARD = "ACTION_UPDATE_IS_DRAGGING_BOARD";
const ACTION_UPDATE_VIEW_LOCATION_BY_DELTA =
  "ACTION_UPDATE_VIEW_LOCATION_BY_DELTA";

const normalizeWindowWidth = windowWidth => windowWidth / 2000;

export const initialActiveBoard = {
  activeBoardId: undefined,
  currentDraggedComponentId: undefined,
  isDraggingBoard: false,
  lastDragTime: undefined,
  zoomFactor: 0.7,
  windowWidthFactor: normalizeWindowWidth(window.innerWidth),
  isDrawerOpen: false,
  viewLocation: {
    x: 0,
    y: 0
  }
};

export const selectViewLocation = state => state[STATE_KEY].viewLocation;

export const selectCurrentDraggedComponentId = state =>
  state[STATE_KEY].currentDraggedComponentId;

export const selectActiveBoardId = state => {
  return state[STATE_KEY].activeBoardId;
};

export const selectMagnifaierFactor = state => {
  return state[STATE_KEY].windowWidthFactor * state[STATE_KEY].zoomFactor;
};

export const selectZoomFactor = state => {
  return state[STATE_KEY].zoomFactor;
};

export const selectWindowWidthFactor = state => {
  return state[STATE_KEY].windowWidthFactor;
};

export const selectIsDraggingBoard = state => state[STATE_KEY].isDraggingBoard;

export const updateViewLocationByDelta = (deltaX, deltaY) => {
  store.dispatch({
    type: ACTION_UPDATE_VIEW_LOCATION_BY_DELTA,
    payload: { deltaX, deltaY, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

export const updateActiveBoardId = activeBoardId => {
  store.dispatch({
    type: ACTION_UPDATE_ACITVE_BOARD_ID,
    payload: { activeBoardId, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

export const updateCurrentDraggedComponentId = (
  dispatch,
  origin,
  componentId
) => {
  dispatch({
    type: ACTION_UPDATE_CURRENT_DRAGGED_COMPONENT_ID,
    payload: { componentId, origin }
  });
};

export const updateLastDragTime = (dispatch, origin, lastDragTime) => {
  dispatch({
    type: ACTION_LAST_DRAG_TIME,
    payload: { lastDragTime, origin }
  });
};

export const updateZoomFactor = _.throttle((dispatch, newFactor) => {
  dispatch({
    type: ACTION_UPDATE_ZOOM_FACTOR,
    payload: { newFactor, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
}, 150);

export const updateWindowWidthFactor = (dispatch, windowWidth) => {
  const windowWidthFactor = normalizeWindowWidth(windowWidth);
  dispatch({
    type: ACTION_UPDATE_WINDOW_WIDTH_FACTOR,
    payload: { windowWidthFactor, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

export const updateIsDrawerOpen = (dispatch, isDrawerOpen) => {
  dispatch({
    type: ACTION_UPDATE_IS_DRAWER_OPEN,
    payload: { isDrawerOpen, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

export const updateIsDraggingBoard = isDraggingBoard => {
  store.dispatch({
    type: ACTION_UPDATE_IS_DRAGGING_BOARD,
    payload: { isDraggingBoard, origin: UPDATE_ORIGIN.LOCAL_CHANGE }
  });
};

const reducer = (state = initialActiveBoard, action) => {
  switch (action.type) {
    case ACTION_UPDATE_ACITVE_BOARD_ID:
      return {
        ...state,
        activeBoardId: action.payload.activeBoardId
      };
    case ACTION_UPDATE_IS_DRAGGING_BOARD:
      return {
        ...state,
        isDraggingBoard: action.payload.isDraggingBoard
      };
    case ACTION_UPDATE_CURRENT_DRAGGED_COMPONENT_ID:
      return {
        ...state,
        currentDraggedComponentId: action.payload.componentId
      };

    case ACTION_LAST_DRAG_TIME:
      return {
        ...state,
        lastDragTime: action.payload.lastDragTime
      };

    case ACTION_UPDATE_ZOOM_FACTOR:
      if (action.payload.newFactor < 0.2 || action.payload.newFactor > 1.25) {
        return state;
      }
      const calcDelta = (currentViewCord, fullViewSize) =>
        currentViewCord -
        ((state.zoomFactor - action.payload.newFactor) * fullViewSize) /
          2 /
          (state.zoomFactor * state.windowWidthFactor);

      return {
        ...state,
        zoomFactor: action.payload.newFactor,
        // viewLocation: {
        //   x: Math.max(calcDelta(state.viewLocation.x, window.innerWidth), 0),
        //   y: Math.max(calcDelta(state.viewLocation.y, window.innerHeight), 0)
        // }
      };
    case ACTION_UPDATE_WINDOW_WIDTH_FACTOR:
      return {
        ...state,
        windowWidthFactor: action.payload.windowWidthFactor
      };
    case ACTION_UPDATE_IS_DRAWER_OPEN:
      return {
        ...state,
        isDrawerOpen: action.payload.isDrawerOpen
      };
    case ACTION_UPDATE_VIEW_LOCATION_BY_DELTA:
      return {
        ...state,
        viewLocation: {
          x: Math.max(state.viewLocation.x + action.payload.deltaX, 0),
          y: Math.max(state.viewLocation.y + action.payload.deltaY, 0)
        }
      };
    default:
      return state;
  }
};

export default reducer;
