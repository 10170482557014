import React, { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { COLOR_DARK_BLUE, COLOR_VERY_DARK_BLUE } from "../consts/colors";
import { TAB_INNER_PADDING } from "../consts/layout";
import { TabBarButton } from "./TabBarButton";

export const TAB_BAR_WIDTH = 160;

const calculateMarkLocation = choosenTabIndex =>
  TAB_INNER_PADDING + choosenTabIndex * TAB_BAR_WIDTH;

const TabsBarChoosenMark = ({ choosenTabIndex }) => {
  const [props, set] = useSpring(() => ({
    left: calculateMarkLocation(choosenTabIndex)
    // top: locationParamenters.toTop,
    // opacity: 1,
    // from: { opacity: 0, top: locationParamenters.fromTop }
  }));
  useEffect(() => {
    set({ left: calculateMarkLocation(choosenTabIndex) });
  }, [choosenTabIndex]);
  return (
    <animated.div
      style={{
        position: "absolute",
        backgroundColor: COLOR_DARK_BLUE,
        width: TAB_BAR_WIDTH,
        height: 2,
        left: props.left
      }}
    />
  );
};

export const TabsBar = ({ choosenTabIndex, onChoseTabIndex, tabsNames }) => {
  return (
    <div
      style={{
        position: "absolute",
        boxShadow: `0px 2px 2px 0px ${COLOR_VERY_DARK_BLUE + "12"}`,
        width: "100%"
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          paddingLeft: TAB_INNER_PADDING,
          width: "100%"
        }}
      >
        {tabsNames.map((tab, i) => (
          <TabBarButton
            key={i}
            text={tab}
            onClick={() => onChoseTabIndex(i)}
            active={i === choosenTabIndex}
          />
        ))}
      </div>
      <TabsBarChoosenMark choosenTabIndex={choosenTabIndex} />
    </div>
  );
};
