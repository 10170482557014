import React, { useState, useCallback } from "react";

import Drawer from "../../drawer/Drawer";
import DrawerButton from "../floatingButtons/DrawerButton";
import RightOptionsBar from "../floatingButtons/RightOptionsBar";
import ParticipentsButton from "../floatingButtons/ParticipantsButton";

const AllFloatingButtons = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onIsDrawerOpenChange = useCallback(() =>
    setIsDrawerOpen(!isDrawerOpen)
  );

  const onCloseDrawer = useCallback(() => setIsDrawerOpen(false));

  return (
    <>
      <RightOptionsBar />
      <ParticipentsButton />
      <DrawerButton onClick={onIsDrawerOpenChange} />
      <Drawer
        onClose={onCloseDrawer}
        isOpen={isDrawerOpen}
        showCollaborateTab={true}
        hideBackground={false}
      />
    </>
  );
};

export default AllFloatingButtons;
