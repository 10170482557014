import React from "react";
import { COLOR_VERY_DARK_BLUE } from "../../consts/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import Seperator from "../Seperator";
import GimobydModal from "./GimobyModal";
import { BoldModalText } from "./BoldModalText";

const ProgressModal = ({ isOpen, onClose, text, animate, autoClose }) => {
  return (
    <GimobydModal
      isOpen={isOpen}
      onClose={onClose}
      animate={animate}
      backgroundColor={COLOR_VERY_DARK_BLUE}
      autoClose={autoClose}
    >
      {text && <Seperator height={30} />}

      <CircularProgress
        classes={{
          colorPrimary: "white"
        }}
        // color="primary"
        size={80}
        thickness={5}
        style={{ colorPrimary: "white" }}
      />
      {text && (
        <>
          <Seperator height={30} />
          <BoldModalText>{text}</BoldModalText>
        </>
      )}
    </GimobydModal>
  );
};

export default ProgressModal;
