import { updateBoard } from "../api/boardDbApi";

const _ = require("lodash");

const updateRemoteBoard = board => {
  if (board === undefined || board.id === undefined) {
    return;
  }
  updateBoard(board);
};

export default updateRemoteBoard;
