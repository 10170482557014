import React, { useState } from "react";
import { PARTICIPENTS_PADDING, PARTICIPENTS_WITHOUT_PADDING } from "./layout";

import { color } from "./color";
import { store } from "../../redux/store";
import { selectActiveBoardId } from "../../redux/modules/activeBoard";
import {
  selectBoardById,
  updateBoard,
  selectAllUserEmailsWithPermissions,
  selectConnectionByUserId
} from "../../redux/modules/boardsDict";
import validateEmail from "../../javascript/validateMail";
import {
  EDITOR_PERMISSION
} from "../../consts/permissions";
import { COLOR_POMEGRANATE } from "../../consts/colors";
import { getUserBasicDetailesByEmail } from "../../backend/api/userDbApi";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import {
  assignUserPermissionToBoard,
  updatePendingConnectionStatus
} from "../../backend/api/boardDbApi";
import {
  WAITING_USER_APPROVAL,
  BOTH_SIDES_APPROVED,
  WAITING_OWNER_APPROVAL
} from "../../consts/connectionsStatuses";

const NOT_AN_EMAIL = "Please enter a valid email";
const EMAIL_ALREADY_EXISTS = "This email is already part of the board";

// const AddButton = (onClick) => {
//   <div onClick={onClick}>
//     Add
//   </div>
// }

export const AddEmailInput = () => {
  const [error, setError] = useState();
  // const [isProcessing]

  const onEnter = async newEmail => {
    const state = store.getState();
    const activeBoardId = selectActiveBoardId(state);
    const activeBoard = selectBoardById(state, activeBoardId);
    const isEmail = validateEmail(newEmail);
    if (!isEmail) {
      setError(NOT_AN_EMAIL);
      return;
    }

    const allBoardParicipants = selectAllUserEmailsWithPermissions(activeBoard);
    if (allBoardParicipants.some(({ user: { email } }) => email === newEmail)) {
      setError(EMAIL_ALREADY_EXISTS);
      return;
    }

    const response = await getUserBasicDetailesByEmail(newEmail);
    var pendingConnection;
    if (response.data !== undefined) {
      pendingConnection = selectConnectionByUserId(
        activeBoard.pendingParticipants,
        response.data.id
      );
    }

    var newStatus = WAITING_USER_APPROVAL;
    if (
      pendingConnection &&
      pendingConnection.status === WAITING_OWNER_APPROVAL
    ) {
      newStatus = BOTH_SIDES_APPROVED;
      updatePendingConnectionStatus(pendingConnection.id, BOTH_SIDES_APPROVED);
    }

    await assignUserPermissionToBoard(
      activeBoard,
      EDITOR_PERMISSION,
      newEmail,
      newStatus
    ).then(updatedBoard => {
      updateBoard(UPDATE_ORIGIN.REMOTE_CHANGE, updatedBoard, updatedBoard.id);
    });

    setValue("");
    setError(undefined);
  };

  const [value, setValue] = useState("");
  const onKeyDown = e => {
    setError(undefined);
    if (e.key === "Enter") {
      onEnter(value);
    }
  };

  return (
    <div style={{ marginLeft: PARTICIPENTS_PADDING - 5 }}>
      <input
        type="text"
        placeholder="Enter email to grant permissions in advance"
        onKeyDown={onKeyDown}
        value={value}
        onChange={e => setValue(e.target.value)}
        style={{
          borderRadius: 8,
          border: `0.5px dashed ${color}`,
          height: 40,
          width: PARTICIPENTS_WITHOUT_PADDING,
          paddingLeft: 15,
          fontSize: 16,
          outline: "none",
          fontWeight: "600",
          color
        }}
      />
      <div
        style={{
          paddingLeft: 5,
          height: 15,
          marginTop: 8,
          color: COLOR_POMEGRANATE
        }}
      >
        {error}
      </div>
    </div>
  );
};
