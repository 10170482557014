import { updateOpenedModal, MODALS } from "../../redux/modules/modals";
import { createNewBoard } from "../../backend/api/boardDbApi";
import { store } from "../../redux/store";
import { addNewBoardAfterId, selectActiveUser } from "../../redux/modules/user";
 import { updateBoard } from "../../redux/modules/boardsDict";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import { updateActiveBoardId } from "../../redux/modules/activeBoard";

const createABoard = async (boardTemplate, history) => {
  updateOpenedModal(MODALS.CREATE_BOARD_MODAL);

  const activeUser = selectActiveUser(store.getState());
  const response = await createNewBoard(
    boardTemplate,
    activeUser.email,
    activeUser.id
  );
  const createdBoard = response.data
  addNewBoardAfterId(undefined, createdBoard.id);
  updateBoard(UPDATE_ORIGIN.REMOTE_CHANGE, createdBoard, createdBoard.id);
  updateActiveBoardId(createdBoard.id)
  updateOpenedModal(undefined);

  history.push(`/boards/${createdBoard.id}`);
};

export default createABoard;
