import React from "react";
import { COLOR_ALIZRIN } from "../../../consts/colors";
import { SquareButton } from "../SquareButton";

export const DeclineButton = ({ onClick, fontSize }) => (
  <SquareButton
    backgroundColor={COLOR_ALIZRIN}
    onClick={onClick}
    text="Decline"
    fontSize={fontSize}
  />
);
