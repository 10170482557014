import React from "react";
import { COLOR_CARROT } from "../consts/colors";

export const Badge = ({ number }) => (<div style={{
  position: "absolute",
  right: 2,
  top: 2,
  borderRadius: 100,
  width: 24,
  height: 24,
  backgroundColor: COLOR_CARROT,
  color: "white",
  fontWeight: "bold"
}}>
  {number}
</div>);
