import React from "react";

const Text = ({ text, style }) => (
  <div
    style={{
    //   fontFamily: "Verdana, Geneva, sans-serif",
      ...style
    }}
  >
    {text}
  </div>
);

export default Text;
