import React, { useState, useEffect } from "react";
import { BORDER_PADDING } from "../../consts/layout";
import { animated, useSprings } from "react-spring";
import { PlusZoomButton, MinusZoomButton } from "./ZoomButton";
import { useHover } from "react-hooks-lib";

const RightOptionsBar = () => {
  const [finishedFirstFadeAway, setFinishedFirstFadeAway] = useState(false);
  const { hovered, bind: hoveredBind } = useHover();

  const [springs, set] = useSprings(3, () => ({
    from: { left: BORDER_PADDING, opacity: 1 },
    to: { left: 0, opacity: 0 },
    delay: 5000
  }));

  useEffect(() => {
    var promiseFlag = true;
    if (!promiseFlag) {
      return;
    }

    if (!finishedFirstFadeAway) {
      return;
    }

    set(i => ({
      left: hovered ? BORDER_PADDING : 0,
      opacity: hovered ? 1 : 0,
      delay: i * 5
    }));

    return () => (promiseFlag = false);
  }, [hovered]);

  useEffect(() => {
    var promiseFlag = true;
    setTimeout(() => {
      if (!promiseFlag) {
        return;
      }

      promiseFlag && setFinishedFirstFadeAway(true);
    }, 5000);
    return () => (promiseFlag = false);
  }, []);

  useEffect(() => {
    var promiseFlag = true;
    promiseFlag && hovered && setFinishedFirstFadeAway(true);
  }, [hovered]);

  return (
    <div
      {...hoveredBind}
      style={{ position: "fixed", left: 0, top: 0, width: 100 }}
    >
      <animated.div
        style={{
          position: "fixed",
          bottom: 150 + 50,
          ...springs[1]
        }}
      >
        <PlusZoomButton />
      </animated.div>
      <animated.div
        style={{
          position: "fixed",
          bottom: 150,
          ...springs[0]
        }}
      >
        <MinusZoomButton />
      </animated.div>
    </div>
  );
};

export default RightOptionsBar;
