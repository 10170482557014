import { store } from "../redux/store"
import { selectZoomFactor, selectWindowWidthFactor } from "../redux/modules/activeBoard"

const a = number => {
    if(number === 0){
        return 0
    }
    const state = store.getState()
    const factor = selectZoomFactor(state)
    const windowFacotor = selectWindowWidthFactor(state)
    return number / (windowFacotor * factor)
}


export default a
