import React, { useRef } from "react";
import AddTextButton from "./AddTextButton";
import AddCardButton from "./AddCardButton";
import AddImageButton from "./AddImageButton";
import AddVideoButton from "./AddVideoButton";
import CloseButton from "./CloseButton";
import FloatingMenu, { SELECT_MENU_WIDTH } from "../floatingMenu/FloatingMenu";
import z from "../../javascript/z";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import AddBoardNameButton from "./AddBoardNameButton";
import { selectIsBoardNameComponentExitsts } from "../../redux/modules/boardsDict";
import { useSelector } from "react-redux";

const SelectContentMenu = ({ location, onClose }) => {
  const ref = useRef();
  const isBoardNameComponentExists = useSelector(
    selectIsBoardNameComponentExitsts
  );
  useOnClickOutside(ref, () => {
    onClose();
  });

  const onClick = e => {
    onClose();
    e.stopPropagation();
  };

  const factoredLocation = {
    x: z(location.x),
    y: z(location.y)
  };

  const locationParamenters = {
    left: factoredLocation.x - SELECT_MENU_WIDTH / 3,
    fromTop: factoredLocation.y + 35,
    toTop: factoredLocation.y + 15
  };

  return (
    <FloatingMenu
      ref={ref}
      locationParamenters={locationParamenters}
      onClick={onClick}
    >
      {!isBoardNameComponentExists && (
        <AddBoardNameButton
          newBoardComponentLocation={location}
          onCloseMenu={onClick}
        />
      )}
      <AddCardButton
        newBoardComponentLocation={location}
        onCloseMenu={onClick}
      />

      <AddTextButton
        newBoardComponentLocation={location}
        onCloseMenu={onClick}
      />
      {/* <AddImageButton onClick={onClick} /> */}
      {/* <AddVideoButton onClick={onClick} /> */}
      <CloseButton onClick={onClick} />
    </FloatingMenu>
  );
};

export default SelectContentMenu;
