import React from "react";
import { useSelector } from "react-redux";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { PermissionButton } from "./PermissionButton";
import {
  // READ_ONLY_PERMISSION,
  EDITOR_PERMISSION,
  OWNER_PERMISSION
} from "../../consts/permissions";
import { store } from "../../redux/store";
import { selectIsOnlyApprovedOwner as selectIsThereMoreThanOneUser } from "../../redux/modules/boardsDict";

export const PermissionsButtons = ({
  isOwner,
  onChangePermissions,
  permission,
  participantId
}) => {
  const isOnlyApprovedOwner = useSelector(state =>
    selectIsThereMoreThanOneUser(state, participantId)
  );

  const shouldShowButton = buttonPermissions => {
    if (isOwner) {
      if (
        isOnlyApprovedOwner &&
        permission === OWNER_PERMISSION &&
        buttonPermissions !== OWNER_PERMISSION
      ) {
        return false;
      }
      return true;
    }

    return buttonPermissions === permission;
  };

  return (
    <div style={{ display: "flex" }}>
      {/* {shouldShowButton(READ_ONLY_PERMISSION) && (
        <>
          <PermissionButton
            onClick={() => onChangePermissions(READ_ONLY_PERMISSION)}
            icon={<VisibilityIcon />}
            text="Viewer"
            isOwner={isOwner}
            isChoosen={permission === READ_ONLY_PERMISSION}
          />
          <div style={{ width: 5 }} />
        </>
      )} */}
      {shouldShowButton(EDITOR_PERMISSION) && (
        <>
          <PermissionButton
            onClick={() => onChangePermissions(EDITOR_PERMISSION)}
            icon={<EditIcon />}
            text="Editor"
            isOwner={isOwner}
            isChoosen={permission === EDITOR_PERMISSION}
          />
          <div style={{ width: 5 }} />
        </>
      )}
      {shouldShowButton(OWNER_PERMISSION) && (
        <>
          <PermissionButton
            onClick={() => onChangePermissions(OWNER_PERMISSION)}
            icon={<PersonOutlineIcon />}
            text="Owner"
            isOwner={isOwner}
            isChoosen={permission === OWNER_PERMISSION}
          />
          <div style={{ width: 5 }} />
        </>
      )}
    </div>
  );
};
