import React from "react";
// import { useSelector, useDispatch } from "react-redux";
import JoinBoardSection from "./JoinBoardSection";
import HostBoardSection from "./HostBoardSection";
import ShareBoardCodeSection from "./ShareBoardCodeSection";
import Seperator from "../../../components/Seperator";
import LeaveBoardButton from "./LeaveBoardButton";
import { useHistory } from "react-router-dom";
import { TAB_INNER_PADDING } from "../../../consts/layout";

const SECTIONS_SEPARATOR_HEIGHT = 25;

const CollaborateTab = ({ onClose, onCreateNewBoard }) => {
  const history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingRight: TAB_INNER_PADDING,
        paddingLeft: TAB_INNER_PADDING,
        paddingTop: TAB_INNER_PADDING / 2,
      }}
    >
      <JoinBoardSection
        onJoinedBoard={boardCode => {
          onClose();
          history.push(`/boards/${boardCode}`);
        }}
      />
      <Seperator height={SECTIONS_SEPARATOR_HEIGHT * 1.3} />

      <HostBoardSection onCreateNewBoard={onCreateNewBoard} />
      <Seperator height={SECTIONS_SEPARATOR_HEIGHT * 1.3} />
      <ShareBoardCodeSection />
      {/* <LeaveBoardButton />
        <Seperator height={SECTIONS_SEPARATOR_HEIGHT} /> */}
    </div>
  );
};

export default CollaborateTab;
