import { UPDATE_ORIGIN } from "../reducers";
import { store } from "../store";

export const STATE_KEY = "modals";

const UPDATE_OPENED_MODAL = "UPDATE_OPENED_MODAL";

export const MODALS = {
  SIGN_IN_MODAL: "SIGN_IN_MODAL",
  COULD_NOT_FIND_BOARD_MODAL: "COULD_NOT_FIND_BOARD_MODAL",
  SIGN_IN_IN_PROGRESS_MODAL: "SIGN_IN_IN_PROGRESS_MODAL",
  SIGN_IN_FAILED_MODAL: "SIGN_IN_FAILED_MODAL",
  EXPORT_TO_MAIL_MODAL: "EXPORT_TO_MAIL_MODAL",
  JOINING_BOARD_MODAL: "JOINING_BOARD_MODAL",
  CREATE_BOARD_MODAL: "CREATE_BOARD_MODAL",
  DELETE_DIRECTORY_ITEM_MODAL: "DELETE_DIRECTORY_ITEM_MODAL",
  PARTICIPANTS_MODAL: "PARTICIPANTS_MODAL",
  REQUESTED_TO_JOIN_BOARD: "REQUESTED_TO_JOIN_BOARD",
  WAITING_OWNER_APPROVAL: "WAITING_OWNER_APPROVAL",
  OWNER_DECLINED_JOIN_REQUEST: "OWNER_DECLINED_JOIN_REQUEST",
  LOADING_MODAL: "LOADING_MODAL",
  APRROVE_LEGAL: "APRROVE_LEGAL"
};

const initialState = {
  currentOpenedModal: undefined,
  extraData: undefined
};

export const selectCurrentOpenedModal = (state) => state[STATE_KEY].currentOpenedModal

export const updateOpenedModal = (currentOpenedModal, extraData) => {
  store.dispatch({
    type: UPDATE_OPENED_MODAL,
    payload: {
      currentOpenedModal,
      extraData,
      origin: UPDATE_ORIGIN.LOCAL_CHANGE
    }
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_OPENED_MODAL:
      return {
        ...state,
        currentOpenedModal: action.payload.currentOpenedModal,
        extraData: action.payload.extraData
      };
    default:
      return state;
  }
};

export default reducer;
