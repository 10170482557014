export const COLOR_VERY_DARK_BLUE = "#12295c";
export const COLOR_DARK_BLUE = "#243087";
export const COLOR_LOGO_BLUE = "#006bb5";

export const COLOR_TURQUOISE = "#1ABC9C";
export const COLOR_GREEN_SEA = "#16A085";
export const COLOR_EMERALD = "#2ECC71";
export const COLOR_NEPHRITIS = "#27AE60";
export const COLOR_PETER_RIVER = "#3498DB";
export const COLOR_BELIZE_HOLE = "#2980B9";
export const COLOR_AMETHYST = "#9B59B6";
export const COLOR_WISTERIA = "#8E44AD";
export const COLOR_WET_ASPHALT = "#34495E";
export const COLOR_BLUE_SEA = "#2C3E50";
export const COLOR_SUN_FLOWER = "#F1C40F";
export const COLOR_CARROT = "#E67E22";
export const COLOR_ORANGE = "#F39C12";
export const COLOR_PUMPKIN = "#D35400";
export const COLOR_ALIZRIN = "#E74C3C";
export const COLOR_POMEGRANATE = "#C0392B";
export const COLOR_CLOUDS = "#ECF0F1";
export const COLOR_SILVER = "#BDC3C7";
export const COLOR_CONCRETE = "#95A5A6";
export const COLOR_ASBESTOS = "#7F8C8D";

export const colorsArray = [
  COLOR_TURQUOISE,
  COLOR_GREEN_SEA,
  COLOR_EMERALD,
  COLOR_NEPHRITIS,
  COLOR_PETER_RIVER,
  COLOR_BELIZE_HOLE,
  COLOR_AMETHYST,
  COLOR_WISTERIA,
  COLOR_WET_ASPHALT,
  COLOR_BLUE_SEA,
  COLOR_SUN_FLOWER,
  COLOR_CARROT,
  COLOR_ORANGE,
  COLOR_PUMPKIN,
  COLOR_ALIZRIN,
  COLOR_POMEGRANATE,
  COLOR_SILVER,
  COLOR_CONCRETE,
  COLOR_ASBESTOS
];

export const colorsArrayForRandomChoose = [
  COLOR_TURQUOISE,
  COLOR_GREEN_SEA,
  COLOR_EMERALD,
  COLOR_NEPHRITIS,
  COLOR_PETER_RIVER,
  COLOR_BELIZE_HOLE,
  COLOR_AMETHYST,
  COLOR_WISTERIA,
  COLOR_WET_ASPHALT,
  COLOR_BLUE_SEA,
  COLOR_SUN_FLOWER,
  COLOR_CARROT,
  COLOR_ORANGE,
  COLOR_PUMPKIN,
  COLOR_ALIZRIN,
  COLOR_POMEGRANATE
];

export const chooseRandomColor = () => {
  const colorIndex = Math.ceil(Math.random() * 1000) % colorsArrayForRandomChoose.length;
  return colorsArrayForRandomChoose[colorIndex];
};
