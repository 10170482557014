import React from "react";
import { useHover } from "react-hooks-lib";
import { COLOR_DARK_BLUE } from "../consts/colors";
import { TAB_BAR_WIDTH } from "./TabsBar";

export const TabBarButton = ({ text, onClick, active }) => {
  const { hovered, bind: hoveredBind } = useHover();
  return (
    <div
      {...hoveredBind}
      onClick={onClick}
      style={{
        display: "flex",
        width: TAB_BAR_WIDTH,
        paddingBottom: 10,
        fontWeight: hovered || active ? "bold" : "600",
        cursor: "pointer",
        color: active ? COLOR_DARK_BLUE : hovered ? "black" : "#333"
      }}
    >
      {text}
    </div>
  );
};
