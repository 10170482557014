import React from "react";

import GimobyModal from "./GimobyModal";
import Participants from "../Participants/Participants";
import { color } from "../Participants/color";
import { PARTICIPENTS_WIDTH } from "../Participants/layout";

const ParticipantsModal = ({ extraData }) => {
  return (
    <GimobyModal
      isOpen={true}
      backgroundColor={"white"}
      autoClose={true}
      innerStyle={{
        width: PARTICIPENTS_WIDTH,
        minHeight: 350,
        maxHeight: 420,
        borderRadius: 20,
        paddingTop: 15,
        paddingBottom: 15
      }}
    >
      <div style={{ overflow: "scroll", paddingBottom: 40 }}>
        <Participants boardId={extraData.boardId} />
      </div>
    </GimobyModal>
  );
};

export default ParticipantsModal;
