import React from "react";
import FloatingMenu, { SELECT_MENU_WIDTH } from "../FloatingMenu";
import DeleteComponentButton from "./DeleteCompnentButton";
import ChangeComponentColorButton from "./ChangeComponentColorButton";

const ComponentMenu = ({ onClose, color, id, onOpenChangeColorMenu }) => {
  const onClick = e => {
    onClose();
    e.stopPropagation();
  };

  const locationParamenters = {
    left: -SELECT_MENU_WIDTH / 2,
    fromTop: -10,
    toTop: 5
  };
  return (
    <FloatingMenu locationParamenters={locationParamenters} onClick={onClick}>
      <ChangeComponentColorButton
        color={color}
        id={id}
        onOpenChangeColorMenu={onOpenChangeColorMenu}
        onCloseMenu={onClick}
      />
      <DeleteComponentButton color={color} id={id} onCloseMenu={onClick} />
    </FloatingMenu>
  );
};

export default ComponentMenu;
