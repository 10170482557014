/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBoard = /* GraphQL */ `
  query GetBoard($id: ID!) {
    getBoard(id: $id) {
      id
      boardMeetingStage {
        stage
        id
        version
      }
      name {
        name
        version
      }
      meetingLifecycleTime {
        id
        scheduledStartTime
        scheduledEndTime
        startTime
        closedDate
        version
      }
      boardComponents
      boardParticipants {
        items {
          id
          userId
          boardId
          status
          permission
          user{
            id
            userName
            email
            pictureURL
          }
        }
        nextToken
      }
      pendingParticipants {
        items {
          id
          userId
          boardId
          status
          user{
            id
            userName
            email
            pictureURL
          }
        }
        nextToken
      }
      readOnlyIds
      editorIds
      ownerIds
      version
    }
  }
`;
export const listBoards = /* GraphQL */ `
  query ListBoards(
    $filter: ModelBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        boardMeetingStage {
          stage
          id
          version
        }
        name {
          name
          version
        }
        meetingLifecycleTime {
          id
          scheduledStartTime
          scheduledEndTime
          startTime
          closedDate
          version
        }
        boardComponents
        boardParticipants {
          nextToken
        }
        pendingParticipants {
          nextToken
        }
        readOnlyIds
        editorIds
        ownerIds
        version
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      userName
      pictureURL
      email
      emailVerified
      participatingBoards {
        items {
          id
          userId
          boardId
          permission
          status
          board{
              id
              name{
                name
                version
              }
          }
        }
        nextToken
      }
      pendingBoards {
        items {
          id
          userId
          boardId
          status
        }
        nextToken
      }
      boardsDirectory
      version
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        userName
        pictureURL
        email
      }
      nextToken
    }
  }
`;
