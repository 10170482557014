import React from "react";
import { color } from "./color";
import { ONE_LINE_NO_WRAP } from "../../consts/styles";

export const UserName = ({ userName }) => (
  <div
    style={{
      fontSize: 16,
      fontWeight: "700",
      color,
      ...ONE_LINE_NO_WRAP
    }}
  >
    {userName}
  </div>
);
