import React from "react";

import { BlockButton, MarkButton, LinkButton } from "./EditorButtons";
import z from "../../javascript/z";

const Toolbar = React.forwardRef(({ color }, ref) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        marginTop: z(30),
        cursor: "default",
      }}
      onMouseDown={(e) => e.preventDefault()}
    >
      <MarkButton color={color} ref={ref} format="bold" icon="FormatBold" />
      <MarkButton color={color} format="italic" icon="FormatItalic" />
      <MarkButton color={color} format="underline" icon="FormatUnderline" />
      <MarkButton color={color} format="strike" icon="FormatStrikethrough" />
      <LinkButton color={color} format="link" />
      <BlockButton color={color} format="heading-one" icon="LooksOne" />
      <BlockButton
        color={color}
        format="numbered-list"
        icon="FormatListNumbered"
      />
      <BlockButton
        color={color}
        format="bulleted-list"
        icon="FormatListBulleted"
      />
    </div>
  );
});

export default Toolbar;
