import React, { useState } from "react";
import { useSelector } from "react-redux";

import { TextField, Button } from "@material-ui/core";
import Seperator from "../../../components/Seperator";
import { COLOR_LOGO_BLUE } from "../../../consts/colors";
import Text from "../../../components/Text";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateOpenedModal, MODALS } from "../../../redux/modules/modals";
import joinABoard from "../../../javascript/collaboration/joinABoard";
import { isUserSignin } from "../../../redux/modules/user";

const LOCAL_HOST_PREFIX = "http://localhost:3000/boards/";
const WEBSITE_PREFIX = "https://boards.gimoby.com/boards/";

const validateLink = code => {
  return (
    typeof code === "string" &&
    ((code.startsWith(LOCAL_HOST_PREFIX) &&
      code.length === LOCAL_HOST_PREFIX.length + 36) ||
      (code.startsWith(WEBSITE_PREFIX) &&
        code.length === WEBSITE_PREFIX.length + 36))
  );
};

const validateCode = code => {
  return (
    code !== undefined &&
    code !== null &&
    typeof code === "string" &&
    code.length === 36
  );
};

const JoinBoardSection = ({ onJoinedBoard }) => {
  const [boardCode, setBoardCode] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const isSignedIn = useSelector(isUserSignin);

  const onClick = () => {
    if (!isSignedIn) {
      updateOpenedModal(MODALS.SIGN_IN_MODAL);
      return;
    }

    const isCode = validateCode(boardCode);
    const isLink = validateLink(boardCode);

    if (!isCode && !isLink) {
      updateOpenedModal(MODALS.COULD_NOT_FIND_BOARD_MODAL);
      return;
    }

    var extractedCode = boardCode;
    if (isLink) {
      extractedCode = boardCode.slice(boardCode.length - 36, boardCode.length);
    }

    joinABoard(extractedCode, history).then(
      () => onJoinedBoard && onJoinedBoard(extractedCode)
    );
  };

  return (
    <>
      <h3>Join a Board</h3>
      <TextField
        onChange={e => setBoardCode(e.target.value)}
        variant="outlined"
        fullWidth
        label="Enter board code or link"
      />
      <Seperator height={20} />

      <Button
        fullWidth
        color="primary"
        style={{ backgroundColor: COLOR_LOGO_BLUE }}
        onClick={onClick}
      >
        <Text text="Join" style={{ color: "white" }} />
      </Button>
    </>
  );
};

export default JoinBoardSection;
