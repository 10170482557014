import React, { useState, useCallback } from "react";
import { LEFT_PADDING_SIZE, DRAGGABLE_ZINDEX } from "../../consts/layout";
import {
  updateBoardComponent,
  selectActiveBoardComponentById
} from "../../redux/modules/boardsDict";
import {
  updateCurrentDraggedComponentId,
  selectCurrentDraggedComponentId,
  selectViewLocation,
  selectIsDraggingBoard
} from "../../redux/modules/activeBoard";

import { useSelector, useDispatch } from "react-redux";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";

import OpenWithIcon from "@material-ui/icons/OpenWith";
import z from "../../javascript/z";
import a from "../../javascript/a";
import { useHover } from "react-hooks-lib";

const _ = require("lodash");

const defaultLocationIfNull = location => (location !== null ? location : 200);

const updateComponentLocation = (dispatch, component, x, y) => {
  if (x === null || y === null) {
    return;
  }
  var clonedComponent = _.cloneDeep(component);
  clonedComponent.location = {
    x,
    y
  };
  updateBoardComponent(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, clonedComponent);

  // updateLastDragTime(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, new Date());
};

const DraggableBoardComponent = ({ children, id }) => {
  const { hovered, bind: hoveredBind } = useHover();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const viewLocation = useSelector(selectViewLocation);

  const boardComponent = useSelector(state =>
    selectActiveBoardComponentById(state, id)
  );

  const currentDraggedComponentId = useSelector(
    selectCurrentDraggedComponentId
  );

  const bind = useDrag(({ down, delta: [x, y] }) => {
    isMouseDown !== down && setIsMouseDown(down);
    if (!down) {
      updateCurrentDraggedComponentId(
        dispatch,
        UPDATE_ORIGIN.LOCAL_CHANGE,
        undefined
      );
      return;
    }

    const newX = boardComponent.location.x + a(x);
    const newY = boardComponent.location.y + a(y);
    updateComponentLocation(dispatch, boardComponent, newX, newY);
    updateCurrentDraggedComponentId(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, id);
  });

  const dispatch = useDispatch();

  const x = z(
    defaultLocationIfNull(boardComponent.location.x) - viewLocation.x
  );
  const y = z(
    defaultLocationIfNull(boardComponent.location.y) - viewLocation.y
  );

  const [props, set] = useSpring(() => ({
    x,
    y
  }));

  set({
    x,
    y
  });

  const isCurrentlyDragged = () =>
    currentDraggedComponentId !== undefined && id === currentDraggedComponentId;

  const onMouseDown = useCallback(e => {
    setIsMouseDown(true);
    e.stopPropagation();
  });

  const onMouseUp = useCallback(e => {
    setIsMouseDown(false);
    e.stopPropagation();
  });

  const wrapperStyle = useCallback(
    () => ({ WebkitUserSelect: isMouseDown ? "none" : undefined }),
    [isMouseDown]
  );
  return (
    <div
      // onMouseMove={e =>
      //   currentDraggedComponentId === undefined && e.stopPropagation()
      // }
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      style={wrapperStyle()}
    >
      <animated.div
        {...bind()}
        {...hoveredBind}
        style={{
          display: "flex",
          position: "absolute",
          left: props.x,
          top: props.y,
          cursor: isMouseDown ? "grabbing" : "grab",
          zIndex: isCurrentlyDragged() ? DRAGGABLE_ZINDEX : undefined
        }}
      >
        <div
          style={{
            width: LEFT_PADDING_SIZE,
            justifyContent: "cetner",
            alignItems: "center"
          }}
        >
          {isCurrentlyDragged() ||
            (!currentDraggedComponentId && hovered && <OpenWithIcon style={{fontSize:'1.4em'}} />)}
        </div>
        {children}
      </animated.div>
    </div>
  );
};

export default DraggableBoardComponent;
