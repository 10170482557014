import React from "react";
import { useHover } from "react-hooks-lib";

const SmallIconButton = ({
  color,
  backgroundColor,
  hoveredColor,
  children,
  onClick
}) => {
  const { hovered, bind: hoveredBind } = useHover();
  const localOnClick = e => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <div
      {...hoveredBind}
      onMouseDown={e => e.stopPropagation()}
      onClick={localOnClick}
      style={{
        borderRadius: 100,
        padding: 4,
        // width: 30,
        backgroundColor: hovered ? hoveredColor : backgroundColor,
        opacity: hovered ? 0.5 : undefined,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: hovered ? 'white' : color,
      }}
    >
      {children}
    </div>
  );
};

export default SmallIconButton;
