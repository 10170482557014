import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { updateVersion, updateBoardVersion } from "../updateVersion";
import { selectActiveUserEmail } from "./user";

const _ = require("lodash");

export const STATE_KEY = "board";

export const MIN_CARD_WIDTH = 400;
export const MAX_CARD_WIDTH = 1200;

export const ACTION_UPDATE_BOARD = "ACTION_UPDATE_BOARD";
export const ACTION_UPDATE_BOARD_NAME = "ACTION_UPDATE_BOARD_NAME";
export const ACTION_UPDATE_BOARD_COMPONENT = "ACTION_UPDATE_BOARD_COMPONENT";
export const ACTION_DELETE_BOARD_COMPONENT = "ACTION_DELETE_BOARD_COMPONENT";
export const ACTION_UPDATE_COMPONENT_SIZE = "ACTION_UPDATE_COMPONENT_SIZE";
export const ACTION_UPDATE_COLOR = "ACTION_UPDATE_COLOR";
export const ACTION_UPDATE_EDITOR_STATE = "ACTION_UPDATE_EDITOR_STATE";

export const LIFE_CYCLE = {
  ALIVE: "ALIVE",
  DELETED: "DELETED"
};

export const emptyBoard = {
  id: undefined,
  name: {
    name: undefined,
    version: 0
  },
  boardComponents: {}
};

export const createBoardComponent = (location, componentType) => ({
  id: uuidv4(),
  location: {
    x: location.x,
    y: location.y
  },
  componentType,
  stage: LIFE_CYCLE.ALIVE
});

export const isNewVersion = (oldObject, newObject) => {
  return oldObject.version < newObject.version;
};

export const isDeleted = object => object.stage === LIFE_CYCLE.DELETED;
const isNewKey = (oldObject, newKey) =>
  oldObject[newKey] === undefined ||
  oldObject[newKey] === null ||
  oldObject[newKey] === "" ||
  (typeof oldObjects === "object" &&
    Object.keys(oldObject).indexOf(newKey) === -1);

const mergeObjects = (oldObjects, newObjects) => {
  for (let newObjectId in newObjects) {
    if (
      isNewKey(oldObjects, newObjectId) ||
      isNewVersion(oldObjects[newObjectId], newObjects[newObjectId]) ||
      oldObjects[newObjectId].version === undefined ||
      isDeleted(oldObjects[newObjectId]) ||
      isDeleted(newObjects[newObjectId])
    ) {
      if (newObjects[newObjectId] !== undefined) {
        oldObjects[newObjectId] = newObjects[newObjectId];
      }
    }
  }
};

const reducer = (state = emptyBoard, action) => {
  var newBoard = undefined;
  switch (action.type) {
    case ACTION_UPDATE_BOARD:
      var oldBoard = cloneDeep(state);
      var newBoard = action.payload.board;

      var newBoardComponents = action.payload.board.boardComponents;

      var oldBoardComponents = oldBoard.boardComponents;
      if (newBoardComponents !== undefined) {
        mergeObjects(oldBoardComponents, newBoardComponents);
      }
      mergeObjects(oldBoard, newBoard);

      return {
        ...oldBoard,
        boardComponents: { ...oldBoardComponents }
      };

    case ACTION_UPDATE_BOARD_COMPONENT:
      const boardComponents = cloneDeep(state.boardComponents);
      const updatedComponent = action.payload.boardComponent;

      updatedComponent.version = updateVersion(updatedComponent.version);
      boardComponents[updatedComponent.id] = updatedComponent;
      newBoard = {
        ...state,
        boardComponents
      };
      return updateBoardVersion(newBoard);
    case ACTION_DELETE_BOARD_COMPONENT:
      newBoard = {
        ...state,
        boardComponents: {
          ...state.boardComponents,
          [action.payload.componentId]: {
            id: action.payload.componentId,
            stage: LIFE_CYCLE.DELETED
          }
        }
      };
      return updateBoardVersion(newBoard);
    case ACTION_UPDATE_COMPONENT_SIZE:
      const componentToUpdate =
        state.boardComponents[action.payload.componentId];
      const newWidth = _.clamp(
        componentToUpdate.size.width + action.payload.sizeDelta.x,
        MIN_CARD_WIDTH,
        MAX_CARD_WIDTH
      );
      const diffToNewWidth = Math.abs(newWidth - componentToUpdate.size.width);
      newBoard = {
        ...state,
        boardComponents: {
          ...state.boardComponents,
          [action.payload.componentId]: {
            ...state.boardComponents[action.payload.componentId],
            version: updateVersion(
              state.boardComponents[action.payload.componentId].version
            ),
            location: {
              x:
                componentToUpdate.location.x +
                _.clamp(
                  action.payload.locationDelta.x,
                  -diffToNewWidth,
                  diffToNewWidth
                ),
              y: componentToUpdate.location.y
            },
            size: {
              width: newWidth,
              height: componentToUpdate.size.height
            }
          }
        }
      };
      return updateBoardVersion(newBoard);

    case ACTION_UPDATE_COLOR:
      newBoard = {
        ...state,
        boardComponents: {
          ...state.boardComponents,
          [action.payload.id]: {
            ...state.boardComponents[action.payload.id],
            color: action.payload.color,
            version: updateVersion(
              state.boardComponents[action.payload.id].version
            )
          }
        }
      };
      return updateBoardVersion(newBoard);

    case ACTION_UPDATE_EDITOR_STATE:
      newBoard = {
        ...state,
        boardComponents: {
          ...state.boardComponents,
          [action.payload.componentId]: {
            ...state.boardComponents[action.payload.componentId],
            editorState: action.payload.editorState,
            version: updateVersion(
              state.boardComponents[action.payload.componentId].version
            )
          }
        }
      };
      return updateBoardVersion(newBoard);
    case ACTION_UPDATE_BOARD_NAME:
      newBoard = {
        ...state,
        name: {
          name: action.payload.name,
          version: updateVersion(state.name.version)
        }
      };
      return updateBoardVersion(newBoard);

    default:
      return state;
  }
};

export default reducer;
