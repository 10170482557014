import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import { backendSetup } from "./backend/api/backend-setup";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import NotSupportedScreen from "./mobile/NotSupportedScreen";
import LoginSwitch from "./LoginSwitch";
import ModalsMannager from "./components/Modals/ModalsMannager";
import ReactGA from 'react-ga';

var mixpanel = require("mixpanel-browser");
mixpanel.init("0c3e5fb982fccf186f1ce11e1c966afe");

const trackingId = "UA-175561542-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);


backendSetup();

ReactDOM.render(
  <div>
    <BrowserView>
      <Provider store={store}>
        <Router>
          <LoginSwitch />
          <ModalsMannager />
        </Router>
      </Provider>
    </BrowserView>
    <MobileView>
      <NotSupportedScreen />
    </MobileView>
  </div>,
  document.getElementById("root")
);
