// Original: https://github.com/chenglou/react-motion/tree/master/demos/demo8-draggable-list

import clamp from "lodash-es/clamp";
import swap from "lodash-move";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { animated, interpolate, useSprings } from "react-spring";
import { useDrag } from "react-use-gesture";
import {
  addNewDirectoryAfterId,
  BOARD,
  DIRECTORY,
  selectBoardsDirectory,
  updateIsOpenDirectoryById,
  updateItemLocationAfter,
  addNewDirectoryAtLastPlace
} from "../../../../redux/modules/user";
// import DirectoryItemFloatingMenu from "./DirectoryItemFloatingMenu";
import ItemLine from "./ItemLine";
import EditDirectoryItemInput from "./NewDirectoryButton";
import { DRAWER_WIDTH, TAB_INNER_PADDING } from "../../../../consts/layout";
import joinABoard from "../../../../javascript/collaboration/joinABoard";
// import { DRAWER_WIDTH, TAB_INNER_PADDING } from "../../../consts/layout";

const BOARD_CARD_WIDTH = 350;
const BOARD_CARD_HEIGHT = 35;
const BOARD_CARD_HEIGHT_WITH_PADDING = BOARD_CARD_HEIGHT + 10;
const START_DRAG_TIME = 200; //ms

// Returns fitting styles for dragged/idle items
const fn = (order, down, originalIndex, curIndex, y) => index =>
  down && index === originalIndex
    ? {
        y: Math.max(curIndex * BOARD_CARD_HEIGHT_WITH_PADDING + y, 0),
        scale: 1.03,
        zIndex: "1",
        shadow: 4,
        down: 1,
        immediate: n => n === "y" || n === "zIndex"
      }
    : {
        y: order.indexOf(index) * BOARD_CARD_HEIGHT_WITH_PADDING,
        scale: 1,
        zIndex: "0",
        shadow: 0,
        immediate: false,
        down: 0
      };

const flattenDirectoryAux = (dir, currentDepth, isOpen) => {
  if (!isOpen) {
    return [];
  }
  if (dir.type === BOARD) {
    return [
      {
        ...dir,
        currentDepth,
        isOpen
      }
    ];
  }
  const currentDir = {
    ...dir,
    isOpen: dir.isOpen && isOpen,
    currentDepth
  };
  const subTreeResult = Object.values(dir.children).reduce((res, subDir) => {
    return [
      ...res,
      ...flattenDirectoryAux(subDir, currentDepth + 1, currentDir.isOpen)
    ];
  }, []);

  if (dir.name === "root") {
    return subTreeResult;
  }

  return [currentDir, ...subTreeResult];
};

const flattenDirectory = dir => flattenDirectoryAux(dir, -1, true);

const printFlattenDirectory = items => {
  const a = flattenDirectory(items);
  return (
    <div>
      {a.map(i => (
        <div>{JSON.stringify(i)}</div>
      ))}
    </div>
  );
};

function BoardsDirectory({ items, tabsHieght }) {
  const [flattenItems, setFlattenItems] = useState(flattenDirectory(items));
  const history = useHistory();
  const order = useRef(flattenItems.map((_, index) => index)); // Store indicies as a local ref, this represents the item order
  const [springs, setSprings] = useSprings(
    flattenItems.length,
    fn(order.current)
  ); // Create springs, each corresponds to an item, controlling its transform, scale, etc.
  const [draggedIndex, setDraggedIndex] = useState(-1);
  const [draggedDepth, setDraggedDepth] = useState(-1);
  // const [
  //   isDirectoryFloatingMenuOpen,
  //   setIsDirectoryFloatingMenuOpen
  // ] = useState(false);

  const [detailsForFloatingMenu, setDetailsForFloatingMenu] = useState({
    item: null,
    location: {
      x: null,
      y: null
    }
  });

  const onItemPress = item => {
    if (item.type === DIRECTORY) {
      updateIsOpenDirectoryById(item.id, !item.isOpen);
      return;
    }

    if (item.type === BOARD) {
      joinABoard(item.id, history)
        .then(() => {
          history.push(`/boards/${item.id}`);
        })
        .catch(() => null);
    }
  };

  const bind = useDrag(
    ({ args: [originalIndex], down, movement: [, y], elapsedTime }) => {
      if (down && elapsedTime < START_DRAG_TIME) {
        return;
      }
      if (!down && elapsedTime < START_DRAG_TIME) {
        onItemPress(flattenItems[originalIndex]);
        return;
      }
      if (
        flattenItems[originalIndex].type === DIRECTORY &&
        flattenItems[originalIndex].isOpen
      ) {
        updateIsOpenDirectoryById(flattenItems[originalIndex].id, false);
        return;
      }
      const curIndex = order.current.indexOf(originalIndex);

      const curRow = clamp(
        Math.round(
          (curIndex * BOARD_CARD_HEIGHT_WITH_PADDING + y) /
            BOARD_CARD_HEIGHT_WITH_PADDING
        ),
        0,
        flattenItems.length - 1
      );
      const newOrder = swap(order.current, curIndex, curRow);
      setSprings(fn(newOrder, down, originalIndex, curIndex, y)); // Feed springs new style data, they'll animate the view without causing a single render

      //Get prev item of the new order
      var prevItem = undefined;
      if (newOrder.indexOf(originalIndex) > 0) {
        const newPrevIndex = newOrder[newOrder.indexOf(originalIndex) - 1];
        prevItem = flattenItems[newPrevIndex];
      }

      if (!down) {
        setDraggedIndex(-1);
        setDraggedDepth(-1);
        order.current = newOrder;
        updateItemLocationAfter(
          flattenItems[originalIndex],
          prevItem !== undefined ? prevItem.id : undefined
        );
      } else {
        setDraggedIndex(originalIndex);
        setDraggedDepth(
          prevItem === undefined
            ? 0
            : prevItem.type === DIRECTORY && prevItem.isOpen
            ? prevItem.currentDepth + 1
            : prevItem.currentDepth
        );
      }
    }
  );

  useEffect(() => {
    const flatten = flattenDirectory(items);
    order.current = flatten.map((_, index) => index);
    setFlattenItems(flatten);
  }, [items]);

  const onPlusClick = (e, item) => {
    addNewDirectoryAfterId(item.id);
    // console.log(e.nativeEvent)
    // const location = {
    //   x: e.nativeEvent.pageX,
    //   y: e.nativeEvent.pageY
    // };
    // setDetailsForFloatingMenu({ item, location });
    // setIsDirectoryFloatingMenuOpen(true);
  };

  return (
    <div
      style={{
        marginTop: 20,
        height: tabsHieght,
        position: "relative"
      }}
    >
      {springs.map(({ zIndex, shadow, y, scale, paddingLeft }, i) => {
        return (
          <animated.div
            {...bind(i)}
            key={flattenItems[i].id}
            style={{
              position: "absolute",
              borderRadius: 8,
              zIndex,
              boxShadow: shadow.interpolate(
                s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
              ),
              transform: interpolate(
                [y, scale],
                (y, s) => `translate3d(0,${y}px,0) scale(${s})`
              )
            }}
          >
            <ItemLine
              index={i}
              draggedIndex={draggedIndex}
              dirItem={flattenItems[i]}
              draggedDepth={draggedDepth}
              onPlusClick={e => onPlusClick(e, flattenItems[i])}
            />
          </animated.div>
        );
      })}
      <div
        style={{
          position: "absolute",
          top: 10 + flattenItems.length * BOARD_CARD_HEIGHT_WITH_PADDING,
          left: TAB_INNER_PADDING - 10
        }}
      >
        <EditDirectoryItemInput
          height={BOARD_CARD_HEIGHT}
          width={DRAWER_WIDTH - 2 * TAB_INNER_PADDING + 10}
          item={{name: ""}}
          onEnter={directoryName => addNewDirectoryAtLastPlace(directoryName)}
          placeholder="Create a new directory"
        />
      </div>
      {/* {isDirectoryFloatingMenuOpen && (
        <DirectoryItemFloatingMenu
          item={detailsForFloatingMenu.item}
          location={detailsForFloatingMenu.location}
          isOpen={isDirectoryFloatingMenuOpen}
          onClose={() => setIsDirectoryFloatingMenuOpen(false)}
        />
      )} */}
    </div>
  );
}

const BoardsDirectoryAux = ({ tabsHieght }) => {
  const version = useSelector(state => state.user.version);
  const items = useSelector(selectBoardsDirectory);

  return (
    <BoardsDirectory tabsHieght={tabsHieght} key={version} items={items} />
  );
};

export default BoardsDirectoryAux;
