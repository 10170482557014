import React from "react";
import { useWindowHeight } from "@react-hook/window-size";

const NotSupportedScreen = () => {
  const height = useWindowHeight();
  return (
    <div
      style={{
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
        padding: 20
      }}
    >
      <div />
      <div />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        {/* <img src={logo} alt="Logo" style={{ width: "50%" }} /> */}
        <h1 style={{ color: "#333", marginTop: 40, textAlign: "center" }}>
          {" "}
          Gimoby boards is not yet supported on smart phones{" "}
        </h1>
      </div>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default NotSupportedScreen;
