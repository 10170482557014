import React, {useState} from "react";

export const Image = ({ pictureURL }) => {
  const [fallBackImage, setFallBackImage] = useState();

  if (!pictureURL) {
    return null;
  }
  return (
    <img
      src={fallBackImage === undefined ? pictureURL : fallBackImage}
      alt={"Board Participant"}
      style={{ width: 43, height: 43, borderRadius: 20 }}
      onError={() =>
        setFallBackImage(require("../../assets/blank-profile-picture.png"))
      }
    />
  );
};
