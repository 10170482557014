import React from "react";
import MenuButton from "../floatingMenu/MenuButton";
import { BOARD_NAME_TYPE } from "../../consts/boardComponentsTypes";
import { useDispatch } from "react-redux";
import { UPDATE_ORIGIN } from "../../redux/reducers";
import { updateBoardComponent } from "../../redux/modules/boardsDict";
import { createBoardComponent } from "../../redux/modules/board";
import ReactGA from "react-ga";
var mixpanel = require('mixpanel-browser');

export const AddBoardNameButton = ({
  newBoardComponentLocation,
  onCloseMenu,
}) => {
  const dispatch = useDispatch();

  const onClick = (e) => {
    const boardComponent = createBoardComponent(
      newBoardComponentLocation,
      BOARD_NAME_TYPE
    );
    mixpanel.track("Created board name component", {
      location: newBoardComponentLocation,
    });

    ReactGA.event({
      category: "Generic Catagory",
      action: "Created board name component",
      location: newBoardComponentLocation,

    });

    updateBoardComponent(dispatch, UPDATE_ORIGIN.LOCAL_CHANGE, boardComponent);
    onCloseMenu(e);
  };

  return <MenuButton onClick={onClick} text="Add Name" />;
};
export default AddBoardNameButton;
